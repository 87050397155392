import React, { useEffect, useMemo, useState } from 'react';
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    PDFDownloadLink,
    PDFViewer,
    Font,
    Image,
    G,
} from '@react-pdf/renderer';
import font from '../../assets/hindi.ttf';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { authAxiosInstance, axiosInstance } from '../../utils/axiosConfig';
import QueryString from 'qs';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import sign from '../../assets/sign.png';

// Create styles
Font.register({
    family: 'hindi',
    src: font,
});
const styles = StyleSheet.create({
    page: {
        // flexDirection: "row",
        backgroundColor: 'white',
        fontFamily: 'hindi',
        paddingTop: 35,
        paddingBottom: 45,
        paddingHorizontal: 35,
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    image: {
        width: '160px',
    },
    heading: {
        fontWeight: 700,
        fontSize: 20,
        marginBottom: '10px',
        marginTop: '20px',
    },
    paragraph: {
        fontSize: 16,
    },
    addressHeading: {
        fontSize: 11,
        fontWeight: 'light',
        // fontWeight: "bold",
    },
    address: {
        fontSize: 11,
        color: '#636363',
        fontWeight: 'light',
        // fontWeight: "bold",
    },
    description: {
        paddingVertical: 10,
        fontSize: 11,
        color: '#555555',
        // fontWeight: "bold",
    },
});

// Create Document Component
const CustomerNoticeDocument = ({
    contactInfo,
    invoiceInfo,
    dateFrom,
    dateTo,
}) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <View style={styles.image}>
                    <Image src={'/smallLogo.jpg'} />
                </View>
                <View style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Text style={styles.addressHeading}>
                        KUKREJABULLS AGENCY
                    </Text>
                    <Text style={styles.address}>302,3rd floor</Text>
                    <Text style={styles.address}>hariom market,ring road.</Text>
                    <Text style={styles.address}>surat 395002</Text>
                    <Text style={styles.address}>India</Text>
                    <Text style={styles.address}>GSTIN: 24BOHPK9316P1ZE</Text>
                </View>
            </View>
            <View>
                <Text style={styles.address}>To</Text>
                <Text style={styles.addressHeading}>
                    {contactInfo ? `${contactInfo.contact_name}` : ''}
                </Text>
                <Text style={styles.address}>
                    {contactInfo
                        ? `${contactInfo.billing_address?.address} `
                        : 'N/A'}
                </Text>
                <Text style={styles.address}>
                    {contactInfo ? `GSTIN: ${contactInfo.gst_no} ` : 'N/A'}
                </Text>

                {/* <Text style={styles.address}>India</Text> */}
            </View>
            <View>
                <Text style={{ textAlign: 'right', paddingBottom: '30' }}>
                    Date: {moment().format('DD/MM/YYYY')}
                </Text>
            </View>
            <View>
                <Text
                    style={{
                        fontSize: '20',
                        textAlign: 'center',
                        textDecoration: 'underline',
                        color: 'red',
                    }}
                >
                    INFORMATION OF DUE AMOUNT
                </Text>
            </View>
            {invoiceInfo &&
                invoiceInfo?.map((e, i) => (
                    <View key={i}>
                        <Text
                            style={{
                                fontSize: 11,
                                color: '#555555',
                                paddingTop: 10,
                            }}
                        >
                            चालान{' '}
                            <Text style={{ color: 'black' }}>
                                {e.invoice_number}
                            </Text>
                            के लिए प्राप्त की गयी भुगतान की राशि,{' '}
                            <Text style={{ color: 'black' }}>Total Amount</Text>{' '}
                            से कम है,
                        </Text>
                        <Text
                            style={{
                                fontSize: 11,
                                color: '#555555',
                                paddingTop: 0,
                                paddingBottom: 10,
                            }}
                        >
                            चालान तारीख{' '}
                            <Text style={{ color: 'black' }}>
                                {moment(e.date).format('DD/MM/YYYY')}
                            </Text>{' '}
                            है।
                        </Text>

                        <Text style={{ color: 'red', fontSize: 11 }}>
                            वर्तमान में इस चालान के लिए {e.balance} की शेष राशि
                            बकाया है।
                        </Text>
                        <Text
                            style={{
                                color: 'red',
                                fontSize: 11,
                                paddingTop: 0,
                                paddingBottom: 10,
                            }}
                        >
                            {' '}
                            इसे नवीनतम चालान में जोड़ दिया गया है।
                        </Text>

                        <View style={styles.description}>
                            <Text style={{ fontWeight: 300 }}>
                                Total Amount: {e.total?.toLocaleString('en-IN')}
                            </Text>
                            <Text>
                                Receive Amount:{' '}
                                {(e.total - e.balance)?.toLocaleString('en-IN')}
                            </Text>
                            <Text style={{ color: 'black' }}>
                                Pending Amount:{' '}
                                {e.balance?.toLocaleString('en-IN')}
                            </Text>
                        </View>
                        <Text style={styles.description}>
                            यदि आपने पहले से ही भुगतान किया है, तो कृपया हमें
                            भुगतान तिथि और राशि व्हाट्सएप भेजें।
                        </Text>
                        <Text>
                            ----------------------------------------------------------------
                        </Text>
                    </View>
                ))}
            <View>
                <Image style={{ width: '180x', paddingTop: 10 }} src={sign} />
                <Text>-Authorized Signature</Text>
            </View>
        </Page>
    </Document>
);

const CustomerNotice = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const paramsData = {};
    for (const [key, value] of queryParams) {
        paramsData[key] = value;
    }
    const [invoiceData, setInvoiceData] = useState({});
    const currentDate = moment();
    const threeYearBackDate = moment().subtract(3, 'years');

    // console.log("current", currentDate.format("YYYY-MM-DD"));
    useEffect(() => {
        if (paramsData?.customerId) {
            const string = QueryString.stringify({
                customerId: paramsData.customerId,
            });
            authAxiosInstance
                .get(`/invoice/data/pendingInvoices?${string}`)
                .then((resp) => {
                    setInvoiceData(resp.data?.data);
                    console.log('pendingInvoicesRes', resp);
                });
        }
    }, []);
    console.log(invoiceData?.contactData?.contact, 'invoiceData');
    const contactInfo = invoiceData?.contactData?.contact;
    const invoiceInfo = useMemo(
        () =>
            invoiceData?.invoiceData ? invoiceData?.invoiceData.invoices : [],
        [invoiceData]
    );
    return (
        <>
            <div
                style={{
                    width: '100vw',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <div className="relative">
                    <PDFViewer className="w-[595px] h-[842px]">
                        <CustomerNoticeDocument
                            contactInfo={contactInfo}
                            invoiceInfo={invoiceInfo}
                            dateFrom={currentDate.format('DD-MM-YYYY')}
                            dateTo={threeYearBackDate.format('DD-MM-YYYY')}
                        />
                    </PDFViewer>
                    <PDFDownloadLink
                        document={
                            <CustomerNoticeDocument
                                contactInfo={contactInfo}
                                invoiceInfo={invoiceInfo}
                                dateFrom={currentDate.format('YYYY-MM-DD')}
                                dateTo={threeYearBackDate.format('YYYY-MM-DD')}
                            />
                        }
                        fileName="report.pdf"
                    >
                        <PrimaryButton className={'absolute top-0'}>
                            download
                        </PrimaryButton>
                    </PDFDownloadLink>
                </div>
            </div>
        </>
    );
};

export default CustomerNotice;
