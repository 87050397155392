import { createSlice } from '@reduxjs/toolkit';
import { generateSetEditId } from '../../generators/generateReducers';
import { generateStateSingle } from '../../generators/generateState';
import {
    createData,
    deleteData,
    editData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';
const SLICE_NAME = 'paymentTerms';

export const fetchPaymentTerms = fetchData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const editPaymentTerms = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchPaymentTerm = fetchEditData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const deletePaymentTerm = deleteData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const createPaymentTerm = createData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);

const paymentTerm = createSlice({
    name: SLICE_NAME,
    initialState: generateStateSingle(SLICE_NAME),
    reducers: {
        setEditId: generateSetEditId(),
    },
    extraReducers: {
        ...fetchData.generateExtraReducers(fetchPaymentTerms, SLICE_NAME),
        ...createData.generateExtraReducers(createPaymentTerm, SLICE_NAME),
        ...deleteData.generateExtraReducers(deletePaymentTerm, SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchPaymentTerm, SLICE_NAME),
        ...editData.generateExtraReducers(editPaymentTerms, SLICE_NAME),
    },
});

export const getPaymentTerm = (state) => state.paymentTerms;
export const { setEditId } = paymentTerm.actions;
export default paymentTerm.reducer;
