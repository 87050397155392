import { useDispatch, useSelector } from 'react-redux';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { editAttribute } from '../../app/reducers/Attributes/attributeSlice';
import { X } from 'react-feather';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';

const EditAttributeModal = ({
    isEditAttributeModalOpen,
    setIsEditAttributeModalOpen,
    selectedAttribute,
}) => {
    console.log(selectedAttribute, 'selectedAttribute');
    const dispatch = useDispatch();
    const { _id, __v, updatedAt, createdAt, name, type, ...restObj } =
        selectedAttribute ? selectedAttribute : {};
    const additionalValueArr = Object.keys(restObj).map((ele) => ({
        key: ele,
        value: restObj[ele],
    }));
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: name,
            type: type,
            additionalValue: additionalValueArr,
        },
        onSubmit: async (values) => {
            const { additionalValue, ...rest } = values;
            const additionalData = additionalValue.reduce((acc, ele) => {
                acc[ele.key] = ele.value;
                return acc;
            }, {});
            const finalObj = { _id, ...additionalData, ...rest };
            const updateAttrRes = await dispatch(editAttribute(finalObj));
            console.log(updateAttrRes);
            setIsEditAttributeModalOpen(false);
        },
    });
    return (
        <ModalBasic
            modalOpen={isEditAttributeModalOpen}
            setModalOpen={setIsEditAttributeModalOpen}
        >
            <div className="grid  pt-0 pb-3 pl-4 pr-4 rounded-2xl">
                <form onSubmit={formik.handleSubmit}>
                    <FormikInputGroup
                        label={'Name'}
                        formik={formik}
                        name={'name'}
                    />
                    <FormikInputGroup
                        label={'Type'}
                        formik={formik}
                        name={'type'}
                    />

                    <FormikProvider value={formik}>
                        <FieldArray
                            name="additionalValue"
                            render={(arrayHelpers) => {
                                return (
                                    <div>
                                        <div className="my-2">
                                            {formik.values.additionalValue.map(
                                                (ele, count) => (
                                                    <div
                                                        key={count}
                                                        className="flex  gap-3  items-center"
                                                    >
                                                        <FormikInputGroup
                                                            formik={formik}
                                                            label="Enter Key"
                                                            name={`additionalValue.${count}.key`}
                                                        />
                                                        <FormikInputGroup
                                                            formik={formik}
                                                            label="Enter value"
                                                            name={`additionalValue.${count}.value`}
                                                        />
                                                        <X
                                                            className="text-red-500 "
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                arrayHelpers.remove(
                                                                    count
                                                                );
                                                            }}
                                                        />
                                                        {/* <SecondaryButton
                            onClick={() => arrayHelpers.remove(count)}
                          >
                            Remove
                          </SecondaryButton> */}
                                                    </div>
                                                )
                                            )}
                                        </div>
                                        <div>
                                            <SecondaryButton
                                                onClick={() => {
                                                    arrayHelpers.push('');
                                                }}
                                            >
                                                Add Additional Fields
                                            </SecondaryButton>
                                        </div>
                                    </div>
                                );
                            }}
                        />
                    </FormikProvider>
                    <div className="my-2">
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            </div>
        </ModalBasic>
    );
};

export default EditAttributeModal;
