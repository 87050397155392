import { useEffect, useMemo, useState } from 'react';
import {
    fetchQualities,
    getQuality,
} from '../../app/reducers/Quality/qualitySlice';
import { useDispatch, useSelector } from 'react-redux';
import QualitySwatch from './QualitySwatch';

const ViewQualities = () => {
    const dispatch = useDispatch();
    const { quality, loading } = useSelector(getQuality);
    const [page, setPage] = useState(1);
    const qualityMemo = useMemo(
        () => (quality?.docs ? quality.docs : []),
        [page, quality]
    );

    useEffect(() => {
        dispatch(fetchQualities({ page, sort: { priority: -1 }, limit: 300 }));
    }, [page]);

    return (
        <div className="grid grid-cols-2 md:grid-cols-4 gap-3 p-4">
            <h1 className=" col-span-2 md:col-span-4 ">View Quality</h1>
            {qualityMemo.map((ele) => (
                <QualitySwatch quality={ele} key={ele._id} />
            ))}
        </div>
    );
};

export default ViewQualities;
