import React, { useEffect, useState } from 'react';
import { authAxiosInstance } from '../../utils/axiosConfig';
import _ from 'lodash';

//

export const Tracking = ({ trackingData }) => {
    const [scrapData, setScrapData] = useState(null);
    useEffect(() => {
        const getDeliveryStatus = async () => {
            try {
                if (
                    !_.isNull(trackingData.cf_tracking_id) &&
                    !_.isEmpty(trackingData.cf_tracking_id)
                ) {
                    const resp = await authAxiosInstance.get(
                        `invoice/delivery/status/${trackingData.cf_tracking_id}`
                    );
                    setScrapData(resp?.data?.data);
                }
            } catch (error) {
                console.log(error);
            }
        };
        getDeliveryStatus();
    }, [trackingData]);
    return (
        <>
            <div>{trackingData.cf_tracking_id}</div>
            <p>{scrapData?.currentStatus}</p>
            {scrapData?.link && (
                <a
                    className="underline text-indigo-600"
                    target="_blank"
                    href={`http://crm.markerp.in/${scrapData?.link}`}
                >
                    Scan Copy
                </a>
            )}
        </>
    );
};
