import { useFormik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    editOrganization,
    fetchOrganization,
    getOrganization,
} from '../../app/reducers/Organization/organizationSlice';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import _ from 'lodash';

export const EditOrganization = () => {
    const { id } = useParams();
    const { elementEditData } = useSelector(getOrganization);
    const dispatch = useDispatch();

    console.log(id);
    const formik = useFormik({
        initialValues: {
            name: '',
            refreshToken: '',
            clientID: '',
            secretID: '',
            organizationID: '',
            domainName: '',
            accessToken: '',
        },

        onSubmit: async (values, { resetForm }) => {
            console.log(values, 'check');
            await dispatch(editOrganization(values));
            resetForm();
        },
    });

    useEffect(() => {
        dispatch(fetchOrganization(id));
    }, []);

    useEffect(() => {
        if (!_.isEmpty(elementEditData)) {
            formik.setValues({ ...elementEditData });
        }
    }, [elementEditData]);

    return (
        <PageWithCard heading="Edit Organization">
            <form
                onSubmit={formik.handleSubmit}
                className="grid grid-rows-6 gap-3"
            >
                <FormikInputGroup
                    label="Name"
                    formik={formik}
                    name="name"
                    required
                />
                <FormikInputGroup
                    label="Token"
                    formik={formik}
                    name="refreshToken"
                    required
                />

                <FormikInputGroup
                    label="Client Id"
                    formik={formik}
                    name="clientID"
                    required
                />

                <FormikInputGroup
                    label="Organization Id"
                    formik={formik}
                    name="organizationID"
                    required
                />

                <FormikInputGroup
                    label="Domain"
                    formik={formik}
                    name="domainName"
                    required
                />
                <FormikInputGroup
                    label="Secret Id"
                    formik={formik}
                    name="secretID"
                    required
                />
                <FormikInputGroup
                    label="Access Token"
                    formik={formik}
                    name="accessToken"
                    required
                />

                <div>
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                </div>
            </form>
        </PageWithCard>
    );
};
