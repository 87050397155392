import React from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import _ from 'lodash';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { Clock, Edit2, PhoneCall } from 'react-feather';
import { useState } from 'react';
import {
    fetchPurchaseOrder,
    fetchPurchaseOrders,
    getPurchaseOrder,
} from '../../app/reducers/PurchaseOrder/purchaseOrderSlice';
import EditPurchaseModal from './EditPurchaseModal';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import moment from 'moment';
import { useFormik } from 'formik';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { generateOptions } from '../../utils/Utils';
import EditMenu from '../../components/DropdownEditMenu/DropdownEditMenu';
import axios from 'axios';
import { toast } from 'react-toastify';
import { customIDs } from '../../utils/customIds';
import AttachmentModal from './AttachmentModal';
import ContactModal from './ContactModal';
import EditDateModal from '../SplitQuarterData/EditDateModal';
import PrimaryBadge from '../../components/infrastructure/Badges/PrimaryBadge';
import { openReminderPopUp } from '../../app/reducers/ReminderPopUp/reminderPopUpSlice';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { ClipLoader, ClockLoader } from 'react-spinners';
import AdvanceSearchModal from './AdvanceSearchModal';

const ViewPurchaseOrder = () => {
    const [page, setPage] = useState(1);
    const [viewPurchaseModal, setPurchaseModal] = useState(false);
    const [loadingReminder, setLoadingReminder] = useState(false);

    const [viewAttachmentModal, setAttachmentModal] = useState(false);
    const [attachmentData, setAttachmentData] = useState(false);
    const [editData, setEditData] = useState({});
    const [spitModal, setSplitModal] = useState(false);
    const [contactModalOpen, setContactModalOpen] = useState(false);
    const [contactDetails, setContactDetails] = useState({});
    const [editDateModalOpen, setEditDateModalOpen] = useState(false);
    const [editPoId, setEditPoId] = useState(null);

    const organization_id = 637857442;
    const dispatch = useDispatch();

    const poStatusStyle = (status) => {
        if (status === 'billed') {
            return 'bg-gray-200 border-b-2 border-white';
        } else if (status == 'cancelled') {
            return 'bg-red-200 border-b-2 border-white';
        }
    };

    const { purchaseOrder, loading } = useSelector(getPurchaseOrder); // created on 16-11-22

    const purchaseOrdersData = useMemo(
        () =>
            purchaseOrder?.purchaseorders
                ? purchaseOrder.purchaseorders.map((d) => ({
                      ...d,
                      className: poStatusStyle(d.status),
                  }))
                : [],
        [purchaseOrder]
    );

    const purchaseOrderPageContext = useMemo(
        () => (purchaseOrder?.page_context ? purchaseOrder.page_context : []),
        [purchaseOrder]
    );

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            search: 'vendor',
        },
    });

    // const convertBill = async (data) => {
    //     setButtonId(data.purchaseorder_id)
    //     dispatch(convertToBill({ id: data.purchaseorder_id}))
    //     dispatch(fetchPurchaseOrders({ page: page,status:'open' }))
    // }
    // const { purchaseOrderLoading } = useSelector(getPurchaseOrder);

    useEffect(() => {
        dispatch(fetchPurchaseOrders({ page: page, status: 'open' }));
    }, [page]);

    const waModified = async (row) => {
        const action = await dispatch(
            fetchPurchaseOrder({ id: row.purchaseorder_id })
        );
        if (action.payload?.purchaseorder) {
            const vendorCheck = await axios.get(
                `https://app2.com.bot/webhook/641a5e12a52b8e34db70b32c?contact_type=vendor&po_id=${row.purchaseorder_id}&org_id=${organization_id}&contact_id=${row.vendor_id}&delivery_date=${row.delivery_date}`
            );
            if (vendorCheck.data?.accepted) {
                toast.success('Vendor True');
            } else {
                toast.error('Not accepted try again');
            }
            const customerCheck = await axios.get(
                `https://app2.com.bot/webhook/641a5e12a52b8e34db70b32c?contact_type=customer&po_id=${row.purchaseorder_id}&org_id=${organization_id}&contact_id=${action.payload.purchaseorder.delivery_customer_id}&delivery_date=${row.delivery_date}`
            );
            if (customerCheck.data?.accepted) {
                toast.success('Customer True');
            } else {
                toast.error('Not accepted try again');
            }
        } else {
            toast.error('Something Went Wrong.Please try again');
        }
    };

    const deliveryConfirmation = async (row) => {
        const action = await dispatch(
            fetchPurchaseOrder({ id: row.purchaseorder_id })
        );
        if (action.payload?.purchaseorder) {
            const deliveryCheck = await axios.get(
                `https://app2.com.bot/webhook/6475aeddb5c9a37453be5805?vendor_id=${row.vendor_id}&customer_id=${action.payload.purchaseorder.delivery_customer_id}&po_id=${row.purchaseorder_id}`
            );
            if (deliveryCheck.data?.accepted) {
                toast.success('Delivery Confiramtion :True');
            } else {
                toast.error('Not accepted try again');
            }
        } else {
            toast.error('Something Went Wrong.Please try again');
        }
    };

    const sendChallan = async (row) => {
        const action = await dispatch(
            fetchPurchaseOrder({ id: row.purchaseorder_id })
        );
        if (action.payload?.purchaseorder) {
            const sendChallanCheck = await axios.get(
                `https://app2.com.bot/webhook/64390a78985aa6cc38fefe9f?vendor_id=${row.vendor_id}&customer_id=${action.payload.purchaseorder.delivery_customer_id}&po_id=${row.purchaseorder_id}`
            );
            if (sendChallanCheck.data?.accepted) {
                toast.success('Delivery Confiramtion :True');
            } else {
                toast.error('Not accepted try again');
            }
        } else {
            toast.error('Something Went Wrong.Please try again');
        }
    };
    const sendAddressToVendor = async (row) => {
        const action = await dispatch(
            fetchPurchaseOrder({ id: row.purchaseorder_id })
        );
        if (action.payload?.purchaseorder) {
            const sendAddressCheck = await axios.get(
                `https://app2.com.bot/webhook/649a703d94773ee7a8db2482?vendor_id=${row.vendor_id}&customer_id=${action.payload.purchaseorder.delivery_customer_id}&po_id=${row.purchaseorder_id}`
            );
            if (sendAddressCheck.data?.accepted) {
                toast.success('Send Address Vendor Success');
            } else {
                toast.error('Not accepted try again');
            }
        } else {
            toast.error('Something Went Wrong.Please try again');
        }
    };

    const cols = [
        {
            Header: 'Action',
            Cell: ({ row, data }) => {
                return (
                    <div
                        className="flex items-center gap-2"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Edit2
                            color="blue"
                            className="cursor-pointer"
                            onClick={() => {
                                console.log(row);
                                setPurchaseModal(true);
                                setEditData({
                                    id: row.original.purchaseorder_id,
                                    page: page,
                                });
                            }}
                        />
                        {loadingReminder == row.original.purchaseorder_id ? (
                            <ClockLoader size={25} speedMultiplier={2} />
                        ) : (
                            <button
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    setLoadingReminder(
                                        row.original.purchaseorder_id
                                    );
                                    let popUpData = { ...row.original };
                                    console.log(row.original, 'check data');
                                    //Vendor Mobile No.
                                    const data = {
                                        id: row.original?.vendor_id,
                                    };
                                    const string = QueryString.stringify(data);
                                    const contacts =
                                        await authAxiosInstance.get(
                                            `purchase-orders/contacts/${data.id}?${string}`
                                        );
                                    if (contacts?.data?.data) {
                                        popUpData['vendorMobileNo'] =
                                            contacts?.data?.data?.contact?.contact_persons?.[0]?.mobile;
                                    }
                                    //Customer Mobile No.
                                    const customerData = {
                                        search: row.original?.cf_buyer_name,
                                    };
                                    const customerString =
                                        QueryString.stringify(customerData);
                                    const customerContacts =
                                        await authAxiosInstance.get(
                                            `purchase-orders/contacts?${customerString}`
                                        );
                                    console.log(customerContacts, 'new');
                                    if (customerContacts?.data?.data) {
                                        popUpData['customerMobileNo'] =
                                            customerContacts?.data?.data?.contacts?.[0]?.mobile;
                                    }
                                    dispatch(
                                        openReminderPopUp({
                                            isOpen: true,
                                            data: {
                                                ...popUpData,
                                                type: 'vendor',
                                                reminderType: 'PO_reminder',
                                                path: 'purchaseorders',
                                                path_id:
                                                    popUpData.purchaseorder_id,
                                            },
                                        })
                                    );
                                    setLoadingReminder('');
                                }}
                            >
                                <Clock
                                    size={25}
                                    color={
                                        row.original['cf_po_reminder'] ==
                                            'YES' ||
                                        row.original['cf_complaint_reminder'] ==
                                            'YES'
                                            ? '#ffff1a'
                                            : 'black'
                                    }
                                />
                            </button>
                        )}
                        <EditMenu align="left" className="relative inline-flex">
                            <li>
                                <span
                                    onClick={() => waModified(row.original)}
                                    className="font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3"
                                    style={{ cursor: 'pointer' }}
                                >
                                    WA Team Modified PO
                                </span>
                            </li>
                            <hr></hr>
                            <li>
                                <span
                                    onClick={() =>
                                        deliveryConfirmation(row.original)
                                    }
                                    className="font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3"
                                    style={{ cursor: 'pointer' }}
                                >
                                    Delivery Confirmation
                                </span>
                            </li>
                            <hr></hr>
                            <li>
                                <span
                                    onClick={() => sendChallan(row.original)}
                                    className="font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3"
                                    style={{ cursor: 'pointer' }}
                                >
                                    Send Challan
                                </span>
                            </li>
                            <hr></hr>
                            <li>
                                <span
                                    onClick={() =>
                                        sendAddressToVendor(row.original)
                                    }
                                    className="font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3"
                                    style={{ cursor: 'pointer' }}
                                >
                                    Send Address To Vendor
                                </span>
                            </li>
                        </EditMenu>
                        {/* {
                         btnId == row.original.purchaseorder_id && purchaseOrderLoading ? <ClipLoader /> :   
                        <PrimaryButton onClick={(e) => {
                            e.stopPropagation()
                            convertBill(row.original)
                        }} >Convert to Bill</PrimaryButton>
                        } */}
                    </div>
                );
            },
        },

        {
            Header: 'PURCHASE ORDER#',
            Cell: ({ row, data }) => {
                return (
                    <div
                        className={
                            row?.original?.has_attachment
                                ? 'underline text-blue-500 cursor-pointer'
                                : 'cursor-pointer'
                        }
                        onClick={(e) => {
                            e.stopPropagation();
                            setAttachmentData({
                                id: row.original.purchaseorder_id,
                                page: page,
                            });
                            setAttachmentModal(true);
                        }}
                    >
                        {row.original.purchaseorder_number}
                    </div>
                );
            },
            style: {
                width: '12%',
            },
        },
        {
            Header: 'QTY TAKA',
            accessor: 'cf_qty_taka',
            style: {
                width: '8%',
            },
        },
        {
            Header: 'QUALITY NAME',
            accessor: 'cf_quality_name',
        },
        {
            Header: 'VENDOR NAME',
            Cell: ({ data, row }) => {
                return (
                    <div className=" gap-4 flex ">
                        <span>{row?.original?.vendor_name}</span>
                        <PhoneCall
                            onClick={(e) => {
                                e.stopPropagation();
                                setContactModalOpen(true);
                                setContactDetails({
                                    contact_type: 'vendor',
                                    name: row.original.vendor_name,
                                });
                            }}
                            color="green"
                            size={18}
                        />
                    </div>
                );
            },
        },
        {
            Header: 'BUYER NAME',
            Cell: ({ data, row }) => {
                return (
                    <div className=" gap-4 flex flex-col">
                        <div className="flex gap-4">
                            <span>{row?.original?.cf_buyer_name}</span>
                            <PhoneCall
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setContactModalOpen(true);
                                    setContactDetails({
                                        contact_type: 'customer',
                                        name: row.original.cf_buyer_name,
                                    });
                                }}
                                color="blue"
                                size={18}
                            />
                        </div>
                        {row?.original?.['cf_po_note'] && (
                            <div className="flex">
                                <PrimaryBadge>
                                    {
                                        row?.original?.['cf_po_note']?.split(
                                            '-'
                                        )[0]
                                    }
                                </PrimaryBadge>
                                <span className="text-[0.5rem]">
                                    {
                                        row?.original?.['cf_po_note']?.split(
                                            '-'
                                        )[1]
                                    }
                                </span>
                            </div>
                        )}
                    </div>
                );
            },
        },
        {
            Header: 'DATE',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col">
                        <span
                            className="underline text-indigo-500 cursor-pointer"
                            onClick={(e) => {
                                e.stopPropagation();
                                setEditDateModalOpen(true);
                                setEditPoId(row.original.purchaseorder_id);
                            }}
                        >
                            Issue Date{' '}
                            {moment(row.original.date).format('DD/MM/YYYY')}
                        </span>
                        <span>
                            Delivery Date{' '}
                            {moment(row.original.delivery_date).format(
                                'DD/MM/YYYY'
                            )}
                        </span>
                    </div>
                );
            },
            style: {
                width: '20%',
            },
        },
        {
            Header: 'RATE',
            accessor: 'cf_rate',
        },
        {
            Header: 'AMOUNT',
            accessor: 'total',
        },
    ];

    const handleNext = (e) => {
        e.preventDefault();
        setPage(page + 1);
    };

    const debouncedSearch = useCallback(
        _.debounce((search) => {
            let data = {};
            if (formik.values.search == 'vendor') {
                data['vendor_name_contains'] = search;
            } else {
                data[
                    `custom_field_${customIDs.purchase_custom_buyerName}_contains`
                ] = search;
            }

            dispatch(
                fetchPurchaseOrders({
                    ...data,
                    page: 1,
                    status: 'open',
                })
            );
        }, 300),
        [JSON.stringify(formik.values)]
    );

    const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
    // const advanceSearchFormik = useFormik({
    //     enableReinitialize: true,
    //     initialValues: {
    //         vendor: '',
    //         customer: '',
    //         quality: [],
    //         rate: '',
    //         qty_taka: '',
    //         date_range: '',
    //     },
    //     onSubmit: (values) => {},
    // });
    const [advanceSearchData, setAdvanceSearchData] = useState({
        reset: true,
        data: {},
        hasMore: false,
    });

    return (
        <div className="p-4">
            <AdvanceSearchModal
                // formik={advanceSearchFormik}
                setAdvanceSearchData={setAdvanceSearchData}
                title="Advance Search"
                isOpen={showAdvanceSearch}
                setIsOpen={setShowAdvanceSearch}
            />
            <EditPurchaseModal
                title={'Edit Purchase Order'}
                setPurchaseModal={setPurchaseModal}
                viewPurchaseModal={viewPurchaseModal}
                data={editData}
            />
            <AttachmentModal
                viewAttachmentModal={viewAttachmentModal}
                setAttachmentModal={setAttachmentModal}
                data={attachmentData}
            />
            <EditDateModal
                editDateModalOpen={editDateModalOpen}
                setEditDateModalOpen={setEditDateModalOpen}
                editPoId={editPoId}
                setEditPoId={setEditPoId}
            />
            <ContactModal
                setContactModalOpen={setContactModalOpen}
                contactModalOpen={contactModalOpen}
                contactDetails={contactDetails}
            />
            {/* <PrimaryButton
                className="mb-2"
                onClick={(e) => {
                    e.stopPropagation();
                    dispatch(fetchPurchaseOrders());
                }}
            >
                Fetch All
            </PrimaryButton> */}
            <div className="mb-3 flex gap-2 ">
                <FormikSelectGroup
                    className={''}
                    formik={formik}
                    label="Search Type"
                    name={`search`}
                    onChange={(selectedOption) => {
                        dispatch(
                            fetchPurchaseOrders({
                                page: page,
                                status: 'open',
                            })
                        );
                        formik.setFieldValue('search', selectedOption.value);
                    }}
                    options={generateOptions({
                        array: [
                            { label: 'Vendor', value: 'vendor' },
                            { label: 'Customer', value: 'customer' },
                        ],
                        labelField: 'label',
                        valueField: 'value',
                    })}
                    required
                />
                <div className="w-[20%] mt-auto">
                    <PrimaryButton
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowAdvanceSearch(true);
                        }}
                        className={'w-full'}
                    >
                        Advance Search
                    </PrimaryButton>
                </div>
            </div>
            {advanceSearchData.hasMore && (
                <span className="text-red-500 my-3">
                    Warning: Found 200 or more purchase orders.
                </span>
            )}
            {!advanceSearchData.reset &&
                Object.values(advanceSearchData.data).map(
                    (purchaseOrders, i) => {
                        let countQtyTaka = purchaseOrders.reduce((acc, ele) => {
                            acc += Number(ele.cf_qty_taka);
                            return acc;
                        }, 0);

                        let modifiedPurchaseOrder = purchaseOrders.map((d) => ({
                            ...d,
                            className: poStatusStyle(d.status),
                        }));

                        return (
                            <div className="py-4" key={i}>
                                <TableWithHeadingAndGlobalSearch
                                    heading={`${purchaseOrders[0]?.cf_quality_name} || (${countQtyTaka})`}
                                    placeholder={`Search for ${
                                        formik.values.search == 'vendor'
                                            ? 'vendor Name'
                                            : 'customer Name'
                                    }`}
                                    data={modifiedPurchaseOrder}
                                    loading={loading}
                                    columns={cols}
                                    searchFunction={(search) => {
                                        debouncedSearch(search);
                                    }}
                                />
                            </div>
                        );
                    }
                )}
            {advanceSearchData.reset && (
                <>
                    <TableWithHeadingAndGlobalSearch
                        heading={'Purchase Orders'}
                        placeholder={`Search for ${
                            formik.values.search == 'vendor'
                                ? 'vendor Name'
                                : 'customer Name'
                        }`}
                        data={purchaseOrdersData}
                        loading={loading}
                        columns={cols}
                        searchFunction={(search) => {
                            debouncedSearch(search);
                        }}
                    />
                    {!loading && (
                        <div className="mt-4 flex gap-3 justify-end">
                            {page > 1 && (
                                <PrimaryButton
                                    onClick={(e) => setPage(page - 1)}
                                >
                                    Previous
                                </PrimaryButton>
                            )}
                            {purchaseOrderPageContext.has_more_page && (
                                <PrimaryButton onClick={(e) => handleNext(e)}>
                                    Next
                                </PrimaryButton>
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default ViewPurchaseOrder;
