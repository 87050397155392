import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { authAxiosInstance } from '../../utils/axiosConfig';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import {
    Document,
    Page,
    Image,
    View,
    PDFDownloadLink,
    StyleSheet,
    PDFViewer,
    Text,
    Font,
    Polygon,
    Svg,
    Ellipse,
} from '@react-pdf/renderer';

import moment from 'moment';
import { Chart as ChartJs, Legend, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJs.register(Legend, ChartDataLabels, ...registerables);
import font from '../../assets/hindi.ttf';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { Award } from 'react-feather';

Font.register({
    family: 'hindi',
    src: font,
});

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 45,
        paddingHorizontal: 15,
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        position: 'relative',
    },
    table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        margin: 'auto',
        flexDirection: 'row',
    },
    tableCol: {
        width: '11.40%', // Adjust column width as needed
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCell: {
        margin: 'auto',
        marginTop: 5,
        fontSize: 10,
    },
    tc: {
        fontFamily: 'hindi',
        fontSize: 12,
    },
    heading: {
        fontSize: 14,
    },
    title: {
        textAlign: 'center',
        fontSize: '14',
    },
    subTitle: {
        fontSize: 20,
        paddingVertical: '1px',
        borderBottom: '1px solid black',
        margin: 'auto',
    },
    totalRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
    },
    signature: {
        width: '30%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },

    // totalRow: {
    //     display: "flex",
    //     flexDirection: "row"

    // }
});
const PendingListDocument = ({ data, barChartImage }) => {
    const tableHeadings = [
        'DATE',
        'DELIVERY DATE',
        'P.O#',
        'VENDOR NAME',
        // 'CUSTOMER NAME',
        'QTY TAKA',
        'QUALITY NAME',
        'RATE',
        'PENDING TAKA',
    ];
    console.log('data=>', data);
    const tableData = data.map((ele) => [
        moment(ele.date).format('DD/MM/YYYY'),
        moment(ele.delivery_date).format('DD/MM/YYYY'),
        // `${ele.po_number} ${!ele.priority == "High" ? "🔖" : ""}`,
        `${ele.po_number} `,
        // `${ele.po_number}`,
        ele.vendor_name,
        // ele.customer_name,
        ele.qty_taka,
        ele.quality_name,
        ele.rate,
        ele.pending_order_taka,
    ]);

    const qtyTakaTotal = data.reduce((acc, ele) => {
        return acc + Number(ele.qty_taka);
    }, 0);
    const pendingQtyTakaTotal = data.reduce((acc, ele) => {
        return acc + Number(ele.pending_order_taka);
    }, 0);

    return (
        <Document>
            <Page style={styles.body}>
                {/* Render the table */}

                <View>
                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '4px',
                        }}
                    >
                        <Text style={styles.title}>Pending Order List</Text>
                        <Text style={styles.subTitle}>
                            {data?.[0]?.customer_name?.toUpperCase()}
                        </Text>
                    </View>
                    <View style={styles.totalRow}>
                        <View style={{ width: '75%' }}></View>
                        <View style={{ paddingVertical: '8px' }}>
                            <Text style={{ fontSize: 14 }}>
                                {' '}
                                Date: {moment().format('DD/MM/YYYY')}
                            </Text>
                        </View>
                    </View>
                    <View style={styles.table}>
                        {/* Table Headings */}

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>DATE</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    DELIVERY DATE
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>P.O#</Text>
                            </View>
                            <View style={{ ...styles.tableCol, width: '20%' }}>
                                <Text style={styles.tableCell}>
                                    VENDOR NAME
                                </Text>
                            </View>
                            <View style={{ ...styles.tableCol, width: '8%' }}>
                                <Text style={styles.tableCell}>QTY TAKA</Text>
                            </View>
                            <View style={{ ...styles.tableCol, width: '15%' }}>
                                <Text style={styles.tableCell}>
                                    QUALITY NAME
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>RATE</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text
                                    style={{
                                        ...styles.tableCell,
                                        textAlign: 'center',
                                        width: '80%',
                                    }}
                                >
                                    PENDING TAKA
                                </Text>
                            </View>
                        </View>
                        {/* Table Data */}

                        {data.map((ele, index) => (
                            <View style={styles.tableRow} key={index}>
                                {/* {rowData.map((cellData, cellIndex) => (
                                    cellIndex == 3 ?
                                        <View style={{ ...styles.tableCol, width: "20%" }} key={cellIndex}>
                                            <Text style={styles.tableCell}>{cellData}</Text>
                                        </View>
                                        :

                                        <View style={styles.tableCol} key={cellIndex}>
                                            <Text style={styles.tableCell}>{cellData}</Text>
                                        </View>
                                ))} */}
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {moment(ele.date).format('DD/MM/YYYY')}
                                    </Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {moment(ele.delivery_date).format(
                                            'DD/MM/YYYY'
                                        )}
                                    </Text>
                                </View>
                                <View
                                    style={{
                                        ...styles.tableCol,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Text
                                        style={{
                                            ...styles.tableCell,
                                            color: `${ele.priority == 'EVERYDAY FOLLOWUP' ? 'red' : 'black'}`,
                                        }}
                                    >
                                        {ele.po_number}
                                    </Text>
                                </View>
                                <View
                                    style={{ ...styles.tableCol, width: '20%' }}
                                >
                                    <Text
                                        style={{
                                            ...styles.tableCell,
                                            width: '90%',
                                        }}
                                    >
                                        {ele.vendor_name}
                                    </Text>
                                </View>
                                <View
                                    style={{ ...styles.tableCol, width: '8%' }}
                                >
                                    <Text style={styles.tableCell}>
                                        {ele.qty_taka}
                                    </Text>
                                </View>
                                <View
                                    style={{ ...styles.tableCol, width: '15%' }}
                                >
                                    <Text style={styles.tableCell}>
                                        {ele.quality_name}
                                    </Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {ele.rate}
                                    </Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {ele.pending_order_taka}
                                    </Text>
                                </View>
                            </View>
                        ))}
                        <View style={styles.totalRow}>
                            <View
                                style={{
                                    width: '51.45%',
                                    border: '1px solid black',
                                    paddingVertical: '4px',
                                }}
                            >
                                <Text style={styles.tableCell}>TOTAL</Text>
                            </View>
                            <View
                                style={{
                                    width: '7.80%',
                                    border: '1px solid black',
                                    paddingVertical: '4px',
                                }}
                            >
                                <Text style={styles.tableCell}>
                                    {qtyTakaTotal}
                                </Text>
                            </View>
                            <View
                                style={{
                                    width: '24.55%',
                                    border: '1px solid black',
                                }}
                            >
                                <Text style={styles.tableCell}></Text>
                            </View>
                            <View
                                style={{
                                    width: '10.80%',
                                    border: '1px solid black',
                                    paddingVertical: '4px',
                                }}
                            >
                                <Text style={styles.tableCell}>
                                    {pendingQtyTakaTotal}
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>
                <Image src={barChartImage} style={{ width: '500' }} />
            </Page>
            <Page style={styles.body}>
                <Text style={styles.heading}>Terms and Condition:</Text>
                <View>
                    <Text style={styles.tc}>
                        1. जानकारी में ±10% त्रुटि हो सकती है।
                    </Text>
                    <Text style={styles.tc}>
                        2. विक्रेता द्वारा दी गई डिलीवरी उपरोक्त स्टेटमेंट में
                        से कम करके सूचना दी गई है।
                    </Text>
                    <Text style={styles.tc}>
                        3. PO नंबर के पश्चात ++ चिह्न अनेक संलग्न PO नंबरों का
                        संकेत है।
                    </Text>
                </View>
                <View>
                    <Text style={styles.tc}>
                        लाल टिक वाली प्रविष्टियां उच्च प्राथमिकता में हैं, जल्दी
                        पूरी करेंगे।
                    </Text>
                </View>
                <View
                    style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'flex-end',
                        marginTop: '20px',
                    }}
                >
                    <View style={styles.signature}>
                        <Image
                            style={{ width: '100%' }}
                            src="/E-Signature.png"
                        />
                        <Text style={{ fontSize: '9px', textAlign: 'right' }}>
                            {' '}
                            - Authorized Signature
                        </Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

const DownloadPendingOrderList = () => {
    const { id } = useParams();
    const [POLData, setPOLData] = useState([]);
    useEffect(() => {
        authAxiosInstance
            .get(`/pendingOrderList/${id}`)
            .then((res) => setPOLData(res.data.data.data));
    }, []);
    console.log('polDAta', POLData);
    const barRef = useRef();
    const [barImage, setBarImage] = useState(null);
    const qualitiesArr = POLData.reduce((acc, ele, index) => {
        if (acc[ele.quality_name]) {
            acc[ele.quality_name] =
                Number(ele.pending_order_taka) + Number(acc[ele.quality_name]);
            console.log(acc[ele.quality_name]);
        } else {
            acc[ele.quality_name] = Number(ele.pending_order_taka);
        }

        return acc;
    }, {});

    console.log('qualitiesArr', qualitiesArr);
    return (
        <>
            <div className="absolute opacity-0 w-[500px] ">
                <Bar
                    ref={barRef}
                    data={{
                        labels: Object.keys(qualitiesArr),
                        // labels: ['test1', "test2", "test3"],
                        datasets: [
                            {
                                label: 'Pending  Order Taka',
                                // data: [45, 55, 65],
                                data: Object.values(qualitiesArr),
                                borderRadius: 10,
                                backgroundColor: '#66BA82',
                                barThickness: 15,
                                maxBarThickness: 30,
                            },
                        ],
                    }}
                    options={{
                        scales: {
                            x: {
                                grace: '10%',
                            },
                        },
                        events: [],
                        indexAxis: 'y',

                        x: {
                            title: {
                                display: true,
                                text: 'testingX',
                            },
                        },
                        y: {
                            title: {
                                display: true,
                                text: 'testingY',
                            },
                        },
                        plugins: {
                            // legend: {
                            //     position: 'right',
                            // },
                            title: {
                                position: 'top',
                            },
                            datalabels: {
                                anchor: 'end',
                                align: 'right',
                                formatter: Math.round,
                                font: {
                                    weight: 'bold',
                                },
                            },
                        },
                        animation: {
                            duration: 100,
                            onComplete: () => {
                                const image =
                                    barRef?.current?.toBase64Image?.();
                                setBarImage(image);
                            },
                        },
                    }}
                />
            </div>

            {barImage && (
                <div className="flex items-center justify-center relative">
                    <PDFViewer className="w-[595px] h-[842px]">
                        <PendingListDocument
                            data={POLData}
                            barChartImage={barImage}
                        />
                    </PDFViewer>
                    <div className="absolute top-0 left-0">
                        <PDFDownloadLink
                            document={
                                <PendingListDocument
                                    data={POLData}
                                    barChartImage={barImage}
                                />
                            }
                        >
                            {({ loading }) =>
                                loading ? (
                                    <PrimaryButton disabled={true}>
                                        loading...
                                    </PrimaryButton>
                                ) : (
                                    <PrimaryButton>Download</PrimaryButton>
                                )
                            }
                        </PDFDownloadLink>
                    </div>
                </div>
            )}
        </>
    );
};

export default DownloadPendingOrderList;
