import { FieldArray, FormikProvider, useFormik } from 'formik';

import { useDispatch, useSelector } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
// import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import Modal from 'react-modal';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import DatePicker from 'react-datepicker';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { useEffect, useMemo, useState } from 'react';
import {
    fetchReminderSubjects,
    getReminderSubject,
} from '../../app/reducers/ReminderSubject/reminderSubjectSlice';
import { generateOptions } from '../../utils/Utils';
import {
    createReminderPopUp,
    openReminderPopUp,
} from '../../app/reducers/ReminderPopUp/reminderPopUpSlice';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikTextareaGroup from '../../components/formik/FormikTextareaGroup';
import {
    fetchContact,
    getCustomer,
} from '../../app/reducers/Customer/customerSlice';
import { ClipLoader } from 'react-spinners';
import { X } from 'react-feather';
import FormikTime from '../../components/formik/FormikTime';
import moment from 'moment';
import ShowDropdown from '../../components/infrastructure/ShowDropdown';
import { REMINDER_TYPE_OPTION } from '../../utils/dropdownOptions';
const ReminderPopUp = ({ isOpen, setIsOpen, data }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { reminderSubject } = useSelector(getReminderSubject);
    useEffect(() => {
        dispatch(fetchReminderSubjects());
    }, [data]);

    const subjectData = useMemo(
        () => reminderSubject.docs ?? [],
        [reminderSubject.docs]
    );

    const formik = useFormik({
        initialValues: {
            type: data?.type ? data?.type : '',
            poNumber: data?.purchaseorder_number
                ? data?.purchaseorder_number
                : '',
            customerName: data?.cf_buyer_name ? data?.cf_buyer_name : '',
            vendorName: data?.vendor_name ? data?.vendor_name : '',
            qualityName: data?.cf_quality_name ? data?.cf_quality_name : '',
            quantity: data?.cf_qty_taka ? data?.cf_qty_taka : '',
            pdfLink: '',
            notes: '',
            subject: '',
            //Date Time Fields Start
            selectTime: 'default',
            date: moment(),
            time: new Date(),
            defaultTime: '',
            //Date Time Fields Ends
            vendorMobileNo: data?.vendorMobileNo ? data?.vendorMobileNo : '',
            customerMobileNo: data?.customerMobileNo
                ? data?.customerMobileNo
                : '',
            reminderType: data.reminderType ?? '',
            purchaseorder_id: data.purchaseorder_id ?? '',
        },
        onSubmit: async (values, { resetForm }) => {
            setLoading(true);
            let dateTimeNew;
            if (values.selectTime == 'default') {
                let dateTime = moment(values.date).format('DD/MM/YYYY');
                dateTimeNew = `${dateTime} ${values.defaultTime}`;
            } else {
                let dateTime = moment(values.date).format('DD/MM/YYYY');
                dateTimeNew = `${dateTime} ${values.time.toTimeString()}`;
            }
            let finalDate = moment(dateTimeNew, 'DD/MM/YYYY HH:mm:ss Z').unix();
            delete values.selectTime;
            delete values.time;
            delete values.defaultTime;
            let additionalData = {
                orderDate: data?.date,
                deliveryDate: data?.delivery_date,
                rate: data?.cf_rate,
            };
            if (values.inputSubject) {
                values.subject = values.inputSubject;
            }
            await dispatch(
                createReminderPopUp({
                    ...values,
                    ...additionalData,
                    date: finalDate,
                    path: data.path,
                    path_id: data.path_id,
                })
            );
            setLoading(false);
            dispatch(openReminderPopUp({ isOpen: false, data: {} }));
        },
    });
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '1000px',
            width: '-webkit-fill-available',
            maxHeight: '80%',
            overflow: 'auto',
        },
    };
    return (
        <Modal
            isOpen={isOpen}
            style={customStyles}
            contentLabel="Example Modal"
            onRequestClose={(e) => {
                setIsOpen();
            }}
        >
            <div className="m-4">
                <h4 className="font-bold mb-5">
                    Reminder Pop Up
                    <span className="float-right cursor-pointer">
                        <X
                            onClick={(e) => {
                                setIsOpen();
                            }}
                        />
                    </span>
                </h4>
                {/* Mobile Code */}
                <form
                    className=" sm:hidden flex flex-col gap-3"
                    onSubmit={formik.handleSubmit}
                >
                    <FormikSelectGroup
                        label="Type"
                        formik={formik}
                        name="type"
                        required
                        options={generateOptions({
                            array: [
                                { label: 'Customer', value: 'customer' },
                                { label: 'Vendor', value: 'vendor' },
                            ],
                            labelField: 'label',
                            valueField: 'value',
                        })}
                    />
                    {/* <div>
                        <div className="block text-sm font-medium">Date</div>
                        <DatePicker
                            selected={formik.values.date}
                            onChange={(date) =>
                                formik.setFieldValue('date', date)
                            }
                        />
                    </div> */}
                    <FormikInputDateGroup
                        formik={formik}
                        name={'date'}
                        label="Date"
                    />
                    <FormikInputGroup
                        name="vendorName"
                        formik={formik}
                        label="Vendor Name"
                    />
                    <FormikInputGroup
                        name="customerName"
                        formik={formik}
                        label="Customer Name"
                    />
                    <FormikInputGroup
                        name="poNumber"
                        formik={formik}
                        label="PO No."
                        readOnly
                    />
                    <FormikSelectGroup
                        label="Reminder Subject"
                        formik={formik}
                        name="subject"
                        required
                        options={generateOptions({
                            array: subjectData.filter(
                                (d) => d.type === formik.values.type
                            ),
                            labelField: 'name',
                            valueField: 'name',
                        })}
                    />
                    <div className="flex flex-col gap-3">
                        <FormikSelectGroup
                            label="Select Time Type"
                            formik={formik}
                            name="selectTime"
                            required
                            options={generateOptions({
                                array: [
                                    { label: 'Default', value: 'default' },
                                    { label: 'Other', value: 'other' },
                                ],
                                labelField: 'label',
                                valueField: 'value',
                            })}
                        />
                        {formik.values.selectTime == 'default' && (
                            <>
                                <div>Time</div>
                                <div className="flex flex-wrap gap-5">
                                    <div>
                                        <input
                                            type="radio"
                                            id="11"
                                            name="defaultTime"
                                            onChange={formik.handleChange}
                                            value="11:00:00"
                                        />
                                        <label className="ml-2" for="9">
                                            11:00 AM
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            id="1"
                                            name="defaultTime"
                                            onChange={formik.handleChange}
                                            value="13:00:00"
                                        />
                                        <label className="ml-2" for="11">
                                            01:00 PM
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            id="4"
                                            name="defaultTime"
                                            onChange={formik.handleChange}
                                            value="16:00:00"
                                        />
                                        <label className="ml-2" for="4">
                                            04:00 PM
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            id="6"
                                            name="defaultTime"
                                            onChange={formik.handleChange}
                                            value="18:00:00"
                                        />
                                        <label className="ml-2" for="6">
                                            06:00 PM
                                        </label>
                                    </div>
                                    <br></br>
                                </div>
                            </>
                        )}
                        {formik.values.selectTime == 'other' && (
                            <>
                                <FormikTime
                                    name="time"
                                    formik={formik}
                                    label="Time"
                                />
                            </>
                        )}
                    </div>
                    <ShowDropdown
                        heading={'Mobile No.'}
                        allowedRoles={['admin']}
                    >
                        <div className="grid grid-cols-2 gap-2">
                            <FormikInputGroup
                                name="vendorMobileNo"
                                formik={formik}
                                label="Vendor Mobile No."
                            />
                            <FormikInputGroup
                                name="customerMobileNo"
                                formik={formik}
                                label="Customer Mobile No."
                            />
                        </div>
                    </ShowDropdown>
                    <ShowDropdown
                        heading={'PDF link/text'}
                        allowedRoles={['admin']}
                    >
                        <FormikInputGroup
                            name="pdfLink"
                            formik={formik}
                            label="Pdf Link/Text"
                        />
                    </ShowDropdown>
                    <FormikInputGroup
                        name="quantity"
                        formik={formik}
                        label="Quantity"
                    />
                    <FormikInputGroup
                        name="qualityName"
                        formik={formik}
                        label="Quality Name"
                    />
                    <FormikTextareaGroup
                        name="notes"
                        formik={formik}
                        label="Notes"
                    />
                    <div className="mt-2">
                        {loading ? (
                            <ClipLoader />
                        ) : (
                            <PrimaryButton
                                disabled={formik.isSubmitting}
                                type="submit"
                            >
                                Submit
                            </PrimaryButton>
                        )}
                    </div>
                </form>
                {/* Desktop Code */}
                <form
                    onSubmit={formik.handleSubmit}
                    className="hidden sm:block  flex-col gap-4"
                >
                    <div className="grid grid-cols-2">
                        <FormikSelectGroup
                            label="Type"
                            formik={formik}
                            name="type"
                            required
                            options={generateOptions({
                                array: [
                                    {
                                        label: 'Customer',
                                        value: 'customer',
                                    },
                                    { label: 'Vendor', value: 'vendor' },
                                ],
                                labelField: 'label',
                                valueField: 'value',
                            })}
                        />
                        {/* <div className="col-start-8">
                            <div className="block text-sm font-medium">
                                Date
                            </div>
                            <DatePicker
                                selected={formik.values.date}
                                onChange={(date) =>
                                    formik.setFieldValue('date', date)
                                }
                            />
                        </div> */}
                        <FormikInputDateGroup
                            formik={formik}
                            name={'date'}
                            label="Date"
                        />
                    </div>
                    <div className="grid grid-cols-3 gap-2">
                        <div>
                            <FormikInputGroup
                                name="vendorName"
                                formik={formik}
                                label="Vendor Name"
                            />
                        </div>
                        <div>
                            <FormikInputGroup
                                name="customerName"
                                formik={formik}
                                label="Customer Name"
                            />
                        </div>
                        <div>
                            <FormikInputGroup
                                name="poNumber"
                                formik={formik}
                                label="PO No."
                                readOnly
                            />
                        </div>
                    </div>
                    <FormikSelectGroup
                        label="Reminder Subject"
                        formik={formik}
                        name="subject"
                        required
                        options={generateOptions({
                            array: [
                                { name: 'other' },
                                ...subjectData.filter(
                                    (d) => d.type === formik.values.type
                                ),
                            ],
                            labelField: 'name',
                            valueField: 'name',
                        })}
                    />
                    {formik.values?.subject == 'other' && (
                        <FormikInputGroup
                            name={'inputSubject'}
                            label="Write Your Subject"
                            formik={formik}
                        />
                    )}
                    <div className="grid grid-cols-2 gap-2">
                        <div>
                            <FormikSelectGroup
                                label="Select Time Type"
                                formik={formik}
                                name="selectTime"
                                required
                                options={generateOptions({
                                    array: [
                                        { label: 'Default', value: 'default' },
                                        { label: 'Other', value: 'other' },
                                    ],
                                    labelField: 'label',
                                    valueField: 'value',
                                })}
                            />
                        </div>
                        <div>
                            {formik.values.selectTime == 'default' && (
                                <>
                                    <div>Time</div>
                                    <div className="flex flex-wrap gap-5">
                                        <div>
                                            <input
                                                type="radio"
                                                id="11"
                                                name="defaultTime"
                                                onChange={formik.handleChange}
                                                value="11:00:00"
                                            />
                                            <label className="ml-2" for="9">
                                                11:00 AM
                                            </label>
                                        </div>
                                        <div>
                                            <input
                                                type="radio"
                                                id="1"
                                                name="defaultTime"
                                                onChange={formik.handleChange}
                                                value="13:00:00"
                                            />
                                            <label className="ml-2" for="11">
                                                01:00 PM
                                            </label>
                                        </div>
                                        <div>
                                            <input
                                                type="radio"
                                                id="4"
                                                name="defaultTime"
                                                onChange={formik.handleChange}
                                                value="16:00:00"
                                            />
                                            <label className="ml-2" for="4">
                                                04:00 PM
                                            </label>
                                        </div>
                                        <div>
                                            <input
                                                type="radio"
                                                id="6"
                                                name="defaultTime"
                                                onChange={formik.handleChange}
                                                value="18:00:00"
                                            />
                                            <label className="ml-2" for="6">
                                                06:00 PM
                                            </label>
                                        </div>
                                        <br></br>
                                    </div>
                                </>
                            )}
                            {formik.values.selectTime == 'other' && (
                                <>
                                    <FormikTime
                                        name="time"
                                        formik={formik}
                                        label="Time"
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-2">
                        <div>
                            <ShowDropdown
                                heading={'Mobile No.'}
                                allowedRoles={['admin']}
                            >
                                <div className="grid grid-cols-2 gap-2">
                                    <FormikInputGroup
                                        name="vendorMobileNo"
                                        formik={formik}
                                        label="Vendor Mobile No."
                                    />
                                    <FormikInputGroup
                                        name="customerMobileNo"
                                        formik={formik}
                                        label="Customer Mobile No."
                                    />
                                </div>
                            </ShowDropdown>
                        </div>
                        <div>
                            <FormikInputGroup
                                name="qualityName"
                                formik={formik}
                                label="Quality Name"
                            />
                        </div>
                        {/* 
                        <div className="flex gap-2">
                            <FormikSelectGroup
                                name={'reminderType'}
                                label="Reminder Type"
                                formik={formik}
                                options={REMINDER_TYPE_OPTION}
                            />
                        </div>
                         */}
                    </div>
                    <div className="grid grid-cols-2 gap-2">
                        <div>
                            <ShowDropdown
                                heading={'PDF link/text'}
                                allowedRoles={['admin']}
                            >
                                <FormikInputGroup
                                    name="pdfLink"
                                    formik={formik}
                                    label="Pdf Link/Text"
                                />
                            </ShowDropdown>
                        </div>
                        <div>
                            <FormikInputGroup
                                name="quantity"
                                formik={formik}
                                label="Quantity"
                            />
                        </div>
                    </div>
                    <FormikTextareaGroup
                        name="notes"
                        formik={formik}
                        label="Notes"
                    />
                    <div className="mt-2">
                        {loading ? (
                            <ClipLoader />
                        ) : (
                            <PrimaryButton
                                disabled={formik.isSubmitting}
                                type="submit"
                            >
                                Submit
                            </PrimaryButton>
                        )}
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default ReminderPopUp;
