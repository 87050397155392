import { useFormik } from 'formik';
import { useEffect } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { ClipLoader } from 'react-spinners';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import {
    fetchQualities,
    getQuality,
    createQuality,
    deleteQuality,
    setEditId,
} from '../../app/reducers/Quality/qualitySlice';
import { Edit2, Trash } from 'react-feather';
import { useMemo } from 'react';
import EditQuality from './EditQuality';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { QUALITY_TYPE } from '../../utils/dropdownOptions';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';

const Quality = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchQualities({ limit: 300, sort: { priority: -1 } }));
    }, []);
    const { loading, quality } = useSelector(getQuality);

    const columns = [
        {
            Header: 'SRNO',
            Cell: (prop) => {
                const { data, row } = prop;
                return row.index + 1;
            },
        },
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Tag',
            accessor: 'tag',
        },
        {
            Header: 'Type',
            accessor: 'type',
        },
        {
            Header: 'Action',
            Cell: (prop) => {
                const { data, row } = prop;
                return (
                    <div
                        className="flex items-center gap-2"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Edit2
                            color="blue"
                            onClick={() => {
                                dispatch(setEditId(row.original._id));
                            }}
                        />
                        <Trash
                            color="red"
                            onClick={async () => {
                                await dispatch(
                                    deleteQuality({ id: row.original._id })
                                );
                                dispatch(
                                    fetchQualities({
                                        limit: 300,
                                        sort: { priority: -1 },
                                    })
                                );
                            }}
                        />
                    </div>
                );
            },
        },
    ];

    // const debouncedSearch = useCallback(
    //   _.debounce((search) => {
    //     dispatch(fetchQualities({ search: search }));
    //   }, 300),
    //   []
    // );
    const data = useMemo(() => (quality?.docs ? quality.docs : []), [quality]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            tag: '',
            priority: '',
            type: QUALITY_TYPE[0].value,
        },
        validationSchema: Yup.object({
            name: Yup.string().required(),
        }),
        onSubmit: async (values, { resetForm }) => {
            console.log(values);
            await dispatch(createQuality({ ...values }));
            dispatch(fetchQualities({ limit: 300, sort: { priority: -1 } }));

            resetForm();
        },
    });

    return (
        <PageWithCard heading="Quality">
            <EditQuality />
            <div className="text-slate-800 font-semibold mb-4">
                Quality Names
            </div>
            {loading ? (
                <ClipLoader />
            ) : (
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-4 mb-2"
                >
                    <FormikInputGroup
                        label="Name"
                        formik={formik}
                        name="name"
                        required
                    />
                    <FormikInputGroup label="Tag" formik={formik} name="tag" />
                    <FormikInputGroup
                        label="Priority"
                        formik={formik}
                        name={'priority'}
                    />
                    <FormikSelectGroup
                        formik={formik}
                        label="Select type"
                        name={'type'}
                        options={QUALITY_TYPE}
                    />
                    {/* <FormikColorSlider label="Color" formik={formik} name={"color"} /> */}
                    <div>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            )}
            <TableWithHeadingAndSearch
                heading="Qualities"
                data={data}
                columns={columns}
                loading={loading}
                // searchFunction={(value) => {
                //   debouncedSearch(value);
                // }}
            />
        </PageWithCard>
    );
};
export default Quality;
