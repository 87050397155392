import { Link } from 'react-router-dom';
import PageWithCard from '../components/infrastructure/PageWithCard';
import QualitySwatch from './ViewVendor/QualitySwatch';

function Dashboard() {
    const qualityTypeArr = [
        {
            name: 'Nylon',
            value: 'nylon',
        },
        {
            name: 'Viscose',
            value: 'viscose',
        },
        {
            name: 'Polyester',
            value: 'polyester',
        },
    ];
    return (
        <PageWithCard heading="Dashboard">
            <div className="w-full  grid grid-cols-3 gap-4 items-center ">
                {qualityTypeArr.map((quality) => (
                    <Link to={`/dashboard/${quality.value}`}>
                        <QualitySwatch quality={quality} showTag={false} />
                    </Link>
                ))}
            </div>
        </PageWithCard>
    );
}

export default Dashboard;
