import { createSlice } from '@reduxjs/toolkit';
import { generateStateSingle } from '../../generators/generateState';
import {
    createData,
    deleteData,
    editData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';

const SLICE_NAME = 'submission';

export const fetchSubmissions = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchSubmission = fetchEditData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const createSubmission = createData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const deleteSubmission = deleteData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const editSubmission = editData.generateThunk(SLICE_NAME, SLICE_NAME);

const submissionReducer = createSlice({
    initialState: generateStateSingle(SLICE_NAME),
    name: SLICE_NAME,
    extraReducers: {
        ...fetchData.generateExtraReducers(fetchSubmissions, SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchSubmission, SLICE_NAME),
        ...createData.generateExtraReducers(createSubmission, SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteSubmission, SLICE_NAME),
        ...editData.generateExtraReducers(editSubmission, SLICE_NAME),
    },
});

export const getSubmissions = (state) => state.submissions;

export default submissionReducer.reducer;
