import React from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { useFormik } from 'formik';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { useDispatch, useSelector } from 'react-redux';
import {
    createOrganization,
    getOrganization,
} from '../../app/reducers/Organization/organizationSlice';
import * as Yup from 'yup';
import { ClipLoader } from 'react-spinners';

export const AddOrganization = () => {
    const { loading } = useSelector(getOrganization);
    const dispatch = useDispatch();
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            refreshToken: '',
            clientID: '',
            secretID: '',
            organizationID: '',
            domainName: '',
            accessToken: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required(),
            refreshToken: Yup.string().required(),
            clientID: Yup.string().required(),
            secretID: Yup.string().required(),
            organizationID: Yup.string().required(),
            domainName: Yup.string().required(),
            accessToken: Yup.string().required(),
        }),
        onSubmit: async (values, { resetForm }) => {
            await dispatch(
                createOrganization({
                    ...values,
                })
            );
            resetForm();
        },
    });

    return (
        <PageWithCard heading="ADD Organization">
            {loading ? (
                <ClipLoader />
            ) : (
                <form
                    onSubmit={formik.handleSubmit}
                    className="grid grid-rows-6 gap-3"
                >
                    <FormikInputGroup
                        label="Name"
                        formik={formik}
                        name="name"
                        required
                    />
                    <FormikInputGroup
                        label="Token"
                        formik={formik}
                        name="refreshToken"
                        required
                    />

                    <FormikInputGroup
                        label="Client Id"
                        formik={formik}
                        name="clientID"
                        required
                    />

                    <FormikInputGroup
                        label="Organization Id"
                        formik={formik}
                        name="organizationID"
                        required
                    />

                    <FormikInputGroup
                        label="Domain"
                        formik={formik}
                        name="domainName"
                        required
                    />
                    <FormikInputGroup
                        label="Secret Id"
                        formik={formik}
                        name="secretID"
                        required
                    />
                    <FormikInputGroup
                        label="Access Token"
                        formik={formik}
                        name="accessToken"
                        required
                    />

                    <div>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            )}
        </PageWithCard>
    );
};
