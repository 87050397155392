import { useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import {
    generateOptions,
    generateOptionsForPaymentTerms,
    generateQualityLabel,
    percentOf,
} from '../../utils/Utils';
import { fetchContacts } from '../../app/reducers/Contacts/contactsSlice';
import { fetchCustomers } from '../../app/reducers/Customer/customerSlice';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import {
    createPurchaseOrder,
    fetchPurchaseOrders,
    getPurchaseOrder,
    updatePurchaseOrder,
} from '../../app/reducers/PurchaseOrder/purchaseOrderSlice';
import {
    fetchQualities,
    getQuality,
} from '../../app/reducers/Quality/qualitySlice';
import {
    fetchPaymentTerms,
    getPaymentTerm,
} from '../../app/reducers/PaymentTerms/paymentTermSLice';
import { ClipLoader } from 'react-spinners';
import { LABEL_VALUES } from '../../utils/dropdownOptions';
import { checkForCustomerFollowUp } from '../../app/reducers/CustomerPreference/customerPreferenceSlice';
import moment from 'moment';
import { roundTo } from 'round-to';
import { customIDs } from '../../utils/customIds';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
const CreatePurchaseOrder = () => {
    const dispatch = useDispatch();
    const { quality, loading: qualityLoading } = useSelector(getQuality);
    const { loading } = useSelector(getPurchaseOrder);

    const formik = useFormik({
        initialValues: {
            poNumber: 'PO-',
            isVendor: 'no',
            customer: '',
            gstNumber: '',
            quality: '',
            buyerName: '',
            qtyTaka: 0,
            vendor: '',
            vendorName: '',
            payment_terms_label: '',
            paymentTerm: '',
            rate: 0,
            commission: 0,
            customer_commission: 0,
            vendor_commission: 0,
            date: new Date(),
            expectedDeliveryDate: '',
            paymentTermPer: '',
        },
        validationSchema: Yup.object({
            poNumber: Yup.string().required(),
            customer: Yup.string().required(),
            quality: Yup.string().required(),
            paymentTerm: Yup.string().required(),
            buyerName: Yup.string(),
            vendorName: Yup.string(),
            paymentTermPer: Yup.string(),
            isVendor: Yup.string().required(),
            vendor_commission: Yup.string(),
            payment_terms_label: Yup.string(),
            qtyTaka: Yup.number().min(1).required(),
            vendor: Yup.string().required(),
            rate: Yup.number().min(1).required(),
            commission: Yup.number().required(),
            date: Yup.mixed().required(),
            expectedDeliveryDate: Yup.mixed().required(),
        }),
        onSubmit: async (values) => {
            const updatedRate = (
                parseFloat(values.rate) +
                parseFloat(
                    percentOf(parseFloat(values.paymentTermPer), values.rate)
                )
            ).toFixed(2);
            console.log(typeof updatedRate);
            const newRate = roundTo(parseFloat(updatedRate), 2);
            console.log(newRate);
            const paymentTermsValue = parseInt(
                values.paymentTerm.split('/')[0]
            );

            let data = {
                vendor_id: values.vendor.split('/')[0],
                payment_terms: paymentTermsValue,
                payment_terms_label: values.payment_terms_label,
                source_of_supply: 'GJ',
                destination_of_supply: 'GJ',
                place_of_supply: values.place_of_supply,
                date: moment(values.date).format('YYYY-MM-DD'),
                delivery_date: moment(values.expectedDeliveryDate).format(
                    'YYYY-MM-DD'
                ),
                delivery_customer_id: values.customer,
                line_items: [
                    {
                        description: values.quality,
                        account_id: customIDs.purchase_item_account_id,
                        rate: newRate,

                        quantity: 105,
                        item_custom_fields: [
                            {
                                customfield_id:
                                    customIDs.purchase_item_qty_taka,
                                value: values.qtyTaka,
                            },
                            {
                                customfield_id:
                                    customIDs.purchase_item_commission,
                                value: values.commission,
                            },
                            {
                                customfield_id:
                                    customIDs.purchase_item_vendorName,
                                value: values.vendorName,
                            },
                        ],
                    },
                ],
                custom_fields: [
                    {
                        customfield_id: customIDs.purchase_custom_buyerName,
                        value: values.buyerName,
                    },
                    {
                        customfield_id: customIDs.purchase_custom_qty_taka,
                        value: values.qtyTaka,
                    },
                    {
                        customfield_id: customIDs.purchase_custom_quality,
                        value: values.quality,
                    },
                    {
                        customfield_id: customIDs.purchase_custom_rate,
                        value: newRate,
                    },
                    {
                        customfield_id:
                            customIDs.purchase_custom_vendor_commision,
                        value: values.isVendor,
                    },
                    {
                        customfield_id: customIDs.purchase_custom_payment_terms,
                        value: paymentTermsValue,
                    },
                    {
                        customfield_id:
                            customIDs.purchase_custom_payment_terms_2,
                        value: values.payment_terms_label,
                    },
                ],
            };
            const checkForFollowupResp = await dispatch(
                checkForCustomerFollowUp({
                    customerId: values.customer,
                    vendorId: values.vendor,
                    qualityName: values.quality,
                })
            );
            console.log('checkForFollowupResp=>', checkForFollowupResp); // api working fine next we just need to send payload to create purchase order if followupRequired : true
            const followupRequired =
                checkForFollowupResp.payload.data.followUpRequired;
            if (followupRequired) {
                data.custom_fields.push({
                    customfield_id: customIDs.purchase_order_followup_task,
                    value: 'YES',
                });

                const followUpDate = moment(values.expectedDeliveryDate)
                    .add(12, 'd')
                    .format('YYYY-MM-DD'); // 12 days from the delivery date for the follow up

                data.custom_fields.push({
                    customfield_id: customIDs.purchase_order_followup_due_date,
                    value: followUpDate,
                });
            }
            // console.log("payload data=>", data)
            if (values.paymentTermPer != 0) {
                toast.success(`Rate Modified ${newRate}`);
            }
            const purchaseOrder = await dispatch(createPurchaseOrder(data));
            if (purchaseOrder?.payload) {
                dispatch(
                    updatePurchaseOrder({
                        id: purchaseOrder?.payload?.purchaseorder
                            .purchaseorder_id,
                        status: 'open',
                    })
                );
            }
            formik.resetForm();
            formik.setFieldValue('vendor', '');
            formik.setFieldValue('customer', '');
            const action = await dispatch(fetchPurchaseOrders());
            if (action.payload?.purchaseorders) {
                formik.setFieldValue(
                    'poNumber',
                    `PO-${
                        parseInt(
                            action.payload?.purchaseorders[0].purchaseorder_number.split(
                                '-'
                            )[1]
                        ) + 1
                    }`
                );
            }
        },
    });
    const { paymentTerms } = useSelector(getPaymentTerm);
    useEffect(async () => {
        dispatch(fetchContacts({ contact_type: 'vendor' }));
        dispatch(fetchCustomers({ contact_type: 'customer' }));
        dispatch(fetchPaymentTerms());
        dispatch(fetchQualities({ sort: { priority: -1 }, limit: 300 }));
        const action = await dispatch(fetchPurchaseOrders());
        if (action.payload?.purchaseorders) {
            formik.setFieldValue(
                'poNumber',
                `PO-${
                    parseInt(
                        action.payload?.purchaseorders[0].purchaseorder_number.split(
                            '-'
                        )[1]
                    ) + 1
                }`
            );
        }
    }, []);

    const paymentTermData = useMemo(
        () => (paymentTerms?.docs ? paymentTerms.docs : []),
        [paymentTerms]
    );
    return (
        <div className="p-4">
            {loading ? (
                <ClipLoader />
            ) : (
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-4 w-full"
                >
                    <h1 className="text-lg font-bold">Create Purchase Order</h1>
                    <div className=" grid md:grid-cols-2 gap-2">
                        <FormikAsyncSelect
                            name="vendor"
                            label="Vendor"
                            formik={formik}
                            getOptions={async (value) => {
                                const action = await dispatch(
                                    fetchContacts({
                                        search: value,
                                        contact_type: 'vendor',
                                    })
                                );
                                const serverResp = action.payload.contacts.map(
                                    (ele) => ({
                                        label: `${ele.contact_name}`,
                                        value: `${ele.contact_id}/${ele.contact_name}/${ele?.cf_commission}`,
                                    })
                                );
                                return serverResp;
                            }}
                            onChange={(option) => {
                                const id = option.value.split('/')[0];
                                const vendor_name = option.value.split('/')[1];
                                const vendor_commission =
                                    option.value.split('/')[2];
                                formik.setFieldValue('vendorName', vendor_name);
                                formik.setFieldValue(
                                    'vendor_commission',
                                    vendor_commission
                                );
                                formik.setFieldValue('vendor', id);
                            }}
                        />
                        <FormikAsyncSelect
                            name="customer"
                            label="Customer"
                            formik={formik}
                            getOptions={async (value) => {
                                const action = await dispatch(
                                    fetchCustomers({
                                        search: value,
                                        contact_type: 'customer',
                                    })
                                );
                                const serverResp = action.payload.contacts.map(
                                    (ele) => ({
                                        label: ele.contact_name,
                                        value: `${ele.contact_id}/${ele.gst_no}/${ele?.cf_commission}/${ele?.payment_terms_label}`,
                                    })
                                );
                                return serverResp;
                            }}
                            onChange={(option) => {
                                console.log(option.value);
                                const id = option.value.split('/')[0];
                                const gst = option.value.split('/')[1];
                                const customer_commision =
                                    option.value.split('/')[2];
                                const paymentTermLabel =
                                    option.value.split('/')[3];
                                formik.setFieldValue('buyerName', option.label);
                                formik.setFieldValue('gstNumber', gst);
                                formik.setFieldValue(
                                    'customer_commission',
                                    customer_commision
                                );
                                formik.setFieldValue('customer', id);
                                console.log(paymentTermLabel);
                                let checkPaymentTerm = paymentTermData.find(
                                    (d) => d.name == paymentTermLabel
                                );
                                if (checkPaymentTerm) {
                                    formik.setFieldValue(
                                        'paymentTerm',
                                        `${checkPaymentTerm?.value}/${checkPaymentTerm?.name}`
                                    );
                                    formik.setFieldValue(
                                        'payment_terms_label',
                                        checkPaymentTerm?.name
                                    );
                                    formik.setFieldValue(
                                        'paymentTermPer',
                                        checkPaymentTerm?.percentage
                                    );
                                }
                            }}
                        />
                    </div>

                    <FormikInputGroup
                        readOnly
                        name="gstNumber"
                        label="GST Number"
                        formik={formik}
                    />
                    <FormikInputDateGroup
                        name="date"
                        label="Date"
                        formik={formik}
                        required
                    />

                    <FormikInputGroup
                        name="poNumber"
                        label="Purchase Order#"
                        formik={formik}
                        required
                        readOnly
                    />
                    <div className="flex flex-row gap-4">
                        <FormikInputDateGroup
                            name="expectedDeliveryDate"
                            label="Expected Delivery Date"
                            formik={formik}
                            required
                        />
                        <FormikSelectGroup
                            formik={formik}
                            label="Payment Terms"
                            name={`paymentTerm`}
                            onChange={(selectedOption) => {
                                console.log(selectedOption);
                                let findPaymentData = paymentTermData.find(
                                    (d) => d.name == selectedOption.label
                                );

                                formik.setFieldValue(
                                    'payment_terms_label',
                                    selectedOption.value.split('/')[1]
                                );
                                formik.setFieldValue(
                                    'paymentTerm',
                                    selectedOption.value
                                );
                                formik.setFieldValue(
                                    'paymentTermPer',
                                    findPaymentData.percentage
                                );
                            }}
                            options={generateOptionsForPaymentTerms({
                                array: paymentTermData,
                                labelField: 'name',
                                valueField: 'value',
                            })}
                            required
                        />
                    </div>

                    <div className="flex flex-row gap-4">
                        <FormikInputGroup
                            name="qtyTaka"
                            fullWidth={true}
                            type="number"
                            label="QTY Taka"
                            formik={formik}
                            required
                        />
                        <FormikInputGroup
                            name="rate"
                            fullWidth={true}
                            type="number"
                            label="RATE"
                            formik={formik}
                            required
                        />
                    </div>
                    <div className="flex flex-row gap-4">
                        <FormikSelectGroup
                            formik={formik}
                            label="Select Quality"
                            name={`quality`}
                            options={generateQualityLabel(quality.docs)}
                            required
                        />
                        <FormikInputGroup
                            name="commission"
                            fullWidth={true}
                            type="number"
                            value={
                                formik.values.isVendor == 'yes'
                                    ? parseFloat(
                                          formik.values.vendor_commission
                                      )
                                    : parseFloat(
                                          formik.values.customer_commission
                                      )
                            }
                            label="Commission"
                            formik={formik}
                            required
                            readOnly
                        />
                    </div>
                    <FormikSelectGroup
                        formik={formik}
                        label="Add Vendor Commission"
                        name={`isVendor`}
                        options={generateOptions({
                            array: LABEL_VALUES,
                            labelField: 'label',
                            valueField: 'value',
                        })}
                        required
                    />
                    <div>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            )}
        </div>
    );
};

export default CreatePurchaseOrder;
