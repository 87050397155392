import React from 'react';

const SecondaryBadge = ({ children, className }) => {
    return (
        <div
            className={`text-xs inline-flex font-medium bg-slate-100 text-slate-500 rounded-full text-center px-2.5 py-1 ${className}`}
        >
            {children}
        </div>
    );
};

export default SecondaryBadge;
