import { createSlice } from '@reduxjs/toolkit';
import { generateSetEditId } from '../../generators/generateReducers';
import { generateStateSingle } from '../../generators/generateState';
import {
    createData,
    deleteData,
    editData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';
const SLICE_NAME = 'quality';

export const fetchQualities = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editQuality = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchQuality = fetchEditData.generateThunk(SLICE_NAME, SLICE_NAME);
export const deleteQuality = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createQuality = createData.generateThunk(SLICE_NAME, SLICE_NAME);

const qualitySlice = createSlice({
    name: SLICE_NAME,
    initialState: generateStateSingle(SLICE_NAME),
    reducers: {
        setEditId: generateSetEditId(),
    },
    extraReducers: {
        ...fetchData.generateExtraReducers(fetchQualities, SLICE_NAME),
        ...createData.generateExtraReducers(createQuality, SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteQuality, SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchQuality, SLICE_NAME),
        ...editData.generateExtraReducers(editQuality, SLICE_NAME),
    },
});

export const getQuality = (state) => state.quality;
export const { setEditId } = qualitySlice.actions;
export default qualitySlice.reducer;
