import { createSlice } from '@reduxjs/toolkit';
import { generateSetEditId } from '../../generators/generateReducers';
import { generateStateSingle } from '../../generators/generateState';
import {
    createData,
    deleteData,
    editData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';

const SLICE_NAME = 'notice';

export const fetchNotices = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editNotice = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchNotice = fetchEditData.generateThunk(SLICE_NAME, SLICE_NAME);
export const deleteNotice = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createNotice = createData.generateThunk(SLICE_NAME, SLICE_NAME);

const noticeSlice = createSlice({
    name: SLICE_NAME,
    initialState: generateStateSingle(SLICE_NAME),
    //   reducers: {
    //     setEditId: generateSetEditId(),
    //   },
    extraReducers: {
        ...fetchData.generateExtraReducers(fetchNotices, SLICE_NAME),
        ...createData.generateExtraReducers(createNotice, SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteNotice, SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchNotice, SLICE_NAME),
        ...editData.generateExtraReducers(editNotice, SLICE_NAME),
    },
});

export const getNotice = (state) => state.notice;
// export const { setEditId } = notificationSlice.actions;
export default noticeSlice.reducer;
