import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { ClipLoader } from 'react-spinners';
import {
    fetchVendors,
    getVendor,
} from '../../app/reducers/Vendors/vendorSlice';
import { CheckCircle, Edit3, Clock } from 'react-feather';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { useState } from 'react';
import PaginationClassic from '../../components/pagination/PaginationClassic';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { fetchQualities } from '../../app/reducers/Quality/qualitySlice';
import { customIDs } from '../../utils/customIds';
import {
    fetchCustomers,
    fetchVendorsFromZoho,
    getCustomer,
} from '../../app/reducers/Customer/customerSlice';
import Tabs from '../../components/infrastructure/Tabs/Tabs';
import Tab from '../../components/infrastructure/Tabs/Tab';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import shuffle from '../../utils/shuffleWithSeed';
import { useFormik } from 'formik';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import ShowDropdown from '../../components/infrastructure/ShowDropdown';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import EditVendorModal from './EditVendorModal';
import { useCallback } from 'react';
import EditCustomer from '../EditCustomer';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { ClockLoader } from 'react-spinners';
import { openReminderPopUp } from '../../app/reducers/ReminderPopUp/reminderPopUpSlice';

const groupArr = (array, groupLength) => {
    const arrayOfArr = []; // grouped Array
    for (let i = 0; i < array.length; i += groupLength) {
        arrayOfArr.push(array.slice(i, i + groupLength));
    }
    return arrayOfArr;
};

const computeCurrentArr = (array) => {
    let currentArr = [];
    for (let arr of array) {
        for (let ele of arr) {
            if (ele.cf_calling == '0') {
                currentArr.push(ele);
            }
        }

        if (currentArr.length > 0) break;
    }
    return currentArr;
};

const ViewVendors = () => {
    const dispatch = useDispatch();
    const { customer_calling } = customIDs;
    const [loadingReminder, setLoadingReminder] = useState(false);
    const [showHighPriority, setShowHighPriority] = useState(false);
    const [page, setPage] = useState(1);
    const [isVendorModalOpen, setVendorModalOpen] = useState(false);
    const [isCustomerModalOpen, setCustomerModalOpen] = useState(false);
    const [editVendorData, setEditVendorData] = useState({});
    const [editCustomerData, setEditCustomerData] = useState({});
    const [section, setSection] = useState([]);
    const [index, setIndex] = useState(0);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            // quality_name: "",
            // quality_tag: "",
            // type: "",
            // pana: "",
            // fani: "",
            // weight: "",
            // pick: "",
            // denier_beam: "",
            // denier_vana: "",
            // tpm_beam: "",
            // tpm_vana: "",
            // yarn_beam: "",
            // yarn_vana: "",
        },
        onSubmit: async (values) => {
            console.log(values);
            const keys = Object.keys(values);
            const filterObj = keys.reduce((acc, ele) => {
                acc[`quality_details.${ele}`] = values[ele];

                return acc;
            }, {});
            await dispatch(fetchVendors(filterObj));
            // await dispatch(fetchVendors({ ["quality_details.fani"]: values.fani }));
        },
    });

    useEffect(() => {
        const data = { page, sort: { company_name: 1 } };
        if (showHighPriority) {
            data.priority = 'HIGH';
        }
        dispatch(fetchVendors(data));
    }, [showHighPriority, page]);

    useEffect(() => {
        dispatch(fetchQualities({ limit: 300, sort: { priority: -1 } }));
        dispatch(
            fetchCustomers({
                contact_type: 'customer',
                // [`custom_field_${customer_calling}_contains`]: 0,
            })
        );
        dispatch(
            fetchVendorsFromZoho({
                contact_type: 'vendor',
            })
        );
    }, []);

    const { vendors, loading: vendorLoading } = useSelector(getVendor);
    const {
        customers,
        vendorsFromZoho,
        loading: customerLoading,
    } = useSelector(getCustomer);
    const cols = [
        {
            Header: 'Company Name',
            Cell: ({ row }) => {
                return (
                    <div className="flex gap-2 cursor-pointer">
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                setVendorModalOpen(true);
                                setEditVendorData(row.original);
                                setSection(['company_name']);
                            }}
                        >
                            {row?.original?.company_name}
                        </div>
                        {row?.original?.priority?.toUpperCase() === 'HIGH' && (
                            <CheckCircle color="green" />
                        )}
                    </div>
                );
            },
        },
        {
            Header: 'First Name',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col gap-1 cursor-pointer ">
                        {row?.original?.first_name && (
                            <span
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setVendorModalOpen(true);
                                    setEditVendorData(row.original);
                                    setSection(['vendor_name']);
                                }}
                            >
                                {row?.original?.first_name}
                            </span>
                        )}
                    </div>
                );
            },
            accessor: 'first_name',
        },
        {
            Header: 'Mobile',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col gap-1 cursor-pointer">
                        {row?.original?.mobile && (
                            <span
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setVendorModalOpen(true);
                                    // console.log(row.original);
                                    setEditVendorData(row.original);
                                    setSection(['phone']);
                                }}
                            >
                                {row?.original?.mobile}
                            </span>
                        )}
                    </div>
                );
            },
            accessor: 'mobile',
        },
        {
            Header: 'Work',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col gap-1 cursor-pointer">
                        {row?.original?.phone && (
                            <span
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setVendorModalOpen(true);
                                    setEditVendorData(row.original);
                                    setSection(['phone']);
                                }}
                            >
                                {row?.original?.phone}
                            </span>
                        )}
                    </div>
                );
            },
            accessor: 'phone',
        },
        {
            Header: 'Work name',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col gap-1 cursor-pointer">
                        {row?.original?.phone && (
                            <span
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setVendorModalOpen(true);
                                    setEditVendorData(row.original);
                                    setSection(['phone']);
                                }}
                            >
                                {row?.original?.work_name}
                            </span>
                        )}
                    </div>
                );
            },
            accessor: 'work_name',
        },
        {
            Header: 'Update date',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col gap-1 cursor-pointer">
                        {row?.original?.updatedAt && (
                            <span>
                                {moment(row?.original?.updatedAt).format(
                                    'YYYY-MM-DD'
                                )}
                            </span>
                        )}
                    </div>
                );
            },
        },
        {
            Header: 'Action',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col gap-1 cursor-pointer">
                        <Edit3
                            onClick={async (e) => {
                                e.stopPropagation();
                                setVendorModalOpen(true);
                                // console.log(row.original);
                                setSection(['all']);

                                setEditVendorData(row.original);
                            }}
                        />
                        {loadingReminder == row.original._id ? (
                            <ClockLoader size={25} speedMultiplier={2} />
                        ) : (
                            <button
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    console.log('row', row.original);
                                    setLoadingReminder(row.original._id);
                                    let popUpData = {};
                                    popUpData['vendorMobileNo'] =
                                        row.original?.mobile;
                                    popUpData['vendor_name'] =
                                        row.original?.company_name;

                                    dispatch(
                                        openReminderPopUp({
                                            isOpen: true,
                                            data: {
                                                ...popUpData,
                                                type: 'vendor',
                                                reminderType: 'reminder',
                                                path: 'contacts',
                                                path_id:
                                                    row.original?.contact_id,
                                            },
                                        })
                                    );
                                    setLoadingReminder('');
                                }}
                            >
                                <Clock
                                    size={25}
                                    color={
                                        row.original?.reminder == 'YES'
                                            ? '#ffff1a'
                                            : 'black'
                                    }
                                />
                            </button>
                        )}
                    </div>
                );
            },
        },
    ];
    const tabColsVendor = [
        {
            Header: 'Company Name',
            Cell: ({ row }) => {
                return (
                    <div className="flex gap-2 cursor-pointer">
                        <div
                            onClick={(e) => {
                                // e.stopPropagation();
                                // setVendorModalOpen(true);
                                // setEditVendorData(row.original);
                                // setSection(["company_name"]);
                            }}
                        >
                            {row?.original?.company_name}
                        </div>
                        {row?.original?.cf_priority?.toUpperCase() ===
                            'HIGH' && <CheckCircle color="green" />}
                    </div>
                );
            },
        },
        {
            Header: 'First Name',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col gap-1 cursor-pointer ">
                        {row?.original?.first_name && (
                            <span
                                onClick={(e) => {
                                    // e.stopPropagation();
                                    // setVendorModalOpen(true);
                                    // setEditVendorData(row.original);
                                    // setSection(["vendor_name"]);
                                }}
                            >
                                {row?.original?.first_name}
                            </span>
                        )}
                    </div>
                );
            },
            accessor: 'first_name',
        },
        {
            Header: 'Mobile',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col gap-1 cursor-pointer">
                        {row?.original?.mobile && (
                            <span
                                onClick={(e) => {
                                    // e.stopPropagation();
                                    // setVendorModalOpen(true);
                                    // // console.log(row.original);
                                    // setEditVendorData(row.original);
                                    // setSection(["phone"]);
                                }}
                            >
                                {row?.original?.mobile}
                            </span>
                        )}
                    </div>
                );
            },
        },
        {
            Header: 'Work',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col gap-1 cursor-pointer">
                        {row?.original?.phone && (
                            <span
                                onClick={(e) => {
                                    // e.stopPropagation();
                                    // setVendorModalOpen(true);
                                    // setEditVendorData(row.original);
                                    // setSection(["phone"]);
                                }}
                            >
                                {row?.original?.phone}
                            </span>
                        )}
                    </div>
                );
            },
        },
        {
            Header: 'Action',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col gap-1 cursor-pointer">
                        <PrimaryButton
                            onClick={async (e) => {
                                e.stopPropagation();
                                setVendorModalOpen(true);
                                // console.log(row.original);
                                setSection(['all']);
                                let { cf_calling, ...rest } = row.original;
                                cf_calling = 1;
                                // console.log("cf_calling", cf_calling);
                                // console.log("rowData-->", row.original);
                                // console.log("updatedObj-->", { cf_calling, ...rest });

                                setEditVendorData({ cf_calling, ...rest });
                            }}
                        >
                            Update
                        </PrimaryButton>
                    </div>
                );
            },
        },
    ];
    const tabColsCustomer = [
        {
            Header: 'Company Name',
            Cell: ({ row }) => {
                return (
                    <div className="flex gap-2 cursor-pointer">
                        <div
                            onClick={(e) => {
                                // e.stopPropagation();
                                // setVendorModalOpen(true);
                                // setEditData(row.original);
                                // setSection(["company_name"]);
                            }}
                        >
                            {row?.original?.company_name}
                        </div>
                        {row?.original?.cf_priority?.toUpperCase() ===
                            'HIGH' && <CheckCircle color="green" />}
                    </div>
                );
            },
        },
        {
            Header: 'First Name',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col gap-1 cursor-pointer ">
                        {row?.original?.first_name && (
                            <span
                                onClick={(e) => {
                                    // e.stopPropagation();
                                    // setVendorModalOpen(true);
                                    // setEditData(row.original);
                                    // setSection(["vendor_name"]);
                                }}
                            >
                                {row?.original?.first_name}
                            </span>
                        )}
                    </div>
                );
            },
            accessor: 'first_name',
        },
        {
            Header: 'Mobile',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col gap-1 cursor-pointer">
                        {row?.original?.mobile && (
                            <span
                                onClick={(e) => {
                                    // e.stopPropagation();
                                    // setVendorModalOpen(true);
                                    // // console.log(row.original);
                                    // setEditData(row.original);
                                    // setSection(["phone"]);
                                }}
                            >
                                {row?.original?.mobile}
                            </span>
                        )}
                    </div>
                );
            },
        },
        {
            Header: 'Work',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col gap-1 cursor-pointer">
                        {row?.original?.phone && (
                            <span
                                onClick={(e) => {
                                    // e.stopPropagation();
                                    // setVendorModalOpen(true);
                                    // setEditData(row.original);
                                    // setSection(["phone"]);
                                }}
                            >
                                {row?.original?.phone}
                            </span>
                        )}
                    </div>
                );
            },
        },
        {
            Header: 'Action',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col gap-1 cursor-pointer">
                        <PrimaryButton
                            onClick={async (e) => {
                                e.stopPropagation();
                                setCustomerModalOpen(true);
                                // console.log(row.original);
                                setSection(['all']);
                                let { cf_calling, ...rest } = row.original;
                                cf_calling = 1;
                                // console.log("cf_calling", cf_calling);
                                // console.log("rowData-->", row.original);
                                // console.log("updatedObj-->", { cf_calling, ...rest });

                                setEditCustomerData({ cf_calling, ...rest });
                            }}
                        >
                            Update
                        </PrimaryButton>
                    </div>
                );
            },
        },
    ];

    const SEED = 30;
    const GROUP_LENGTH = 5;
    const customerTabData = useMemo(() => {
        const customerArr = customers?.contacts ? [...customers.contacts] : [];
        const shuffledArr = shuffle(customerArr, SEED);
        const groupedArr = groupArr(shuffledArr, GROUP_LENGTH);
        return computeCurrentArr(groupedArr);
    }, [customers]);
    const vendorTabData = useMemo(() => {
        const vendorArr = vendorsFromZoho?.contacts
            ? [...vendorsFromZoho.contacts]
            : [];
        const shuffledArr = shuffle(vendorArr, SEED);
        const groupedArr = groupArr(shuffledArr, GROUP_LENGTH);
        const result = computeCurrentArr(groupedArr);
        return result;
    }, [vendorsFromZoho]);

    const vendorsData = useMemo(
        () => (vendors?.docs ? vendors.docs : []),
        [vendors]
    );
    const debouncedSearch = useCallback(
        _.debounce(async (search) => {
            dispatch(fetchVendors({ search: search }));
        }, 300),
        []
    );
    const navigate = useNavigate();
    return (
        <PageWithCard heading="View Vendor">
            <EditVendorModal
                title={'Edit Vendor'}
                setIsOpen={setVendorModalOpen}
                isOpen={isVendorModalOpen}
                data={editVendorData}
                section={section}
            />
            <EditCustomer
                title={'Edit Customer'}
                setIsOpen={setCustomerModalOpen}
                isOpen={isCustomerModalOpen}
                data={editCustomerData}
                section={section}
            />

            <div className="mb-4" style={{ overflow: 'hidden' }}>
                <Tabs
                    headers={['Vendor', 'Customer']}
                    onTabChange={(index) => {
                        setIndex(index);
                    }}
                >
                    <Tab>
                        {vendorLoading ? (
                            <ClipLoader />
                        ) : (
                            <TableWithHeadingAndSearch
                                heading={'Vendor'}
                                data={vendorTabData}
                                loading={vendorLoading}
                                columns={tabColsVendor}
                            />
                        )}
                    </Tab>
                    <Tab>
                        {customerLoading ? (
                            <ClipLoader />
                        ) : (
                            <TableWithHeadingAndSearch
                                heading={'Customer'}
                                data={customerTabData}
                                loading={customerLoading}
                                columns={tabColsCustomer}
                            />
                        )}
                    </Tab>
                </Tabs>
            </div>

            <ShowDropdown heading={'Vendor filter'} allowedRoles={['admin']}>
                <form onSubmit={formik.handleSubmit}>
                    <div className="grid grid-cols-3 gap-2">
                        <FormikInputGroup
                            formik={formik}
                            name={`pana`}
                            label="Pana"
                        />
                        <FormikInputGroup
                            formik={formik}
                            name={`fani`}
                            label="Fani"
                        />
                        <FormikInputGroup
                            formik={formik}
                            name={`weight`}
                            label="Weight"
                        />
                        <FormikInputGroup
                            formik={formik}
                            name={`pick`}
                            label="Pick"
                        />
                        <FormikInputGroup
                            formik={formik}
                            name={`denier_beam`}
                            label="Denier Beam"
                        />
                        <FormikInputGroup
                            formik={formik}
                            name={`denier_vana`}
                            label="Denier Vana"
                        />
                        <FormikInputGroup
                            formik={formik}
                            name={`tpm_beam`}
                            label="Tpm Beam"
                        />
                        <FormikInputGroup
                            formik={formik}
                            name={`tpm_vana`}
                            label="Tpm Vana"
                        />
                        <FormikInputGroup
                            formik={formik}
                            name={`yarn_beam`}
                            label="Yarn Beam"
                        />
                        <FormikInputGroup
                            formik={formik}
                            name={`yarn_vana`}
                            label="Yarn Vana"
                        />
                    </div>

                    <div className="my-4">
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            </ShowDropdown>

            {/* {vendorLoading ? (
        <ClipLoader />
      ) : ( */}
            <>
                <TableWithHeadingAndGlobalSearch
                    heading={
                        <div className="flex gap-3">
                            <span>Vendors</span>
                            {!showHighPriority ? (
                                <PrimaryButton
                                    onClick={() => {
                                        setShowHighPriority(true);
                                        setPage(1);
                                    }}
                                >
                                    High Priority
                                </PrimaryButton>
                            ) : (
                                <PrimaryButton
                                    onClick={() => {
                                        setShowHighPriority(false);
                                    }}
                                >
                                    Show All
                                </PrimaryButton>
                            )}
                            <PrimaryButton
                                onClick={() => {
                                    navigate('/view-customer');
                                }}
                            >
                                Customer
                            </PrimaryButton>
                        </div>
                    }
                    data={vendorsData}
                    loading={vendorLoading}
                    columns={cols}
                    searchFunction={debouncedSearch}
                />
                <PaginationClassic
                    paginationDetails={vendors}
                    setPage={setPage}
                />
            </>
            {/* )} */}
        </PageWithCard>
    );
};

export default ViewVendors;
