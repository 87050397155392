import React, { useEffect, useMemo } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteNotice,
    fetchNotices,
    getNotice,
} from '../../app/reducers/Notice/noticeSlice';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import { ClipLoader } from 'react-spinners';
import moment from 'moment';
import { Trash } from 'react-feather';

const ViewNotice = () => {
    const { loading, notice } = useSelector(getNotice);
    const dispatch = useDispatch();

    const cols = [
        {
            Header: 'Date',
            Cell: ({ row }) => {
                return (
                    <span>
                        {row?.original?.createdAt &&
                            moment(row?.original?.createdAt).format(
                                'DD/MM/YYYY'
                            )}
                    </span>
                );
            },
        },
        { Header: 'Customer Name', accessor: 'customerName' },
        {
            Header: 'Due Amount',
            accessor: 'dueAmount',
        },
        {
            Header: 'Notice Url',
            Cell: ({ row }) => {
                return (
                    <a
                        className="underline text-indigo-600"
                        target="poTargetTab"
                        href={row?.original?.noticeWebsiteUrl}
                    >
                        Notice Url
                    </a>
                );
            },
        },
        {
            Header: 'Action',
            Cell: ({ row }) => {
                return (
                    <Trash
                        color="red"
                        onClick={async (e) => {
                            e.preventDefault();
                            await dispatch(
                                deleteNotice({ id: row?.original?._id })
                            );
                            dispatch(fetchNotices());
                        }}
                    />
                );
            },
        },
    ];

    const data = useMemo(() => (notice?.docs ? notice.docs : []), [notice]);

    useEffect(() => {
        dispatch(fetchNotices());
    }, []);
    return (
        <PageWithCard heading="View Notice">
            {loading ? (
                <ClipLoader />
            ) : (
                <TableWithHeadingAndSearch data={data} columns={cols} />
            )}
        </PageWithCard>
    );
};

export default ViewNotice;
