const QualitySwatch = ({ quality, showTag = true }) => {
    return (
        <div
            className="border hover:bg-white cursor-pointer opacity-70 hover:opacity-100 h-[70px] flex flex-col justify-center items-center"
            style={{
                backgroundColor: quality?.color,
            }}
        >
            <span className="font-semibold text-lg">{quality.name}</span>
            {showTag && <span>{quality.tag}</span>}
            {/* <span className="text-[0.5rem]">{quality.priority}</span> */}
        </div>
    );
};

export default QualitySwatch;
