import React, { useState, useEffect, useMemo } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { useFormik } from 'formik';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { ClipLoader } from 'react-spinners';
import { array, mixed, number, object, string } from 'yup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { toast } from 'react-toastify';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import moment from 'moment';
import { authAxiosInstance } from '../../utils/axiosConfig';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { generateOptions } from '../../utils/Utils';
import { NEW_CREDIT, OLD_CREDIT } from '../../utils/dropdownOptions';
const Report = () => {
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            newCredit: '',
            oldCredit: '',
            from: '',
            to: '',
            brockrage: '',
        },
        validationSchema: object({
            newCredit: string().required(),
            oldCredit: string().required(),
            from: mixed().required(),
            to: mixed().required(),
            brockrage: number().required(),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                console.log('check');
                setLoading(true);
                values.from = moment(values.from).format('YYYY-MM-DD');
                values.to = moment(values.to).format('YYYY-MM-DD');
                console.log(values, 'check');
                let poData = await authAxiosInstance.post(
                    'purchase-orders/report',
                    values
                );
                setLoading(false);
                resetForm();
            } catch (error) {
                console.log(error);
                toast.error('Something Went Wrong while Saving Data');
                resetForm();
            } finally {
                setLoading(false);
            }
        },
    });

    return (
        <PageWithCard heading="Generate Report">
            <div>
                {loading ? (
                    <ClipLoader />
                ) : (
                    <form
                        className="flex flex-col gap-4"
                        onSubmit={formik.handleSubmit}
                    >
                        <FormikInputGroup
                            name={`brockrage`}
                            label={'Brockrage Mode'}
                            formik={formik}
                            type="number"
                        />
                        <FormikSelectGroup
                            formik={formik}
                            label="Old Credit Score"
                            name={`oldCredit`}
                            options={generateOptions({
                                array: OLD_CREDIT,
                                labelField: 'label',
                                valueField: 'value',
                            })}
                            required
                        />
                        <FormikSelectGroup
                            formik={formik}
                            label="New Credit Score"
                            name={`newCredit`}
                            options={generateOptions({
                                array: NEW_CREDIT,
                                labelField: 'label',
                                valueField: 'value',
                            })}
                            required
                        />

                        <FormikInputDateGroup
                            name="from"
                            label="From"
                            formik={formik}
                        />
                        <FormikInputDateGroup
                            label="To"
                            name="to"
                            formik={formik}
                        />

                        <div className="flex gap-3">
                            <PrimaryButton type="submit">Submit</PrimaryButton>
                        </div>
                    </form>
                )}
            </div>
        </PageWithCard>
    );
};

export default Report;
