import { createSlice } from '@reduxjs/toolkit';
import { generateSetEditId } from '../../generators/generateReducers';
import { generateStateSingle } from '../../generators/generateState';
import {
    createData,
    deleteData,
    editData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';

const SLICE_NAME = 'attributes';

export const fetchAttributes = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editAttribute = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchAttribute = fetchEditData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const deleteAttribute = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createAttribute = createData.generateThunk(SLICE_NAME, SLICE_NAME);

const attributeSlice = createSlice({
    name: SLICE_NAME,
    initialState: generateStateSingle(SLICE_NAME),
    //   reducers: {
    //     setEditId: generateSetEditId(),
    //   },
    extraReducers: {
        ...fetchData.generateExtraReducers(fetchAttributes, SLICE_NAME),
        ...createData.generateExtraReducers(createAttribute, SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteAttribute, SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchAttribute, SLICE_NAME),
        ...editData.generateExtraReducers(editAttribute, SLICE_NAME),
    },
});

export const getAttributes = (state) => state.attributes;
// export const { setEditId } = notificationSlice.actions;
export default attributeSlice.reducer;
