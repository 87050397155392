import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { toast } from 'react-toastify';
import { authAxiosInstance } from '../../../utils/axiosConfig';
import QueryString from 'qs';
const initialState = {
    customers: {},
    vendorsFromZoho: {},
    loading: false,
    updateLoading: false,
    contact: {},
};
export const fetchCustomers = createAsyncThunk(
    'contacts/fetchCustomers',
    async (data, { rejectWithValue }) => {
        try {
            const string = QueryString.stringify(data);
            const contacts = await authAxiosInstance.get(
                `purchase-orders/contacts?${string}`
            );
            return contacts.data.data;
        } catch (error) {
            console.log(error);
            if (error?.response?.message) toast.error(error.response.message);
            else toast.error('failed to fetch contacts');
        }
    }
);

export const fetchContact = createAsyncThunk(
    'contacts/fetchContact',
    async (data, { rejectWithValue }) => {
        try {
            const string = QueryString.stringify(data);
            const contacts = await authAxiosInstance.get(
                `purchase-orders/contacts/${data.id}?${string}`
            );
            return contacts.data.data;
        } catch (error) {
            console.log(error);
            if (error?.response?.message) toast.error(error.response.message);
            else toast.error('failed to fetch contact');
        }
    }
);

export const fetchVendorsFromZoho = createAsyncThunk(
    'contacts/fetchVendors',
    async (data, { rejectWithValue }) => {
        try {
            const string = QueryString.stringify(data);
            const contacts = await authAxiosInstance.get(
                `purchase-orders/contacts?${string}`
            );
            return contacts.data.data;
        } catch (error) {
            console.log(error);
            if (error?.response?.message) toast.error(error.response.message);
            else toast.error('failed to fetch contacts');
        }
    }
);

export const updateCustomer = createAsyncThunk(
    'contacts/updateCustomer',
    async (data, { rejectWithValue }) => {
        try {
            const contacts = await authAxiosInstance.put(
                `purchase-orders/contacts`,
                data
            );
            console.log('updateCustomer', contacts);
            return contacts.data.data;
        } catch (error) {
            console.log(error);
            if (error?.response?.message) toast.error(error.response.message);
            else toast.error('failed to fetch contacts');
        }
    }
);

const customerSlice = createSlice({
    name: 'customers',
    initialState: initialState,
    reducers: {
        resetContact: (state, action) => {
            state.contact = {};
        },
    },
    extraReducers: {
        [fetchCustomers.pending]: (state) => {
            state.loading = true;
        },
        [fetchCustomers.fulfilled]: (state, action) => {
            state.loading = false;
            state.customers = action.payload;
        },
        [fetchCustomers.rejected]: (state, action) => {
            state.loading = false;
        },
        [fetchVendorsFromZoho.pending]: (state) => {
            state.loading = true;
        },
        [fetchVendorsFromZoho.fulfilled]: (state, action) => {
            state.loading = false;
            state.vendorsFromZoho = action.payload;
        },
        [fetchVendorsFromZoho.rejected]: (state, action) => {
            state.loading = false;
        },
        [updateCustomer.pending]: (state) => {
            state.updateLoading = true;
        },
        [updateCustomer.fulfilled]: (state, action) => {
            state.updateLoading = false;
        },
        [updateCustomer.rejected]: (state, action) => {
            state.updateLoading = false;
        },
        [fetchContact.pending]: (state) => {
            state.updateLoading = true;
        },
        [fetchContact.fulfilled]: (state, action) => {
            state.updateLoading = false;
            state.contact = action.payload;
        },
        [fetchContact.rejected]: (state, action) => {
            state.updateLoading = false;
        },
    },
});

export const getCustomer = (state) => state.customer;
export const { resetContact } = customerSlice.actions;
export default customerSlice.reducer;
