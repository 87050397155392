import { useSelector } from 'react-redux';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';

const ViewAttributeModal = ({
    isViewAttributeModalOpen,
    setIsViewAttributeModalOpen,
    selectedAttribute,
}) => {
    console.log(selectedAttribute, 'selectedAttribute');
    return (
        <ModalBasic
            modalOpen={isViewAttributeModalOpen}
            setModalOpen={setIsViewAttributeModalOpen}
        >
            <div className="grid grid-cols-3 pt-0 pb-3 pl-4 pr-4 rounded-2xl">
                {Object.keys(selectedAttribute ? selectedAttribute : {}).map(
                    (ele) => (
                        <div className="p-4 border border-grey m-1 rounded-sm text-sm">
                            {`${ele} : ${selectedAttribute?.[ele]}`}
                        </div>
                    )
                )}
            </div>
        </ModalBasic>
    );
};

export default ViewAttributeModal;
