import React from 'react';
import PageWithCard from '../components/infrastructure/PageWithCard';

const PageNotFound = () => {
    return (
        <PageWithCard>
            <h1>Page not found</h1>
        </PageWithCard>
    );
};

export default PageNotFound;
