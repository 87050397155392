import React from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import _ from 'lodash';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { Edit2, Trash, Globe } from 'react-feather';
import { useState } from 'react';
import PaginationClassic from '../../components/pagination/PaginationClassic';
import {
    convertToBill,
    fetchPurchaseOrder,
    fetchPurchaseOrders,
    getPurchaseOrder,
} from '../../app/reducers/PurchaseOrder/purchaseOrderSlice';
// import EditPurchaseModal from "./EditPurchaseModal";
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { ClipLoader } from 'react-spinners';
import moment from 'moment';
import { useFormik } from 'formik';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { generateOptions } from '../../utils/Utils';
import { Link } from 'react-router-dom';
// import EditMenu from '../../components/DropdownEditMenu/DropdownEditMenu'
import axios from 'axios';
import { toast } from 'react-toastify';
import SplitModal from './SplitModal';
import { customIDs } from '../../utils/customIds';
import AttachmentModal from '../PurchaseOrder/AttachmentModal';
import EditDateModal from './EditDateModal';
import PrimaryBadge from '../../components/infrastructure/Badges/PrimaryBadge';

const SplitQuarterData = () => {
    const [page, setPage] = useState(1);
    const [editData, setEditData] = useState({});
    const [spitModal, setSplitModal] = useState(false);
    const [viewAttachmentModal, setAttachmentModal] = useState(false);
    const [attachmentData, setAttachmentData] = useState(false);
    const [editDateModalOpen, setEditDateModalOpen] = useState(false);
    const [editPoId, setEditPoId] = useState(null);

    const dispatch = useDispatch();

    const { purchaseOrder, loading } = useSelector(getPurchaseOrder); // created on 16-11-22

    const purchaseOrdersData = useMemo(
        () =>
            purchaseOrder?.purchaseorders ? purchaseOrder.purchaseorders : [],
        [purchaseOrder]
    );

    const purchaseOrderPageContext = useMemo(
        () => (purchaseOrder?.page_context ? purchaseOrder.page_context : []),
        [purchaseOrder]
    );

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            search: 'vendor',
        },
    });

    // const convertBill = async (data) => {
    //     setButtonId(data.purchaseorder_id)
    //     dispatch(convertToBill({ id: data.purchaseorder_id}))
    //     dispatch(fetchPurchaseOrders({ page: page,status:'open' }))
    // }
    const { purchaseOrderLoading } = useSelector(getPurchaseOrder);

    useEffect(() => {
        let currentQuarter = moment().quarter();

        dispatch(
            fetchPurchaseOrders({
                page: page,
                status: 'open',
                date_start: moment()
                    .quarter(currentQuarter - 1)
                    .startOf('quarter')
                    .format('YYYY-MM-DD'),
                date_end: moment()
                    .quarter(currentQuarter - 1)
                    .endOf('quarter')
                    .format('YYYY-MM-DD'),
            })
        );
    }, [page]);

    const cols = [
        {
            Header: 'Action',
            Cell: ({ row, data }) => {
                return (
                    <div
                        className="flex items-center gap-2"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <PrimaryButton
                            onClick={() => {
                                setSplitModal(true);
                                setEditData({ ...row.original, page: page });
                            }}
                        >
                            Split
                        </PrimaryButton>
                    </div>
                );
            },
        },
        // {
        //   Header: "SRNO",
        //   Cell: ({ row, data }) => {
        //     return 200 * (page - 1) + row.index + 1;
        //   },
        // },
        // {
        //   Header: "DATE",
        //   Cell: ({ row }) => {
        //     return moment(row.original.date).format("DD/MM/YYYY");
        //   },
        // },
        {
            Header: 'PURCHASE ORDER#',
            Cell: ({ row, data }) => {
                return (
                    <div
                        className={
                            row?.original?.has_attachment
                                ? 'underline text-blue-500 cursor-pointer'
                                : 'cursor-pointer'
                        }
                        onClick={(e) => {
                            e.stopPropagation();
                            setAttachmentData({
                                id: row.original.purchaseorder_id,
                                page: page,
                            });
                            setAttachmentModal(true);
                        }}
                    >
                        {row.original.purchaseorder_number}
                    </div>
                );
            },
            style: {
                width: '12%',
            },
        },
        {
            Header: 'QTY TAKA',
            accessor: 'cf_qty_taka',
            style: {
                width: '8%',
            },
        },
        {
            Header: 'QUALITY NAME',
            accessor: 'cf_quality_name',
        },
        {
            Header: 'VENDOR NAME',
            accessor: 'vendor_name',
        },
        {
            Header: 'BUYER NAME',
            Cell: ({ data, row }) => {
                return (
                    <div className=" gap-4 flex flex-col">
                        <div className="flex gap-4">
                            <span>{row?.original?.cf_buyer_name}</span>
                        </div>
                        {row?.original?.['cf_po_note'] && (
                            <div className="flex">
                                <PrimaryBadge>
                                    {
                                        row?.original?.['cf_po_note']?.split(
                                            '-'
                                        )[0]
                                    }
                                </PrimaryBadge>
                                <span className="text-[0.5rem]">
                                    {
                                        row?.original?.['cf_po_note']?.split(
                                            '-'
                                        )[1]
                                    }
                                </span>
                            </div>
                        )}
                    </div>
                );
            },
        },
        {
            Header: 'DATE',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col">
                        <span
                            className="underline text-indigo-500 cursor-pointer"
                            onClick={(e) => {
                                e.stopPropagation();
                                setEditDateModalOpen(true);
                                setEditPoId(row.original.purchaseorder_id);
                            }}
                        >
                            Issue Date{' '}
                            {moment(row.original.date).format('DD/MM/YYYY')}
                        </span>
                        <span>
                            Delivery Date{' '}
                            {moment(row.original.delivery_date).format(
                                'DD/MM/YYYY'
                            )}
                        </span>
                    </div>
                );
            },
            style: {
                width: '20%',
            },
        },
        {
            Header: 'RATE',
            accessor: 'cf_rate',
        },
        {
            Header: 'AMOUNT',
            accessor: 'total',
        },
    ];

    const handleNext = (e) => {
        e.preventDefault();
        setPage(page + 1);
    };

    const debouncedSearch = useCallback(
        _.debounce((search) => {
            let data = {};
            if (formik.values.search == 'vendor') {
                data['vendor_name_contains'] = search;
            } else {
                data[
                    `custom_field_${customIDs.purchase_custom_buyerName}_contains`
                ] = search;
            }
            let currentQuarter = moment().quarter();
            dispatch(
                fetchPurchaseOrders({
                    ...data,
                    page: 1,
                    status: 'open',
                    date_start: moment()
                        .quarter(currentQuarter - 1)
                        .startOf('quarter')
                        .format('YYYY-MM-DD'),
                    date_end: moment()
                        .quarter(currentQuarter - 1)
                        .endOf('quarter')
                        .format('YYYY-MM-DD'),
                })
            );
        }, 300),
        [JSON.stringify(formik.values)]
    );
    return (
        <div className="p-4">
            {/* <EditPurchaseModal
        title={"Edit Purchase Order"}
        setPurchaseModal={setPurchaseModal}
        viewPurchaseModal={viewPurchaseModal}
        data={editData}
      /> */}
            <SplitModal
                title={'Edit Purchase Order'}
                setSplitModal={setSplitModal}
                viewSplitModal={spitModal}
                data={editData}
            />
            <AttachmentModal
                viewAttachmentModal={viewAttachmentModal}
                setAttachmentModal={setAttachmentModal}
                data={attachmentData}
            />
            <EditDateModal
                editDateModalOpen={editDateModalOpen}
                setEditDateModalOpen={setEditDateModalOpen}
                editPoId={editPoId}
                setEditPoId={setEditPoId}
            />
            <div className="mb-3">
                <FormikSelectGroup
                    formik={formik}
                    label="Search Type"
                    name="search"
                    onChange={(selectedOption) => {
                        let currentQuarter = moment().quarter();
                        dispatch(
                            fetchPurchaseOrders({
                                page: page,
                                status: 'open',
                                date_start: moment()
                                    .quarter(currentQuarter - 1)
                                    .startOf('quarter')
                                    .format('YYYY-MM-DD'),
                                date_end: moment()
                                    .quarter(currentQuarter - 1)
                                    .endOf('quarter')
                                    .format('YYYY-MM-DD'),
                            })
                        );
                        formik.setFieldValue('search', selectedOption.value);
                    }}
                    options={generateOptions({
                        array: [
                            { label: 'Vendor', value: 'vendor' },
                            { label: 'Customer', value: 'customer' },
                        ],
                        labelField: 'label',
                        valueField: 'value',
                    })}
                    required
                />
            </div>
            <TableWithHeadingAndGlobalSearch
                heading={'Purchase Orders'}
                placeholder={`Search for ${
                    formik.values.search == 'vendor'
                        ? 'vendor Name'
                        : 'customer Name'
                }`}
                data={purchaseOrdersData}
                loading={loading}
                columns={cols}
                searchFunction={(search) => {
                    debouncedSearch(search);
                }}
            />
            {!loading && (
                <div className="mt-4 flex gap-3 justify-end">
                    {page > 1 && (
                        <PrimaryButton onClick={(e) => setPage(page - 1)}>
                            Previous
                        </PrimaryButton>
                    )}
                    {purchaseOrderPageContext.has_more_page && (
                        <PrimaryButton onClick={(e) => handleNext(e)}>
                            Next
                        </PrimaryButton>
                    )}
                </div>
            )}
        </div>
    );
};

export default SplitQuarterData;
