import React from 'react';
import { useEffect } from 'react';
import {
    Link,
    useLocation,
    useNavigate,
    createSearchParams,
} from 'react-router-dom';

function PaginationClassic({ paginationDetails, setPage, required }) {
    const location = useLocation();
    const navigate = useNavigate();

    const {
        pagingCounter,
        hasNextPage,
        totalDocs,
        docs,
        hasPrevPage,
        prevPage,
        nextPage,
    } = paginationDetails;
    return (
        <>
            {typeof docs !== 'undefined' ? (
                <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                    <nav
                        className="mb-4 sm:mb-0 sm:order-1"
                        role="navigation"
                        aria-label="Navigation"
                    >
                        <ul className="flex justify-center mt-3">
                            <li className="ml-3 first:ml-0">
                                <button
                                    className="btn bg-white border-slate-200 hover:border-slate-300 text-indigo-500 disabled:cursor-not-allowed disabled:text-slate-300 disabled:hover:border-slate-200"
                                    disabled={!hasPrevPage}
                                    onClick={() => {
                                        setPage(prevPage ? prevPage : 1);
                                        navigate({
                                            pathname: location.pathname,
                                            search: `?${createSearchParams({
                                                page: prevPage ? prevPage : 1,
                                            })}`,
                                        });
                                    }}
                                >
                                    &lt;- Previous
                                </button>
                            </li>
                            <li className="ml-3 first:ml-0">
                                <button
                                    className="btn bg-white border-slate-200 hover:border-slate-300 text-indigo-500 disabled:cursor-not-allowed disabled:text-slate-300 disabled:hover:border-slate-200"
                                    disabled={!hasNextPage}
                                    // onClick ={()=>setPage(nextPage ? nextPage : 1)}
                                    onClick={() => {
                                        setPage(nextPage ? nextPage : 1);
                                        navigate(
                                            {
                                                pathname: location.pathname,
                                                search: `?${createSearchParams({
                                                    page: nextPage
                                                        ? nextPage
                                                        : 1,
                                                })}`,
                                            },
                                            { replace: true }
                                        );
                                    }}
                                >
                                    Next -&gt;
                                </button>
                            </li>
                        </ul>
                    </nav>
                    <div className="text-sm text-slate-500 text-center sm:text-left">
                        Showing{' '}
                        <span className="font-medium text-slate-600">
                            {pagingCounter}
                        </span>{' '}
                        to{' '}
                        <span className="font-medium text-slate-600">
                            {pagingCounter + docs.length - 1}
                        </span>{' '}
                        of{' '}
                        <span className="font-medium text-slate-600">
                            {totalDocs}
                        </span>{' '}
                        results
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
}

export default PaginationClassic;
