import { useEffect, useMemo, useState } from 'react';
import PageWithCard from '../components/infrastructure/PageWithCard';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVendors, getVendor } from '../app/reducers/Vendors/vendorSlice';
import { useNavigate, useParams } from 'react-router-dom';
import base64 from 'base-64';
import TableWithHeadingAndSearch from '../components/Table/TableWithHeadingAndSearch';
import { CheckCircle, Edit3 } from 'react-feather';
import EditVendorModal from './Views/EditVendorModal';
import FormikInputGroup from '../components/formik/FormikInputGroup';
import { useFormik } from 'formik';
import ShowDropdown from '../components/infrastructure/ShowDropdown';
import PrimaryButton from '../components/infrastructure/Buttons/PrimaryButton';
import SecondaryButton from '../components/infrastructure/Buttons/SecondaryButton';
const decodeQualityName = (qualityName) => {
    return base64.decode(qualityName);
};
const VendorByQuality = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [section, setSection] = useState([]);
    const [editData, setEditData] = useState({});
    const qualityName = decodeQualityName(params.qualityName);
    const { vendors, loading: vendorLoading } = useSelector(getVendor);
    console.log('params', params);
    useEffect(() => {
        dispatch(
            fetchVendors({
                [`quality_details.quality_name`]: qualityName,
            })
        );
    }, []);

    const cols = [
        {
            Header: 'Company Name',
            Cell: ({ row }) => {
                return (
                    <div className="flex gap-2 cursor-pointer">
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsModalOpen(true);
                                setEditData(row.original);
                                setSection(['company_name']);
                            }}
                        >
                            {row?.original?.company_name}
                        </div>
                        {row?.original?.priority?.toUpperCase() === 'HIGH' && (
                            <CheckCircle color="green" />
                        )}
                    </div>
                );
            },
        },
        {
            Header: 'First Name',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col gap-1 cursor-pointer ">
                        {row?.original?.first_name && (
                            <span
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setIsModalOpen(true);
                                    setEditData(row.original);
                                    setSection(['vendor_name']);
                                }}
                            >
                                {row?.original?.first_name}
                            </span>
                        )}
                    </div>
                );
            },
            accessor: 'first_name',
        },
        {
            Header: 'Mobile',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col gap-1 cursor-pointer">
                        {row?.original?.mobile && (
                            <span
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setIsModalOpen(true);
                                    // console.log(row.original);
                                    setEditData(row.original);
                                    setSection(['phone']);
                                }}
                            >
                                {row?.original?.mobile}
                            </span>
                        )}
                    </div>
                );
            },
        },
        {
            Header: 'Work',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col gap-1 cursor-pointer">
                        {row?.original?.phone && (
                            <span
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setIsModalOpen(true);
                                    setEditData(row.original);
                                    setSection(['phone']);
                                }}
                            >
                                {row?.original?.phone}
                            </span>
                        )}
                    </div>
                );
            },
        },
        {
            Header: 'Action',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col gap-1 cursor-pointer">
                        <Edit3
                            onClick={async (e) => {
                                e.stopPropagation();
                                setIsModalOpen(true);
                                // console.log(row.original);
                                setSection(['all']);

                                setEditData(row.original);
                            }}
                        />
                    </div>
                );
            },
        },
    ];
    const formik = useFormik({
        initialValues: {},
        onSubmit: async (values) => {
            console.log(values);
            const keys = Object.keys(values);
            const filterObj = keys.reduce((acc, ele) => {
                acc[`quality_details.${ele}`] = values[ele];

                return acc;
            }, {});
            await dispatch(
                fetchVendors({
                    [`quality_details.quality_name`]: qualityName,
                    [`quality_details.type`]: params.qualityType,
                    ...filterObj,
                })
            );
        },
    });
    const vendorMemo = useMemo(
        () => (vendors.docs ? vendors.docs : []),
        [vendors]
    );
    const navigate = useNavigate();
    return (
        <PageWithCard heading={qualityName}>
            <SecondaryButton onClick={() => navigate(-1)}>Back</SecondaryButton>
            <EditVendorModal
                title={'Edit Vendor'}
                setIsOpen={setIsModalOpen}
                isOpen={isModalOpen}
                data={editData}
                section={section}
            />

            <ShowDropdown heading={'Vendor filter'} allowedRoles={['admin']}>
                <form onSubmit={formik.handleSubmit}>
                    <div className="grid grid-cols-3 gap-2">
                        <FormikInputGroup
                            formik={formik}
                            name={`pana`}
                            label="Pana"
                        />
                        <FormikInputGroup
                            formik={formik}
                            name={`fani`}
                            label="Fani"
                        />
                        <FormikInputGroup
                            formik={formik}
                            name={`weight`}
                            label="Weight"
                        />
                        <FormikInputGroup
                            formik={formik}
                            name={`pick`}
                            label="Pick"
                        />
                        <FormikInputGroup
                            formik={formik}
                            name={`denier_beam`}
                            label="Denier Beam"
                        />
                        <FormikInputGroup
                            formik={formik}
                            name={`denier_vana`}
                            label="Denier Vana"
                        />
                        <FormikInputGroup
                            formik={formik}
                            name={`tpm_beam`}
                            label="Tpm Beam"
                        />
                        <FormikInputGroup
                            formik={formik}
                            name={`tpm_vana`}
                            label="Tpm Vana"
                        />
                        <FormikInputGroup
                            formik={formik}
                            name={`yarn_beam`}
                            label="Yarn Beam"
                        />
                        <FormikInputGroup
                            formik={formik}
                            name={`yarn_vana`}
                            label="Yarn Vana"
                        />
                    </div>

                    <div className="my-4">
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            </ShowDropdown>

            <TableWithHeadingAndSearch
                data={vendorMemo}
                columns={cols}
                heading={'vendors'}
            />
        </PageWithCard>
    );
};

export default VendorByQuality;
