import { createSlice } from '@reduxjs/toolkit';
import { generateStateSingle } from '../../generators/generateState';
import {
    createData,
    createWithFile,
    deleteData,
    editData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';
import { generateSetEditId } from '../../generators/generateReducers';

const SLICE_NAME = 'auth';

export const fetchUsers = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchUser = fetchEditData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createUser = createData.generateThunk(SLICE_NAME, 'auth/register');
export const deleteUser = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editUser = editData.generateThunk(SLICE_NAME, SLICE_NAME);

const userSlice = createSlice({
    initialState: generateStateSingle(SLICE_NAME),
    reducers: {
        setEditId: generateSetEditId(),
    },
    name: SLICE_NAME,
    extraReducers: {
        ...fetchData.generateExtraReducers(fetchUsers, SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchUser, SLICE_NAME),
        ...createWithFile.generateExtraReducers(createUser, SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteUser, SLICE_NAME),
        ...editData.generateExtraReducers(editUser, SLICE_NAME),
    },
});

export const getUsers = (state) => state.users;
export const { setEditId } = userSlice.actions;
export default userSlice.reducer;
