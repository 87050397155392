import { useFormik } from 'formik';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { useDispatch, useSelector } from 'react-redux';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import { useEffect, useState } from 'react';
import { Edit2, Loader, Trash2 } from 'react-feather';
import {
    createReminderSubject,
    deleteReminderSubject,
    editReminderSubject,
    fetchReminderSubject,
    fetchReminderSubjects,
    getReminderSubject,
} from '../../app/reducers/ReminderSubject/reminderSubjectSlice';
import * as Yup from 'yup';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { generateOptions } from '../../utils/Utils';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';

const EditReminderSubjectModal = ({ data, isOpen, setIsOpen }) => {
    const dispatch = useDispatch();
    // useEffect(() => {
    //     // dispatch(fetchReminderSubject({ _id: id }));
    // }, [id]);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: data.name,
            type: data.type,
            _id: data._id,
        },
        onSubmit: async (values) => {
            try {
                await dispatch(editReminderSubject(values));
                await dispatch(fetchReminderSubjects());
                setIsOpen(false);
            } catch (error) {
                console.log(error);
            }
        },
    });

    return (
        <ModalBasic
            title={'Edit Subject'}
            modalOpen={isOpen}
            setModalOpen={setIsOpen}
        >
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-4 m-4 "
            >
                <FormikInputGroup formik={formik} name={'name'} label="Name" />
                <FormikSelectGroup
                    label="Type"
                    formik={formik}
                    name="type"
                    required
                    options={generateOptions({
                        array: [
                            { label: 'Customer', value: 'customer' },
                            { label: 'Vendor', value: 'vendor' },
                        ],
                        labelField: 'label',
                        valueField: 'value',
                    })}
                />
                <div>
                    {formik.isSubmitting ? (
                        <Loader className="animate-spin" />
                    ) : (
                        <PrimaryButton type="submit">Submit </PrimaryButton>
                    )}
                </div>
            </form>
        </ModalBasic>
    );
};

const ReminderSubject = () => {
    const { reminderSubject, loading } = useSelector(getReminderSubject);
    const dispatch = useDispatch();
    const [editSubjectOpen, setEditSubjectOpen] = useState(false);
    const [editData, setEditData] = useState({});
    useEffect(() => {
        dispatch(fetchReminderSubjects());
    }, []);
    const formik = useFormik({
        initialValues: {
            name: '',
            type: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required(),
            type: Yup.string().required(),
        }),
        onSubmit: async (values) => {
            await dispatch(createReminderSubject(values));
            formik.resetForm();
            dispatch(fetchReminderSubjects());
        },
    });
    const cols = [
        {
            Header: 'SRNO',
            Cell: ({ row }) => row.index + 1,
        },
        {
            Header: 'name',
            accessor: 'name',
        },
        {
            Header: 'type',
            accessor: 'type',
        },
        {
            Header: 'Action',
            Cell: ({ row }) => (
                <div className="flex gap-3">
                    <Trash2
                        onClick={async () => {
                            await dispatch(
                                deleteReminderSubject({ id: row.original._id })
                            );
                            dispatch(fetchReminderSubjects());
                        }}
                    />
                    <Edit2
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setEditSubjectOpen(true);
                            setEditData(row.original);
                        }}
                    />
                </div>
            ),
        },
    ];
    return (
        <PageWithCard heading="Reminder Subject">
            <EditReminderSubjectModal
                isOpen={editSubjectOpen}
                setIsOpen={setEditSubjectOpen}
                data={editData}
            />
            <div className="flex flex-col gap-4">
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-4"
                >
                    <FormikInputGroup
                        formik={formik}
                        name={'name'}
                        label="Name"
                    />
                    <FormikSelectGroup
                        label="Type"
                        formik={formik}
                        name="type"
                        required
                        options={generateOptions({
                            array: [
                                { label: 'Customer', value: 'customer' },
                                { label: 'Vendor', value: 'vendor' },
                            ],
                            labelField: 'label',
                            valueField: 'value',
                        })}
                    />
                    <div>
                        <PrimaryButton type="submit">Submit </PrimaryButton>
                    </div>
                </form>
                <TableWithHeadingAndSearch
                    columns={cols}
                    data={reminderSubject.docs ? reminderSubject.docs : []}
                    heading={'Reminder Subject'}
                    loading={loading}
                />
            </div>
        </PageWithCard>
    );
};

export default ReminderSubject;
