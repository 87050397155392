import React from 'react';

const PrimaryBadge = ({ children, className }) => {
    return (
        <div
            className={`ml-1 text-xs inline-flex font-medium bg-indigo-100 text-indigo-600 rounded-full break-all px-2.5 py-1 ${className}`}
        >
            {children}
        </div>
    );
};

export default PrimaryBadge;
