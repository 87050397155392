import React from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import _ from 'lodash';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { generateOptions } from '../../utils/Utils';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import moment from 'moment';
import { useFormik } from 'formik';
import {
    fetchCustomers,
    getCustomer,
} from '../../app/reducers/Customer/customerSlice';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';
import * as Yup from 'yup';
import {
    fetchInvoices,
    getInvoice,
    reset,
} from '../../app/reducers/Invoice/invoice.slice';
import { authAxiosInstance } from '../../utils/axiosConfig';
import QueryString from 'qs';
import { toast } from 'react-toastify';
import { AddTrackingIdModal } from './AddTrackingIdModal';
import EditMenu from '../../components/DropdownEditMenu/DropdownEditMenu';
import { Tracking } from './Tracking';
import { PhoneCall, Send } from 'react-feather';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import {
    PRINT_OPTIONS,
    SEARCH_BY_INVOICE_OPTIONS,
} from '../../utils/dropdownOptions';
import { customIDs } from '../../utils/customIds';
import ContactModal from '../PurchaseOrder/ContactModal';
import PrinterSelect from '../Notification/PrinterSelect';
import ProgressBar from '../../components/progressBar/ProgressBar';
import {
    fetchPrinters,
    printInvoice,
} from '../../app/reducers/Print/printSlice';
import InvoiceDataPopup from './InvoiceDataPopup';
import { ClipLoader, ClockLoader } from 'react-spinners';
import { Clock } from 'react-feather';
import { openReminderPopUp } from '../../app/reducers/ReminderPopUp/reminderPopUpSlice';

const ViewInvoice = () => {
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [filterInvoicesData, setFilterInvoicesData] = useState([]);
    const [invoiceTrackingData, setInvoiceTrackingData] = useState(null);
    const [contactModalOpen, setContactModalOpen] = useState(false);
    const [contactDetails, setContactDetails] = useState({});
    const [selectedPrinter, setSelectedPrinter] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [progressLoading, setProgressLoading] = useState(false);
    const [currentItem, setCurrentItem] = useState(0);
    const [totalItem, setTotalItem] = useState(0);
    const [printerSelectModalOpen, setPrinterSelectModalOpen] = useState(false);
    const [clickedInvoiceId, setClickedInvoiceId] = useState('');
    const [invoicePopupOpen, setInvoicePopupOpen] = useState(false);
    const [printOption, setPrintOption] = useState(PRINT_OPTIONS[0]);
    const [loadingReminder, setLoadingReminder] = useState(false);

    const dispatch = useDispatch();
    const { invoices, loading } = useSelector(getInvoice); // created on 16-11-22

    console.log(invoices, 'invioces');

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchBy: 'customerName',
            filterStatus: ['draft', 'sent', 'overdue'],
        },
        // validationSchema: Yup.object({
        //   customer: Yup.string().required(),
        //   from: Yup.mixed().required(),
        //   to: Yup.mixed().required(),
        // }),
        onSubmit: async (values, { resetForm }) => {
            console.log(values);
            // let data = {
            //   customer_id: values.customer ? values.customer : "",
            //   date_start: values.from ? moment(values.from).format("YYYY-MM-DD") : "",
            //   date_end: values.to ? moment(values.to).format("YYYY-MM-DD") : "",
            // };
            // const docs = await dispatch(fetchInvoices(data));
            // const filterDocs = docs?.payload?.invoices?.filter((el) =>
            //   formik.values.filterStatus.includes(el.current_sub_status)
            // );
            // if (filterDocs) {
            //   setFilterInvoicesData(filterDocs);
            // }
        },
    });

    const handleStatusChangeOnSend = async (invoiceData) => {
        try {
            setIsLoading(true);
            if (invoiceData.current_sub_status === 'draft') {
                const resp = await authAxiosInstance.patch(
                    `invoice/${invoiceData.invoice_id}`,
                    {
                        status: 'sent',
                    }
                );
                await dispatch(fetchInvoices());
                return resp;
            } else {
                alert(`Sorry, Status is ${invoiceData.current_sub_status} `);
            }
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong');
        } finally {
            setIsLoading(false);
        }
    };

    const computeColor = (inVoiceData) => {
        switch (inVoiceData) {
            case 'draft':
                return '#eeeeee';
            case 'overdue':
                return 'red';
            case 'sent':
                return 'green';
            case 'default':
                return '#000';
        }
    };

    const handleInvoiceTrackingId = (invoiceData) => {
        setInvoiceTrackingData(invoiceData);
        setModalIsOpen(true);
    };

    const openPrintModal = (e) => {
        e.stopPropagation();
        setPrinterSelectModalOpen(true);
    };

    const printInvoices = async () => {
        try {
            if (selectedPrinter.value) {
                setProgressLoading(true);
                setTotalItem(selectedRows.length);

                for (let i = 0; i < selectedRows.length; i++) {
                    const element = selectedRows[i];
                    await dispatch(
                        printInvoice({
                            invoiceId: element.invoice_id,
                            printerId: selectedPrinter.value,
                            printInvoice: ['all', 'invoice'].includes(
                                printOption.value
                            ),
                            printReport: ['all', 'report'].includes(
                                printOption.value
                            ),
                        })
                    );
                    setCurrentItem(currentItem + 1);
                }
            }
        } catch (error) {
            console.log(error);
            toast.error('error printing data');
        }
    };

    useEffect(async () => {
        await dispatch(reset());
        dispatch(fetchPrinters());
    }, []);

    useEffect(() => {
        const getInvoices = async () => {
            if (page != 0) {
                // let data = {};

                const docs = await dispatch(fetchInvoices({ page: page }));
                const filterDocs = docs?.payload?.invoices?.filter((el) =>
                    // el.current_sub_status.includes(formik.values.filterStatus)
                    formik.values.filterStatus.includes(el.current_sub_status)
                );
                if (filterDocs) {
                    setFilterInvoicesData(filterDocs);
                }
            }
        };
        getInvoices();
    }, [page]);

    const cols = [
        {
            Header: 'Select',
            Cell: ({ row, data }) => (
                <input
                    type="checkbox"
                    checked={selectedRows
                        .map((ele) => ele.invoice_id)
                        .includes(row.original.invoice_id)}
                    onChange={(e) => {
                        e.preventDefault();
                        if (e.target.checked) {
                            setSelectedRows([...selectedRows, row.original]);
                        } else {
                            const data = selectedRows.filter(
                                (item) =>
                                    item.invoice_id !== row.original.invoice_id
                            );
                            setSelectedRows(data);
                        }
                    }}
                />
            ),
        },
        {
            Header: 'SRNO',
            Cell: ({ row, data }) => {
                return 200 * (page - 1) + row.index + 1;
            },
        },
        {
            Header: 'DATE',
            Cell: ({ row }) => {
                // console.log(row?.original, "check");
                return moment(row.original.date).format('DD/MM/YYYY');
            },
        },
        {
            Header: 'Invoice No.',
            Cell: ({ row }) => {
                return (
                    <span
                        className="underline text-indigo-500 cursor-pointer"
                        onClick={(e) => {
                            e.stopPropagation();
                            setInvoicePopupOpen(true);
                            setClickedInvoiceId(row?.original?.invoice_id);
                        }}
                    >
                        {row.original.invoice_number}
                    </span>
                );
            },
        },
        // {
        //   Header: "Customer Name",
        //   accessor: "company_name",
        // },
        {
            Header: 'Customer Name',
            Cell: ({ data, row }) => {
                return (
                    <div className=" gap-4 flex ">
                        <span>{row?.original?.company_name}</span>
                        <PhoneCall
                            onClick={(e) => {
                                e.stopPropagation();
                                setContactModalOpen(true);
                                setContactDetails({
                                    contact_type: 'customer',
                                    name: row.original.company_name,
                                });
                            }}
                            color="blue"
                            size={18}
                        />
                    </div>
                );
            },
        },
        // {
        //   Header: "Status",
        //   Cell: ({ row }) => {
        //     const status = row?.original?.current_sub_status;

        //     if (status === "draft") {
        //       return <div className="text text-black">{status}</div>;
        //     } else {
        //       return <div className="text text-green-500">{status}</div>;
        //     }
        //   },
        // },
        {
            Header: 'Tracking ID',
            Cell: ({ row }) => {
                return <Tracking trackingData={row?.original} />;
            },
        },
        {
            Header: 'AMOUNT',
            accessor: 'total',
        },
        {
            Header: 'BALANCE',
            accessor: 'balance',
        },
        {
            Header: 'Action',
            Cell: ({ row }) => {
                return (
                    <div
                        className="flex gap-2 "
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {loadingReminder == row.original.invoice_id ? (
                            <ClockLoader size={25} speedMultiplier={2} />
                        ) : (
                            <button
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    setLoadingReminder(
                                        row.original?.invoice_id
                                    );
                                    let popUpData = {
                                        cf_buyer_name:
                                            row.original?.customer_name,
                                    };
                                    //Customer Mobile No.
                                    const customerData = {
                                        search: row.original?.cf_buyer_name,
                                    };
                                    const customerString =
                                        QueryString.stringify(customerData);
                                    const customerContacts =
                                        await authAxiosInstance.get(
                                            `purchase-orders/contacts?${customerString}`
                                        );
                                    console.log(customerContacts, 'new');
                                    if (customerContacts?.data?.data) {
                                        popUpData['customerMobileNo'] =
                                            customerContacts?.data?.data?.contacts?.[0]?.mobile;
                                    }
                                    dispatch(
                                        openReminderPopUp({
                                            isOpen: true,
                                            data: {
                                                ...popUpData,
                                                type: 'customer',
                                            },
                                        })
                                    );
                                    setLoadingReminder('');
                                }}
                                className="ml-2"
                            >
                                <Clock
                                    size={25}
                                    color={
                                        row.original['cf_po_reminder'] ==
                                            'YES' ||
                                        row.original['cf_complaint_reminder'] ==
                                            'YES'
                                            ? '#ffff1a'
                                            : 'black'
                                    }
                                />
                            </button>
                        )}
                        <Send
                            color={computeColor(
                                row.original.current_sub_status
                            )}
                            className="cursor-pointer "
                            onClick={() =>
                                handleStatusChangeOnSend(row?.original)
                            }
                        />

                        {/* <MoreHorizontal
              color="gray"
              className="cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                handleInvoiceTrackingId(row?.original);
              }}
            /> */}

                        <EditMenu
                            align="right"
                            className="relative inline-flex"
                        >
                            <li>
                                <span
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleInvoiceTrackingId(row?.original);
                                    }}
                                    className="font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3"
                                    style={{ cursor: 'pointer' }}
                                >
                                    Add Tracking Id
                                </span>
                            </li>
                            <hr></hr>
                            <li>
                                <a
                                    target="_blank"
                                    className="font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3"
                                    style={{ cursor: 'pointer' }}
                                    href={`${row?.original?.cf_po_report_url}`}
                                >
                                    PO Report
                                </a>
                            </li>
                            <hr></hr>
                            <li>
                                <a
                                    className="font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3"
                                    style={{ cursor: 'pointer' }}
                                    onClick={async () => {
                                        await authAxiosInstance.post(
                                            'https://app2.com.bot/webhook/656f3c5b6d9d4d243c78e8fa',

                                            {
                                                invoice: {
                                                    customer_id:
                                                        row?.original
                                                            .customer_id,
                                                    custom_field_hash: {
                                                        cf_statement_period:
                                                            row?.original
                                                                .cf_statement_period,
                                                        cf_statement_period_unformatted:
                                                            row?.original
                                                                .cf_statement_period_unformatted,
                                                    },
                                                },
                                            }
                                        );
                                        toast.success('function executed');
                                    }}
                                >
                                    Send Statement PDF
                                </a>
                            </li>
                            <hr></hr>
                            <li>
                                <a
                                    className="font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3"
                                    style={{ cursor: 'pointer' }}
                                    onClick={async () => {
                                        await authAxiosInstance.post(
                                            'https://app2.com.bot/webhook/656572842e49e2083c34c26b',
                                            {
                                                receiver: '919913872218',
                                                action: 'pickup',
                                                customer_address:
                                                    row.original.billing_address
                                                        .address,
                                                customer_id:
                                                    row.original.customer_id,
                                            }
                                        );
                                        toast.success('function executed');
                                    }}
                                >
                                    Pick_C/Drop_K(M)
                                </a>
                            </li>
                            <hr></hr>
                            <li>
                                <a
                                    className="font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3"
                                    style={{ cursor: 'pointer' }}
                                    onClick={async () => {
                                        await authAxiosInstance.post(
                                            'https://app2.com.bot/webhook/656572842e49e2083c34c26b',
                                            {
                                                receiver: '918758261222',
                                                action: 'pickup',
                                                customer_address:
                                                    row.original.billing_address
                                                        .address,
                                                customer_id:
                                                    row.original.customer_id,
                                            }
                                        );
                                        toast.success('function executed');
                                    }}
                                >
                                    Pick_C/Drop_K(N)
                                </a>
                            </li>
                            <hr></hr>
                            <li>
                                <a
                                    className="font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3"
                                    style={{ cursor: 'pointer' }}
                                    onClick={async () => {
                                        await authAxiosInstance.post(
                                            'https://app2.com.bot/webhook/656572842e49e2083c34c26b',
                                            {
                                                receiver: '919913872218',
                                                action: 'drop',
                                                customer_address:
                                                    row.original.billing_address
                                                        .address,
                                                customer_id:
                                                    row.original.customer_id,
                                            }
                                        );
                                        toast.success('function executed');
                                    }}
                                >
                                    Pick_K/Drop_C(M)
                                </a>
                            </li>
                            <hr></hr>
                            <li>
                                <a
                                    className="font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3"
                                    style={{ cursor: 'pointer' }}
                                    onClick={async () => {
                                        await authAxiosInstance.post(
                                            'https://app2.com.bot/webhook/656572842e49e2083c34c26b',
                                            {
                                                receiver: '918758261222',
                                                action: 'drop',
                                                customer_address:
                                                    row.original.billing_address
                                                        .address,
                                                customer_id:
                                                    row.original.customer_id,
                                            }
                                        );
                                        toast.success('function executed');
                                    }}
                                >
                                    Pick_K/Drop_C(N)
                                </a>
                            </li>

                            {/* <hr></hr> */}
                        </EditMenu>
                    </div>
                );
            },
        },
    ];

    const handleNext = (e) => {
        e.preventDefault();
        setPage(page + 1);
    };

    const debouncedSearch = useCallback(
        _.debounce(async (search) => {
            let data = {};
            if (formik.values.searchBy === 'phone') {
                data[
                    `custom_field_${customIDs.invoice_mobile_number}_contains`
                ] = search;
            }
            if (formik.values.searchBy === 'customerName') {
                data[`customer_name_contains`] = search;
            }

            const docs = await dispatch(fetchInvoices({ ...data, page: 1 }));
            const filterDocs = docs?.payload?.invoices?.filter((el) =>
                // el.current_sub_status.includes(formik.values.filterStatus)
                formik.values.filterStatus.includes(el.current_sub_status)
            );
            if (filterDocs) {
                setFilterInvoicesData(filterDocs);
            }
            // dispatch(
            //   fetchInvoices({
            //     ...data,
            //     page: 1,
            //   })
            // );
        }, 300),
        [JSON.stringify(formik.values)]
    );

    const inVoiceData = useMemo(
        () => (filterInvoicesData ? filterInvoicesData : []),
        [filterInvoicesData]
    );

    const invoicePageContext = useMemo(
        () => (invoices?.page_context ? invoices.page_context : []),
        [invoices]
    );

    return (
        <div className="p-4">
            <AddTrackingIdModal
                updateInvoiceTrackingIdModalOpen={modalIsOpen}
                setUpdateInvoiceTrackingIdModalOpen={setModalIsOpen}
                trackingData={invoiceTrackingData}
            />
            <ContactModal
                setContactModalOpen={setContactModalOpen}
                contactModalOpen={contactModalOpen}
                contactDetails={contactDetails}
            />
            <PrinterSelect
                printerSelectModalOpen={printerSelectModalOpen}
                setPrinterSelectModalOpen={setPrinterSelectModalOpen}
                selectedPrinter={selectedPrinter}
                setSelectedPrinter={setSelectedPrinter}
                selectedRows={selectedRows}
                printInvoices={printInvoices}
                printOption={printOption}
                setPrintOption={setPrintOption}
            />
            <InvoiceDataPopup
                invoicePopupOpen={invoicePopupOpen}
                setInvoicePopupOpen={setInvoicePopupOpen}
                clickedInvoiceId={clickedInvoiceId}
                setClickedInvoiceId={setClickedInvoiceId}
            />
            {progressLoading ? (
                <ProgressBar totalItem={totalItem} currentItem={currentItem} />
            ) : (
                <>
                    <h1 className="text-lg font-bold col-span-2 mb-3">
                        View Invoice
                    </h1>

                    <form onSubmit={formik.handleSubmit}>
                        <FormikSelectGroup
                            label="Search By"
                            name={'searchBy'}
                            formik={formik}
                            options={SEARCH_BY_INVOICE_OPTIONS}
                        />
                    </form>
                    <TableWithHeadingAndGlobalSearch
                        placeholder={`Search for invoice`}
                        data={inVoiceData}
                        loading={loading || isLoading}
                        columns={cols}
                        searchFunction={(search) => {
                            debouncedSearch(search);
                        }}
                        heading={
                            <div className="flex gap-4">
                                <span className="text-xl">View Invoices</span>
                                {selectedRows?.length > 0 && (
                                    <PrimaryButton onClick={openPrintModal}>
                                        Print
                                    </PrimaryButton>
                                )}
                            </div>
                        }
                    />
                    {!loading && (
                        <div className="mt-4 flex gap-3 justify-end">
                            {page > 1 && (
                                <PrimaryButton
                                    onClick={(e) => setPage(page - 1)}
                                >
                                    Previous
                                </PrimaryButton>
                            )}
                            {invoicePageContext.has_more_page && (
                                <PrimaryButton onClick={(e) => handleNext(e)}>
                                    Next
                                </PrimaryButton>
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default ViewInvoice;
