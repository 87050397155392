import { createSlice } from '@reduxjs/toolkit';
import { generateSetEditId } from '../../generators/generateReducers';
import { generateStateSingle } from '../../generators/generateState';
import {
    createData,
    deleteData,
    editData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';
const SLICE_NAME = 'notification';

export const fetchNotifications = fetchData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const editNotification = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchNotification = fetchEditData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const deleteNotification = deleteData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const createNotification = createData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);

const notificationSlice = createSlice({
    name: SLICE_NAME,
    initialState: generateStateSingle(SLICE_NAME),
    reducers: {
        setEditId: generateSetEditId(),
    },
    extraReducers: {
        ...fetchData.generateExtraReducers(fetchNotifications, SLICE_NAME),
        ...createData.generateExtraReducers(createNotification, SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteNotification, SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchNotification, SLICE_NAME),
        ...editData.generateExtraReducers(editNotification, SLICE_NAME),
    },
});

export const getNotification = (state) => state.notification;
export const { setEditId } = notificationSlice.actions;
export default notificationSlice.reducer;
