import React, { useCallback, useEffect, useState } from 'react';
import { useMemo } from 'react';
import { Eye, Trash } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteUser,
    fetchUsers,
    getUsers,
} from '../../app/reducers/Users/usersSlice';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';

const ViewUser = () => {
    const dispatch = useDispatch();
    const debouncedSearch = useCallback(
        _.debounce(async (search) => {
            dispatch(fetchUsers({ search: search }));
        }, 300),
        []
    );

    useEffect(() => {
        dispatch(fetchUsers());
    }, []);
    const { loading, auth } = useSelector(getUsers);

    const columns = [
        {
            Header: 'SRNO',
            Cell: (prop) => {
                const { data, row } = prop;
                return row.index + 1;
            },
        },
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Phone',
            accessor: 'username',
        },
        {
            Header: 'Role',
            accessor: 'role',
        },
        {
            Header: 'Action',
            Cell: (prop) => {
                const { data, row } = prop;
                return (
                    <div
                        className="flex gap-2"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Trash
                            size={18}
                            color="red"
                            onClick={async () => {
                                await dispatch(
                                    deleteUser({
                                        id: row.original._id,
                                    })
                                );
                                dispatch(fetchUsers());
                            }}
                        />
                    </div>
                );
            },
        },
    ];
    const columnsMemo = useMemo(() => columns, [columns]);
    const dataMemo = useMemo(() => (auth?.docs ? auth.docs : []), [auth?.docs]);
    return (
        <PageWithCard heading="View Users">
            <TableWithHeadingAndGlobalSearch
                columns={columns}
                data={dataMemo}
                heading="Users"
                loading={loading}
                searchFunction={debouncedSearch}
            />
        </PageWithCard>
    );
};

export default ViewUser;
