import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { toast } from 'react-toastify';
import { authAxiosInstance } from '../../../utils/axiosConfig';

const initialState = {
    loading: false,
};
export const placeCall = createAsyncThunk(
    'calling/placeCall',
    async ({ phone }, { rejectWithValue }) => {
        try {
            const resp = await authAxiosInstance.get(`calling/${phone}`);
            toast.success('Call Placed');
            return resp.data;
        } catch (error) {
            console.log(error);
            if (error?.response?.message) toast.error(error.response.message);
            else toast.error('error placing call');
        }
    }
);

const callingSlice = createSlice({
    name: 'calling',
    initialState: initialState,

    extraReducers: {
        [placeCall.pending]: (state) => {
            state.loading = true;
        },
        [placeCall.fulfilled]: (state, action) => {
            state.loading = false;
        },
        [placeCall.rejected]: (state, action) => {
            state.loading = false;
        },
    },
});

export const getCalling = (state) => state.calling;
export default callingSlice.reducer;
