import { createSlice } from '@reduxjs/toolkit';
import { generateSetEditId } from '../../generators/generateReducers';
import { generateStateSingle } from '../../generators/generateState';
import {
    createData,
    deleteData,
    editData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';
const SLICE_NAME = 'features';

export const fetchFeatures = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editFeature = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createFeature = createData.generateThunk(SLICE_NAME, SLICE_NAME);

const emailSlice = createSlice({
    name: SLICE_NAME,
    initialState: generateStateSingle(SLICE_NAME),
    reducers: {
        setEditId: generateSetEditId(),
    },
    extraReducers: {
        ...fetchData.generateExtraReducers(fetchFeatures, SLICE_NAME),
        ...createData.generateExtraReducers(createFeature, SLICE_NAME),
        ...editData.generateExtraReducers(editFeature, SLICE_NAME),
    },
});

export const getFeatures = (state) => state.features;
export const { setEditId } = emailSlice.actions;
export default emailSlice.reducer;
