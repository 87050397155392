import { useFormik } from 'formik';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { useDispatch, useSelector } from 'react-redux';
import {
    createYarnGrade,
    deleteYarnGrade,
    fetchYarnGrades,
    getYarnGrade,
} from '../../app/reducers/YarnGrade/yarnGradeSlice';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import { useEffect } from 'react';
import { Trash2 } from 'react-feather';

const YarnGrade = () => {
    const { yarnGrade, loading } = useSelector(getYarnGrade);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchYarnGrades());
    }, []);
    const formik = useFormik({
        initialValues: {
            name: '',
        },
        onSubmit: async (values) => {
            await dispatch(createYarnGrade(values));
            dispatch(fetchYarnGrades());
        },
    });
    const cols = [
        {
            Header: 'SRNO',
            Cell: ({ row }) => row.index + 1,
        },
        {
            Header: 'name',
            accessor: 'name',
        },
        {
            Header: 'Action',
            Cell: ({ row }) => (
                <>
                    <Trash2
                        onClick={async () => {
                            await dispatch(
                                deleteYarnGrade({ id: row.original._id })
                            );
                            dispatch(fetchYarnGrades());
                        }}
                    />
                </>
            ),
        },
    ];
    return (
        <PageWithCard heading="Yarn Grade">
            <div className="flex flex-col gap-4">
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-4"
                >
                    <FormikInputGroup
                        formik={formik}
                        name={'name'}
                        label="Name"
                    />
                    <div>
                        <PrimaryButton type="submit">Submit </PrimaryButton>
                    </div>
                </form>
                <TableWithHeadingAndSearch
                    columns={cols}
                    data={yarnGrade.docs ? yarnGrade.docs : []}
                    heading={'Yarn Grade'}
                    loading={loading}
                />
            </div>
        </PageWithCard>
    );
};

export default YarnGrade;
