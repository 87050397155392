import React from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useFormik } from 'formik';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { useDispatch } from 'react-redux';
import { fetchInvoices } from '../../app/reducers/Invoice/invoice.slice';
import { useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { customIDs } from '../../utils/customIds';

export const AddTrackingIdModal = ({
    updateInvoiceTrackingIdModalOpen,
    setUpdateInvoiceTrackingIdModalOpen,
    trackingData,
}) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const formik = useFormik({
        initialValues: {
            trackingId: '',
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                setIsLoading(true);
                const custom_field = trackingData?.custom_fields[0];
                if (custom_field) {
                    const resp = await authAxiosInstance.patch(`invoice`, {
                        invoice_id: trackingData.invoice_id,
                        custom_fields: [
                            {
                                customfield_id: customIDs.invoice_tracking_id,
                                value: values.trackingId,
                            },
                        ],
                    });
                    resetForm();
                    setUpdateInvoiceTrackingIdModalOpen(false);
                    await dispatch(fetchInvoices());
                    return resp;
                }
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        },
    });
    return (
        <ModalBasic
            title={'Add Invoice Tracking Id'}
            modalOpen={updateInvoiceTrackingIdModalOpen}
            setModalOpen={setUpdateInvoiceTrackingIdModalOpen}
        >
            {isLoading ? (
                <ClipLoader />
            ) : (
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-2 p-4"
                >
                    <FormikInputGroup formik={formik} name="trackingId" />
                    <div>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            )}
        </ModalBasic>
    );
};
