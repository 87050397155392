import { createSlice } from '@reduxjs/toolkit';
import { generateStateSingle } from '../../generators/generateState';
import {
    createData,
    deleteData,
    editData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';

const SLICE_NAME = 'project';

export const fetchProjects = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchProject = fetchEditData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createProject = createData.generateThunk(SLICE_NAME, SLICE_NAME);
export const deleteProject = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editProject = editData.generateThunk(SLICE_NAME, SLICE_NAME);

const projectReducer = createSlice({
    initialState: generateStateSingle(SLICE_NAME),
    name: SLICE_NAME,
    extraReducers: {
        ...fetchData.generateExtraReducers(fetchProjects, SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchProject, SLICE_NAME),
        ...createData.generateExtraReducers(createProject, SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteProject, SLICE_NAME),
        ...editData.generateExtraReducers(editProject, SLICE_NAME),
    },
});

export const getProjects = (state) => state.projects;

export default projectReducer.reducer;
