import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { toast } from 'react-toastify';
import { authAxiosInstance } from '../../../utils/axiosConfig';
import QueryString from 'qs';
const initialState = {
    loading: false,
    printers: [],
};

export const printInvoice = createAsyncThunk(
    'print/printInvoice',
    async (data, { rejectWithValue }) => {
        try {
            const resp = await authAxiosInstance.post(
                'print/print-invoice',
                data
            );
            return resp.data;
        } catch (error) {
            console.log(error);
            if (error?.response?.message) toast.error(error.response.message);
            else toast.error('failed to fetch contacts');
        }
    }
);

export const fetchPrinters = createAsyncThunk('print/getPrinters', async () => {
    try {
        const resp = await authAxiosInstance.get('print/get-printers');
        return resp.data;
    } catch (error) {
        console.log(error);
        if (error?.response?.message) toast.error(error.response.message);
        else toast.error('Failed to fetch Printers');
    }
});

const printSlice = createSlice({
    name: 'print',
    initialState: initialState,
    reducers: {},
    extraReducers: {
        [printInvoice.pending]: (state) => {
            state.loading = true;
        },
        [printInvoice.fulfilled]: (state, action) => {
            state.loading = false;
        },
        [printInvoice.rejected]: (state, action) => {
            state.loading = false;
        },
        [fetchPrinters.pending]: (state) => {
            state.loading = true;
        },
        [fetchPrinters.fulfilled]: (state, action) => {
            state.loading = false;
            state.printers = action.payload.data;
        },
        [fetchPrinters.rejected]: (state, action) => {
            state.loading = false;
        },
    },
});

export const getPrint = (state) => state.print;
export default printSlice.reducer;
