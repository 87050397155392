import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useDispatch, useSelector } from 'react-redux';
import {
    convertToBill,
    fetchPurchaseOrder,
    fetchPurchaseOrders,
    getPurchaseOrder,
    updatePurchaseOrderQuantity,
} from '../../app/reducers/PurchaseOrder/purchaseOrderSlice';
import { ClipLoader } from 'react-spinners';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import moment from 'moment';
import * as Yup from 'yup';
import { customIDs } from '../../utils/customIds';

const ResolveComplain = ({
    viewResolveModal,
    setResolveModal,
    title,
    data,
    formik: topFormik,
}) => {
    const dispatch = useDispatch();
    console.log(data.page);
    const { purchaseOrderLoading } = useSelector(getPurchaseOrder);
    const [docs, setDocs] = useState([]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: data.id,
            complain: '',
            custom_fields: [],
        },
        validationSchema: Yup.object({
            complain: Yup.string().required(),
            custom_fields: Yup.array(),
        }),
        onSubmit: async (values, { resetForm }) => {
            let updateData = {
                id: values.id,
                custom_fields: [
                    ...values.custom_fields,
                    {
                        value: 'COMPLAINT SOLVE',
                        customfield_id: customIDs.purchase_custom_tag_2,
                    },
                    // {
                    //   value: `${values.complain}`,
                    //   customfield_id: customIDs.purchase_custom_solve_note,
                    // },
                    {
                        value: `${values.complain} --- ${moment().format('DD/MM')}`,
                        customfield_id: customIDs.purchase_custom_solve_note,
                    },
                ],
            };
            await dispatch(updatePurchaseOrderQuantity(updateData));
            setResolveModal(false);

            if (topFormik) {
                topFormik.submitForm();
            } else {
                await dispatch(
                    fetchPurchaseOrders({
                        page: data.page,
                        [`custom_field_${customIDs.purchase_custom_has_complaint}_contains`]:
                            'yes',
                    })
                );
            }
        },
    });

    useEffect(async () => {
        if (data.id) {
            console.log('insert', data.id);
            const action = await dispatch(fetchPurchaseOrder({ id: data.id }));
            if (action.payload.purchaseorder) {
                formik.setFieldValue(
                    'custom_fields',
                    action.payload.purchaseorder.custom_fields
                );
                setDocs(
                    action.payload.purchaseorder?.documents
                        ? action.payload.purchaseorder.documents
                        : []
                );
                // formik.setFieldValue('line_items', action.payload.purchaseorder.line_items)
            }
        }
    }, [data.id]);
    function calculateDateDifference(date) {
        const day = parseInt(date.substring(0, 5).split('/')[0]);
        const month = parseInt(date.substring(0, 5).split('/')[1]);
        const currentDate = moment();
        const targetDate = moment().set({
            month: month - 1,
            date: day,
        });
        if (currentDate.isBefore(targetDate)) {
            const previousYear = moment().subtract(1, 'year');
            const previousYearTargetDate = moment(previousYear).set({
                month: month - 1,
                date: day,
            });
            const daysDifference = currentDate.diff(
                previousYearTargetDate,
                'days'
            );
            console.log(
                `Number of days between ${date} and the current date: ${daysDifference} days (from the previous year)`
            );
            return daysDifference;
        } else {
            const daysDifference = targetDate.diff(currentDate, 'days');
            console.log(
                `Number of days between ${date} and the current date: ${daysDifference} days`
            );
            return daysDifference;
        }
    }
    return (
        <ModalBasic
            title={title}
            modalOpen={viewResolveModal}
            setModalOpen={setResolveModal}
        >
            {purchaseOrderLoading ? (
                <ClipLoader />
            ) : (
                <form
                    className="flex flex-col gap-4 p-4"
                    onSubmit={formik.handleSubmit}
                >
                    <FormikInputGroup
                        label="PO Number"
                        formik={formik}
                        type="number"
                        name="id"
                        readOnly
                    />
                    <ul>
                        {docs.map((ele, i) => {
                            const link = `https://books.zoho.com/api/v3/purchaseorders/${data.id}/documents/${ele.document_id}?organization_id=${process.env.REACT_APP_ZOHO_ORGANIZATION_ID}&inline=true`;
                            return (
                                <li>
                                    <a
                                        className="underline text-indigo-600"
                                        href={link}
                                        target="__blank"
                                    >
                                        {ele.uploaded_on_date_formatted}
                                    </a>
                                    {calculateDateDifference(
                                        moment(
                                            ele.uploaded_on_date_formatted
                                        ).format('DD/MM/YYYY')
                                    )}{' '}
                                    days
                                </li>
                            );
                        })}
                    </ul>
                    <FormikInputGroup
                        label="Solve Note"
                        formik={formik}
                        name="complain"
                    />
                    <div>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            )}
        </ModalBasic>
    );
};

export default ResolveComplain;
