import React, { useState, useEffect, useMemo } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { generateValueFromStores, generateOptions } from '../../utils/Utils';
import { useSelector, useDispatch } from 'react-redux';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { ClipLoader } from 'react-spinners';
import { array, mixed, number, object, string } from 'yup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { toast } from 'react-toastify';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import { fetchCustomers } from '../../app/reducers/Customer/customerSlice';
import { GST_VLAUES } from '../../utils/dropdownOptions';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import { useNavigate } from 'react-router-dom';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import moment from 'moment';
import {
    createProject,
    fetchProjects,
    getProjects,
} from '../../app/reducers/Project/projectSlice';

const DiagnosisProject = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    let initialData = localStorage.getItem('projectData');
    let parseProjectData = JSON.parse(initialData);
    // const [projectData, setProjectData] = useState(parseProjectData);
    const { project, loading: projectLoading } = useSelector(getProjects);
    console.log(project, 'projects');
    const projectData = useMemo(
        () => (project?.docs ? project.docs : []),
        [project]
    );

    let reportObj = {
        group: '',
        chalan: '',
        amount: '',
        finalAmount: '',
        brockrage: '',
        edit: true,
    };
    useEffect(() => {
        dispatch(fetchProjects());
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            project: '',
            customer: '',
            gst: '',
            start: '',
            end: '',
            brockrage: 0,
        },
        validationSchema: object({
            customer: string().required(),
            project: string().required(),
            gst: string().required(),
            start: mixed().required(),
            end: mixed().required(),
            brockrage: number().required(),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                setLoading(true);
                let data = {
                    ...values,
                    zohoTotal: 0,
                    junkTotal: 0,
                    customerTotal: 0,
                    reports: [reportObj],
                };
                await dispatch(createProject(data));
                await dispatch(fetchProjects());
                setLoading(false);
                resetForm();
            } catch (error) {
                console.log(error);
                toast.error('Something Went Wrong while Saving Data');
                resetForm();
            } finally {
                setLoading(false);
            }
        },
    });

    const cols = [
        {
            Header: 'SRNO',
            Cell: ({ row, data }) => {
                return row.index + 1;
            },
        },
        {
            Header: 'Project Name',
            accessor: 'project',
        },
        {
            Header: 'Customer',
            accessor: 'customer',
        },
        {
            Header: 'Brockrage',
            accessor: 'brockrage',
        },
        {
            Header: 'Action',
            Cell: ({ row, data }) => {
                return (
                    <>
                        <PrimaryButton
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate(
                                    `/ultraDiagnosis/${row.original.project}`
                                );
                            }}
                        >
                            View
                        </PrimaryButton>
                    </>
                );
            },
        },
    ];

    return (
        <PageWithCard heading="Diagnosis Projects">
            <div>
                {loading ? (
                    <ClipLoader />
                ) : (
                    <form
                        className="flex flex-col gap-4"
                        onSubmit={formik.handleSubmit}
                    >
                        <FormikInputGroup
                            name="project"
                            label="Project"
                            formik={formik}
                        />
                        <FormikAsyncSelect
                            name="customer"
                            label="Customer"
                            formik={formik}
                            getOptions={async (value) => {
                                const action = await dispatch(
                                    fetchCustomers({
                                        search: value,
                                        contact_type: 'customer',
                                    })
                                );
                                const serverResp = action.payload.contacts.map(
                                    (ele) => ({
                                        label: ele.contact_name,
                                        value: `${ele.contact_id}/${ele.cf_commission}/${ele.contact_name}`,
                                    })
                                );
                                return serverResp;
                            }}
                            onChange={(option) => {
                                console.log(option.value);
                                let id = option.value.split('/')[0];
                                let brockrage = option.value.split('/')[1]
                                    ? option.value.split('/')[1]
                                    : 0;
                                let customer = option.value.split('/')[2];
                                console.log(customer, 'customer check');
                                formik.setFieldValue('customer', customer);
                                formik.setFieldValue('brockrage', brockrage);
                            }}
                        />
                        <FormikSelectGroup
                            formik={formik}
                            label="GST"
                            name={`gst`}
                            options={generateOptions({
                                array: GST_VLAUES,
                                labelField: 'label',
                                valueField: 'value',
                            })}
                            required
                        />
                        {formik.values.customer && (
                            <FormikInputGroup
                                name={`brockrage`}
                                label={'Brockrage'}
                                formik={formik}
                                type="number"
                                readOnly
                            />
                        )}
                        <FormikInputDateGroup
                            label="From"
                            name="start"
                            formik={formik}
                        />
                        <FormikInputDateGroup
                            label="To"
                            name="end"
                            formik={formik}
                        />
                        <div className="flex gap-3">
                            <PrimaryButton type="submit">Submit</PrimaryButton>
                        </div>
                    </form>
                )}
            </div>
            <TableWithHeadingAndSearch
                heading={'Projects'}
                data={projectData}
                loading={projectLoading}
                columns={cols}
            />
        </PageWithCard>
    );
};

export default DiagnosisProject;
