import React, { useEffect, useState } from 'react';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { axiosInstance } from '../../utils/axiosConfig';
import QueryString from 'qs';
import { toast } from 'react-toastify';
import { useSearchParams, useLocation } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const Bill = () => {
    const [singleSaleData, setSingleSaleData] = useState({});
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const paramsData = {};
    for (const [key, value] of queryParams) {
        paramsData[key] = value;
    }
    useEffect(async () => {
        const fetchBillData = async () => {
            try {
                let poUrlData = {
                    from: paramsData.from,
                    to: paramsData.to,
                    customer: paramsData.customer,
                };
                let poString = QueryString.stringify(poUrlData);
                const resp = await axiosInstance.get(
                    `purchase-orders/bill/report?${poString}`
                );
                return resp?.data?.data;
            } catch (error) {
                console.log(error);
                toast.error('Failed to fetch Data');
            }
        };
        let billData = await fetchBillData();
        const unit = 'pt';
        const size = 'A4'; // Use A1, A2, A3 or A4
        const orientation = 'portrait'; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);
        const totalBillAmount = billData.reduce(
            (acc, ele) => (acc += ele.total),
            0
        );

        const title = `Kukreja Agency - Bill Details - ${billData[0].cf_customer_name}\n\nTotal Bill Amount :- ${totalBillAmount}`;
        const headers = [
            [
                'Status',
                'BILL_DATE#',
                'DUE DATE',
                'BILL#',
                'Vendor NAME',
                'AMOUNT',
                'QTY_TAKA',
                'QUALITY',
                'RATE',
            ],
        ];

        const data =
            billData &&
            billData.map((item, index) => [
                item.status,
                item.date,
                item.due_date,
                item.bill_number,
                item.vendor_name,
                item.total,
                item.cf_qty_taka,
                item.cf_quality_name,
                item.cf_rate,
            ]);

        let content = {
            startY: 90,
            head: headers,
            body: data,
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save('bill.pdf');
    }, []);

    return (
        <>
            <div className="flex flex-col md:hidden w-screen h-screen justify-center items-center p-4 gap-4">
                <h1 className="items-center font-bold">
                    Thank You for making a purchase with Kukreja
                </h1>
            </div>
        </>
    );
};

export default Bill;
