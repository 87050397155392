import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import ModalBasic from './ModalBasic';

const EditModal = ({
    editProperty,
    setEditProperty,
    getEditData,
    children,
    ...props
}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!modalOpen) {
            dispatch(setEditProperty(null));
        }
    }, [modalOpen]);
    useEffect(() => {
        if (editProperty) {
            dispatch(getEditData({ _id: editProperty }))
                .then(() => {
                    setModalOpen(true);
                })
                .catch((err) => {
                    toast.error('failed to open modal');
                });
        } else {
            setModalOpen(false);
        }
    }, [editProperty]);
    return (
        <ModalBasic
            {...props}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
        >
            {children}
        </ModalBasic>
    );
};

export default EditModal;
