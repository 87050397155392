import { FieldArray, FormikProvider, useFormik } from 'formik';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { X, XSquare } from 'react-feather';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { PRIORITY_OPTIONS } from '../../utils/dropdownOptions';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getQuality } from '../../app/reducers/Quality/qualitySlice';
import {
    fetchContact,
    updateCustomer,
} from '../../app/reducers/Customer/customerSlice';
import { customIDs } from '../../utils/customIds';
import {
    editVendor,
    fetchVendor,
    getVendor,
} from '../../app/reducers/Vendors/vendorSlice';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import ShowDropdown from '../../components/infrastructure/ShowDropdown';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import moment from 'moment';
import {
    fetchYarnGrades,
    getYarnGrade,
} from '../../app/reducers/YarnGrade/yarnGradeSlice';
import { generateOptions } from '../../utils/Utils';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';

const computeQualityTagString = (array) => {
    let tagArr = [];
    for (let i of array) {
        tagArr.push(i.quality_tag);
    }
    return tagArr.join(' , ');
};

const EditVendorModal = ({ isOpen, setIsOpen, title, data, section }) => {
    const computeInitialValue = (data) => {
        const obj = { ...data };
        if (data?.quality_details?.length) {
            obj.quality_details = data.quality_details.map((ele) => {
                const { quality_name, quality_tag, type, ...rest } = ele;
                const obj = {
                    quality_name: quality_name,
                    quality_tag: quality_tag,
                    type: type,
                };
                const json = JSON.stringify(obj);

                return { quality_name: json, quality_tag, type, ...rest };
            });
        }
        return obj;
    };

    const { elementEditData, loading: vendorLoading } = useSelector(getVendor);
    const dispatch = useDispatch();
    const [qualityTagLoading, setQualityTagLoading] = useState(false);
    const { yarnGrade, loading: yarnGradeLoading } = useSelector(getYarnGrade);
    useEffect(() => {
        setQualityTagLoading(true);
        if (isOpen) {
            dispatch(fetchVendor({ contact_id: data.contact_id }));
            dispatch(fetchContact({ id: data.contact_id })).then((resp) => {
                formik.setFieldValue(
                    'QualityTag',
                    resp.payload?.contact.cf_title
                );
                setQualityTagLoading(false);
            });
            dispatch(fetchYarnGrades());
        }
    }, [data]);
    const { quality, loading } = useSelector(getQuality);

    const qualityMemo = useMemo(
        () => (quality.docs ? quality.docs : []),
        [quality]
    );
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: computeInitialValue(elementEditData),
        onSubmit: async (values) => {
            let valuesCopy = { ...values };
            const parsedQualityDetails = valuesCopy.quality_details.map(
                (ele) => {
                    const parsedJson = JSON.parse(ele.quality_name);
                    const { quality_name, quality_tag, type, _id, ...rest } =
                        ele;
                    const qualityObj = {
                        quality_name: parsedJson.quality_name,
                        quality_tag: parsedJson.quality_tag,
                        type: parsedJson.type,
                        ...rest,
                    };
                    return qualityObj;
                }
            );
            valuesCopy['quality_details'] = parsedQualityDetails;
            if (
                valuesCopy.challan_name_1?.[0] == '' ||
                !valuesCopy.challan_name_1
            ) {
                valuesCopy.challan_name_1 = [];
            }

            await dispatch(editVendor(valuesCopy));

            const updatedObj = {
                id: valuesCopy.contact_id,
                notes: valuesCopy.notes,
                contact_persons: valuesCopy.contact_persons.map((ele) => ({
                    first_name: ele.first_name,
                    last_name: ele.last_name,
                    phone: ele?.phone,
                    mobile: ele.mobile,
                })),
                // contact_persons: [
                //   {
                //     first_name: valuesCopy.first_name,
                //     last_name: valuesCopy.last_name,
                //     phone: valuesCopy.phone,
                //     mobile: valuesCopy.mobile,
                //   },
                // ],
                custom_fields: [
                    {
                        customfield_id: customIDs.customer_priority,
                        value: valuesCopy.priority,
                    },
                    {
                        customfield_id:
                            customIDs.customer_manager_mobile_number,
                        value: valuesCopy.manager_mobile_number,
                    },

                    {
                        customfield_id: customIDs.customer_yarn_grade,
                        value: valuesCopy.yarn_grade,
                    },
                    {
                        customfield_id: customIDs.customer_birthday,
                        value: moment(valuesCopy.birthday).format('YYYY-MM-DD'),
                    },
                    {
                        customfield_id: customIDs.customer_update_date,
                        value: moment().format('YYYY-MM-DD'),
                    },
                ],
            };

            if (valuesCopy?.quality_details.length) {
                updatedObj.custom_fields.push({
                    customfield_id: customIDs.customer_title,
                    value: computeQualityTagString(valuesCopy?.quality_details),
                });
            }
            if (data?.cf_calling) {
                updatedObj.custom_fields.push({
                    customfield_id: customIDs.customer_calling,
                    value: data?.cf_calling,
                });
            }
            if (valuesCopy.challan_name?.length) {
                updatedObj.custom_fields.push({
                    customfield_id: customIDs.customer_chalan_name,
                    value: valuesCopy.challan_name.join(' , '),
                });
            }
            if (valuesCopy?.challan_name_1?.length) {
                updatedObj.custom_fields.push({
                    customfield_id: customIDs.customer_chalan_name1,
                    value: valuesCopy.challan_name_1.join(' , '),
                });
            } else {
                updatedObj.custom_fields.push({
                    customfield_id: customIDs.customer_chalan_name1,
                    value: '',
                });
            }
            setQualityTagLoading(true);
            await dispatch(updateCustomer(updatedObj)).then((resp) =>
                resp.payload?.contact
                    ? toast.success('Data Updated in Zoho')
                    : toast.error('Failed to update in zoho')
            );
            await dispatch(fetchContact({ id: data.contact_id })).then(
                (resp) => {
                    formik.setFieldValue(
                        'QualityTag',
                        resp.payload?.contact.cf_title
                    );
                    setQualityTagLoading(false);
                }
            );
            setIsOpen(false);
        },
    });

    return (
        <ModalBasic
            title={title}
            modalOpen={isOpen}
            setModalOpen={setIsOpen}
            max_width="max-w-4xl"
        >
            <div className="m-4">
                {vendorLoading ? (
                    <ClipLoader />
                ) : (
                    <form onSubmit={formik.handleSubmit}>
                        {(section.includes('company_name') ||
                            section.includes('all')) && (
                            <>
                                <FormikInputGroup
                                    formik={formik}
                                    name={'contact_id'}
                                    label="Contact Id"
                                    readOnly
                                />
                                <FormikInputGroup
                                    formik={formik}
                                    name={'company_name'}
                                    label="Company Name"
                                    readOnly
                                />
                                <FormikSelectGroup
                                    formik={formik}
                                    name={'priority'}
                                    label="Priority"
                                    options={PRIORITY_OPTIONS}
                                />
                            </>
                        )}
                        {(section.includes('vendor_name') ||
                            section.includes('all')) && (
                            <>
                                <FormikInputGroup
                                    formik={formik}
                                    name={'contact_persons.0.first_name'}
                                    label="First Name"
                                />
                                <FormikInputGroup
                                    formik={formik}
                                    name={'contact_persons.0.last_name'}
                                    label="Last Name"
                                />
                                <FormikInputGroup
                                    formik={formik}
                                    name={'work_name'}
                                    label="work Name"
                                />
                            </>
                        )}
                        {(section.includes('phone') ||
                            section.includes('all')) && (
                            <>
                                <FormikInputGroup
                                    formik={formik}
                                    name={'contact_persons.0.mobile'}
                                    label="Mobile"
                                />
                                <FormikInputGroup
                                    formik={formik}
                                    name={'contact_persons.0.phone'}
                                    label="phone"
                                />
                                <FormikInputGroup
                                    formik={formik}
                                    name={'manager_mobile_number'}
                                    label="Manager Mobile Number"
                                />
                            </>
                        )}

                        {section.includes('all') && (
                            <>
                                <hr className="border-1 my-4" />

                                <FormikProvider value={formik}>
                                    <ShowDropdown
                                        heading={'Contact person'}
                                        allowedRoles={['admin']}
                                    >
                                        <FieldArray
                                            name="contact_persons"
                                            render={(arrayHelpers) => (
                                                <>
                                                    {/* <label>Contact Person</label> */}
                                                    <div className="flex flex-col ">
                                                        {formik.values.contact_persons?.map(
                                                            (ele, index) =>
                                                                index > 0 && (
                                                                    <div
                                                                        key={
                                                                            index
                                                                        }
                                                                        className="flex items-center gap-2 "
                                                                    >
                                                                        <FormikInputGroup
                                                                            formik={
                                                                                formik
                                                                            }
                                                                            name={`contact_persons.${index}.first_name`}
                                                                            label={
                                                                                'Contact person first name' +
                                                                                index
                                                                            }
                                                                        />
                                                                        <FormikInputGroup
                                                                            formik={
                                                                                formik
                                                                            }
                                                                            name={`contact_persons.${index}.last_name`}
                                                                            label={
                                                                                'Contact person last name' +
                                                                                index
                                                                            }
                                                                        />
                                                                        <FormikInputGroup
                                                                            formik={
                                                                                formik
                                                                            }
                                                                            name={`contact_persons.${index}.mobile`}
                                                                            label={
                                                                                'Contact person mobile' +
                                                                                index
                                                                            }
                                                                        />
                                                                        <FormikInputGroup
                                                                            formik={
                                                                                formik
                                                                            }
                                                                            name={`contact_persons.${index}.phone`}
                                                                            label={
                                                                                'Contact person phone' +
                                                                                index
                                                                            }
                                                                        />
                                                                        <X
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                e.stopPropagation();
                                                                                arrayHelpers.remove(
                                                                                    index
                                                                                );
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )
                                                        )}
                                                        <div>
                                                            <PrimaryButton
                                                                onClick={() =>
                                                                    arrayHelpers.push(
                                                                        null
                                                                    )
                                                                }
                                                            >
                                                                Add more
                                                            </PrimaryButton>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        />
                                    </ShowDropdown>

                                    <ShowDropdown
                                        heading={'Challan details'}
                                        allowedRoles={['admin']}
                                        color={
                                            !formik.values.challan_name_1
                                                ?.length &&
                                            formik.values.challan_name?.[0] ==
                                                '0'
                                                ? 'red'
                                                : 'black'
                                        }
                                    >
                                        <FieldArray
                                            name="challan_name"
                                            render={(arrayHelpers) => (
                                                <>
                                                    <label className="text-sm font-medium mb-1">
                                                        CHALLAN NAME
                                                    </label>
                                                    <div className="flex items-center flex-wrap gap-4">
                                                        {formik.values.challan_name?.map(
                                                            (ele, index) => (
                                                                <div
                                                                    key={index}
                                                                    className="flex items-center "
                                                                >
                                                                    <FormikInputGroup
                                                                        formik={
                                                                            formik
                                                                        }
                                                                        name={`challan_name.${index}`}
                                                                        label={
                                                                            'name' +
                                                                            index
                                                                        }
                                                                    />
                                                                    <X
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            e.stopPropagation();
                                                                            arrayHelpers.remove(
                                                                                index
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        )}
                                                        <div>
                                                            <PrimaryButton
                                                                onClick={() =>
                                                                    arrayHelpers.push(
                                                                        null
                                                                    )
                                                                }
                                                            >
                                                                Add more
                                                            </PrimaryButton>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        />
                                        <hr className="border-1 my-4" />
                                        <FieldArray
                                            name="challan_name_1"
                                            render={(arrayHelpers) => (
                                                <>
                                                    <label className="text-sm font-medium mb-1">
                                                        CHALLAN NAME 1{' '}
                                                    </label>
                                                    <div className="flex items-center flex-wrap gap-4">
                                                        {formik.values.challan_name_1?.map(
                                                            (ele, index) => (
                                                                <div
                                                                    key={index}
                                                                    className="flex items-center "
                                                                >
                                                                    <FormikInputGroup
                                                                        formik={
                                                                            formik
                                                                        }
                                                                        name={`challan_name_1.${index}`}
                                                                        label={
                                                                            'name' +
                                                                            index
                                                                        }
                                                                    />
                                                                    <X
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            e.stopPropagation();
                                                                            arrayHelpers.remove(
                                                                                index
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        )}
                                                        <div>
                                                            <PrimaryButton
                                                                onClick={() =>
                                                                    arrayHelpers.push(
                                                                        null
                                                                    )
                                                                }
                                                            >
                                                                Add more
                                                            </PrimaryButton>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        />
                                    </ShowDropdown>
                                </FormikProvider>
                                <hr className="border-1 my-4" />
                                {qualityTagLoading ? (
                                    <ClipLoader />
                                ) : (
                                    <FormikInputGroup
                                        formik={formik}
                                        name={'QualityTag'}
                                        label="What quality they make? (ZOHO)"
                                        readOnly
                                    />
                                )}
                                <FormikProvider value={formik}>
                                    <FieldArray
                                        name="quality_details"
                                        render={(arrayHelpers) => (
                                            <>
                                                <h1 className="text-sm font-medium mb-1">
                                                    QUALITY DETAILS
                                                </h1>
                                                {formik.values?.quality_details?.map(
                                                    (ele, index) => (
                                                        <div
                                                            className=" my-4 relative"
                                                            key={index}
                                                        >
                                                            <XSquare
                                                                className="absolute top-0 right-0"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation();
                                                                    arrayHelpers.remove(
                                                                        index
                                                                    );
                                                                }}
                                                            />
                                                            <div className="grid grid-cols-3 gap-2">
                                                                <FormikSelectGroup
                                                                    formik={
                                                                        formik
                                                                    }
                                                                    name={`quality_details.${index}.quality_name`}
                                                                    label={
                                                                        'Select Quality' +
                                                                        index
                                                                    }
                                                                    options={qualityMemo.map(
                                                                        (
                                                                            ele
                                                                        ) => ({
                                                                            label: ele.name,
                                                                            value: JSON.stringify(
                                                                                {
                                                                                    quality_name:
                                                                                        ele?.name,
                                                                                    quality_tag:
                                                                                        ele?.tag,
                                                                                    type: ele?.type,
                                                                                }
                                                                            ),
                                                                        })
                                                                    )}
                                                                    onChange={(
                                                                        selectedOption
                                                                    ) => {
                                                                        const {
                                                                            quality_tag,
                                                                            type,
                                                                        } =
                                                                            JSON.parse(
                                                                                selectedOption.value
                                                                            );
                                                                        formik.setFieldValue(
                                                                            `quality_details.${index}`,
                                                                            {
                                                                                quality_tag,
                                                                                type,
                                                                            }
                                                                        );
                                                                        formik.setFieldValue(
                                                                            `quality_details.${index}.quality_name`,
                                                                            selectedOption.value
                                                                        );
                                                                    }}
                                                                />

                                                                <FormikInputGroup
                                                                    formik={
                                                                        formik
                                                                    }
                                                                    name={`quality_details.${index}.quality_tag`}
                                                                    label="Quality Tag"
                                                                    readOnly
                                                                />
                                                                <FormikInputGroup
                                                                    formik={
                                                                        formik
                                                                    }
                                                                    name={`quality_details.${index}.type`}
                                                                    label="type"
                                                                    readOnly
                                                                />
                                                            </div>
                                                            <ShowDropdown
                                                                heading={
                                                                    'Additional Details'
                                                                }
                                                                allowedRoles={[
                                                                    'admin',
                                                                ]}
                                                            >
                                                                <div className="grid grid-cols-3 gap-2">
                                                                    <FormikInputGroup
                                                                        formik={
                                                                            formik
                                                                        }
                                                                        name={`quality_details.${index}.pana`}
                                                                        label="Pana"
                                                                    />
                                                                    <FormikInputGroup
                                                                        formik={
                                                                            formik
                                                                        }
                                                                        name={`quality_details.${index}.fani`}
                                                                        label="Fani"
                                                                    />
                                                                    <FormikInputGroup
                                                                        formik={
                                                                            formik
                                                                        }
                                                                        name={`quality_details.${index}.weight`}
                                                                        label="Weight"
                                                                    />
                                                                    <FormikInputGroup
                                                                        formik={
                                                                            formik
                                                                        }
                                                                        name={`quality_details.${index}.pick`}
                                                                        label="Pick"
                                                                    />
                                                                    <FormikInputGroup
                                                                        formik={
                                                                            formik
                                                                        }
                                                                        name={`quality_details.${index}.denier_beam`}
                                                                        label="Denier Beam"
                                                                    />
                                                                    <FormikInputGroup
                                                                        formik={
                                                                            formik
                                                                        }
                                                                        name={`quality_details.${index}.denier_vana`}
                                                                        label="Denier Vana"
                                                                    />
                                                                    <FormikInputGroup
                                                                        formik={
                                                                            formik
                                                                        }
                                                                        name={`quality_details.${index}.tpm_beam`}
                                                                        label="Tpm Beam"
                                                                    />
                                                                    <FormikInputGroup
                                                                        formik={
                                                                            formik
                                                                        }
                                                                        name={`quality_details.${index}.tpm_vana`}
                                                                        label="Tpm Vana"
                                                                    />
                                                                    <FormikInputGroup
                                                                        formik={
                                                                            formik
                                                                        }
                                                                        name={`quality_details.${index}.yarn_beam`}
                                                                        label="Yarn Beam"
                                                                    />
                                                                    <FormikInputGroup
                                                                        formik={
                                                                            formik
                                                                        }
                                                                        name={`quality_details.${index}.yarn_vana`}
                                                                        label="Yarn Vana"
                                                                    />
                                                                    <FormikInputGroup
                                                                        formik={
                                                                            formik
                                                                        }
                                                                        name={`quality_details.${index}.last_rate`}
                                                                        label="Last rate"
                                                                    />
                                                                </div>
                                                            </ShowDropdown>
                                                        </div>
                                                    )
                                                )}
                                                <div className="flex justify-between">
                                                    <PrimaryButton
                                                        onClick={() =>
                                                            arrayHelpers.push(
                                                                null
                                                            )
                                                        }
                                                    >
                                                        Add more
                                                    </PrimaryButton>
                                                    <SecondaryButton>
                                                        <a
                                                            href="/createQuality"
                                                            target="_blank"
                                                        >
                                                            Add Quality
                                                        </a>
                                                    </SecondaryButton>
                                                </div>
                                            </>
                                        )}
                                    />
                                </FormikProvider>
                                <hr className="border-1 my-4" />

                                <FormikSelectGroup
                                    formik={formik}
                                    name={'yarn_grade'}
                                    label="Yarn Grade"
                                    options={generateOptions({
                                        array: yarnGrade?.docs
                                            ? yarnGrade.docs
                                            : [],
                                        labelField: 'name',
                                        valueField: 'name',
                                    })}
                                />
                                <FormikInputDateGroup
                                    formik={formik}
                                    name={'birthday'}
                                    label="birthday"
                                />
                                <FormikInputGroup
                                    formik={formik}
                                    name={'notes'}
                                    label="Remarks"
                                />
                            </>
                        )}
                        <div>
                            <PrimaryButton type="submit">Submit</PrimaryButton>
                        </div>
                    </form>
                )}
            </div>
        </ModalBasic>
    );
};

export default EditVendorModal;
