import { useFormik } from 'formik';
import React, { useEffect, useMemo } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchQualities,
    getQuality,
} from '../../app/reducers/Quality/qualitySlice';
import {
    fetchCustomers,
    getCustomer,
} from '../../app/reducers/Customer/customerSlice';
import { generateOptions } from '../../utils/Utils';
import {
    createPendingOrderList,
    fetchPendingOrderList,
    fetchPendingOrderLists,
    getPendingOrderList,
} from '../../app/reducers/PendingOrderList/pendingOrderListSlice';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { BarLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';

const PendingOrderList = () => {
    const dispatch = useDispatch();
    const { quality, loading: qualityLoading } = useSelector(getQuality);
    const { customers, loading: customerLoading } = useSelector(getCustomer);
    const { pendingOrderList, loading: POLLoading } =
        useSelector(getPendingOrderList);

    useEffect(() => {
        dispatch(fetchQualities({ limit: 300, sort: { priority: -1 } }));
        dispatch(
            fetchCustomers({
                contact_type: 'customer',
            })
        );
    }, []);
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            quality: '',
            customer: '',
        },
        onSubmit: async (values) => {
            const payload = {
                quality: values.quality,
                customerId: values.customer,
            };
            await dispatch(fetchPendingOrderLists(payload));
        },
    });
    // const pendingOrderListMemo = useMemo(()=> pendingOrderList.docs)
    const tableHeadings = [
        'DATE',
        'DELIVERY DATE',
        'P.O#',
        'PRIORITY',
        'VENDOR NAME',
        'CUSTOMER NAME',
        'QTY TAKA',
        'QUALITY NAME',
        'RATE',
        'PENDING ORDER TAKA',
    ];

    const pendingOrderListArr = Object.values(pendingOrderList);
    const tableInitialValue = pendingOrderListArr.reduce((acc, ele, index) => {
        acc[index] = {
            date: ele.date,
            delivery_date: ele.delivery_date,
            po_number: ele.purchaseorder_number,
            priority: ele.cf_tag_3 ?? '',
            vendor_name: ele.vendor_name,
            customer_name: ele.cf_buyer_name,
            qty_taka: ele.cf_qty_taka,
            quality_name: ele.cf_quality_name,
            rate: ele.cf_rate,
            pending_order_taka: 0,
        };
        return acc;
    }, {});
    const tableFormik = useFormik({
        enableReinitialize: true,
        initialValues: tableInitialValue,
        onSubmit: async (values) => {
            console.log(values);
            await dispatch(createPendingOrderList(values)).then((resp) =>
                navigate(`/downloadPendingOrderList/${resp.payload.data._id}`)
            );
        },
    });

    return (
        <PageWithCard heading="Pending Order List">
            <form
                className="grid grid-cols-2 gap-4"
                onSubmit={formik.handleSubmit}
            >
                <FormikSelectGroup
                    formik={formik}
                    name={'customer'}
                    label="Customer"
                    options={generateOptions({
                        array: customers?.contacts ? customers.contacts : [],
                        labelField: 'company_name',
                        valueField: 'contact_id',
                    })}
                />
                <FormikSelectGroup
                    options={generateOptions({
                        array: quality.docs ? quality.docs : [],
                        labelField: 'name',
                        valueField: 'name',
                    })}
                    formik={formik}
                    name={'quality'}
                    label="Quality"
                />
                <div>
                    <PrimaryButton className={'mb-3'} type="submit">
                        Apply Filter
                    </PrimaryButton>
                </div>
            </form>

            {POLLoading ? (
                <div className="flex items-center justify-center">
                    <BarLoader />
                </div>
            ) : (
                <form onSubmit={tableFormik.handleSubmit} className="relative">
                    {pendingOrderListArr.length > 0 && (
                        <div className="absolute top-0 right-0 z-30">
                            <PrimaryButton type="submit">
                                Submit Table Data
                            </PrimaryButton>
                        </div>
                    )}
                    {!pendingOrderListArr.length > 0 ? (
                        <div className="flex items-center justify-center">
                            <h1>No Pending Purchase Order to Show</h1>
                        </div>
                    ) : (
                        <div className="relative overflow-x-auto">
                            <table className="mt-12 w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr className="text-center">
                                        {tableHeadings.map((el, index) => (
                                            <th
                                                key={index}
                                                className="px-3 py-2 font-medium"
                                            >
                                                {el}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {pendingOrderListArr.map((ele, index) => (
                                        <tr
                                            key={index}
                                            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 text-xs text-center"
                                        >
                                            {/* <td className='px-3 py-2 ' >{index + 1}</td> */}
                                            <td className="px-1 py-2 ">
                                                {ele.date}
                                            </td>
                                            <td className="px-1 py-2 ">
                                                {ele.delivery_date}
                                            </td>
                                            <td className="px-1 py-2 ">
                                                {ele.purchaseorder_number}
                                            </td>
                                            <td className="px-1 py-2">
                                                {ele.cf_tag_3}
                                            </td>
                                            <td className="px-1 py-2">
                                                {ele.vendor_name}
                                            </td>
                                            <td className="px-1 py-2">
                                                {ele.cf_buyer_name}
                                            </td>
                                            <td className="px-1 py-2">
                                                {ele.cf_qty_taka}
                                            </td>
                                            <td className="px-1 py-2">
                                                {ele.cf_quality_name}
                                            </td>
                                            <td className="px-1 py-2">
                                                {ele.cf_rate}
                                            </td>
                                            <td className="py-2">
                                                <FormikInputGroup
                                                    formik={tableFormik}
                                                    name={`${index}.pending_order_taka`}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </form>
            )}
        </PageWithCard>
    );
};

export default PendingOrderList;
