import React, { useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { useFormik } from 'formik';
import { authAxiosInstance } from '../../utils/axiosConfig';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

const FetchAllVendors = () => {
    const [loading, setLoading] = useState(false);
    const formik = useFormik({
        initialValues: {
            temp_id: '',
        },
        onSubmit: async (values) => {
            try {
                setLoading(true);
                await authAxiosInstance.post('/vendors/fetch-all-vendors', {
                    temp_id: values.temp_id,
                });
            } catch (error) {
                console.log(error);
                toast.error('error');
            } finally {
                setLoading(false);
            }
        },
    });
    return (
        <PageWithCard>
            <div className="p-4">
                {loading ? (
                    <ClipLoader />
                ) : (
                    <form onSubmit={formik.handleSubmit}>
                        <FormikInputGroup
                            name={'temp_id'}
                            label="Temp id"
                            formik={formik}
                        />
                        <div>
                            <PrimaryButton type="submit">Submit</PrimaryButton>
                        </div>
                    </form>
                )}
            </div>
        </PageWithCard>
    );
};

export default FetchAllVendors;
