import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { toast } from 'react-toastify';
import { authAxiosInstance } from '../../../utils/axiosConfig';
import QueryString from 'qs';
const initialState = {
    contacts: {},
    loading: false,
};
export const fetchContacts = createAsyncThunk(
    'contacts/fetchContacts',
    async (data, { rejectWithValue }) => {
        try {
            const string = QueryString.stringify(data);
            const contacts = await authAxiosInstance.get(
                `purchase-orders/contacts?${string}`
            );
            return contacts.data.data;
        } catch (error) {
            console.log(error);
            if (error?.response?.message) toast.error(error.response.message);
            else toast.error('failed to fetch contacts');
        }
    }
);

const contactSlice = createSlice({
    name: 'contacts',
    initialState: initialState,

    extraReducers: {
        [fetchContacts.pending]: (state) => {
            state.loading = true;
        },
        [fetchContacts.fulfilled]: (state, action) => {
            state.loading = false;
            state.contacts = action.payload;
        },
        [fetchContacts.rejected]: (state, action) => {
            state.loading = false;
        },
    },
});

export const getContacts = (state) => state.contacts;
export default contactSlice.reducer;
