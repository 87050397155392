import { FieldArray, FormikProvider, useFormik } from 'formik';
import ModalBasic from '../components/infrastructure/Modals/ModalBasic';
import FormikInputGroup from '../components/formik/FormikInputGroup';
import PrimaryButton from '../components/infrastructure/Buttons/PrimaryButton';
import { X, XSquare } from 'react-feather';
import FormikSelectGroup from '../components/formik/FormikSelectGroup';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getQuality } from '../app/reducers/Quality/qualitySlice';
import { customIDs } from '../utils/customIds';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import ShowDropdown from '../components/infrastructure/ShowDropdown';
import FormikInputDateGroup from '../components/formik/FormikInputDateGroup';
import moment from 'moment';
import { getYarnGrade } from '../app/reducers/YarnGrade/yarnGradeSlice';
import {
    fetchContact,
    getCustomer,
    resetContact,
    updateCustomer,
} from '../app/reducers/Customer/customerSlice';

const computeQualityTagString = (array) => {
    let tagArr = [];
    for (let i of array) {
        tagArr.push(i.quality_tag);
    }
    return tagArr.join(' , ');
};

const EditCustomer = ({ isOpen, setIsOpen, title, data, section }) => {
    useEffect(() => {
        return () => {
            resetContact();
        };
    }, []);
    const { quality, loading } = useSelector(getQuality);

    const qualityMemo = useMemo(
        () => (quality.docs ? quality.docs : []),
        [quality]
    );
    const computeInitialValue = (data) => {
        const obj = { ...data };

        if (data?.cf_title?.length) {
            const qualityTagArr = obj.cf_title.split(' , ');
            let qualityDetailsArr = [];
            for (let tag of qualityTagArr) {
                console.log(tag);
                let checkTag = qualityMemo.find((ele) => ele.tag == tag);
                console.log(checkTag);
                if (checkTag) {
                    qualityDetailsArr.push(checkTag);
                }
            }
            console.log('quality_details=>', qualityDetailsArr);
            obj.quality_details = qualityDetailsArr;

            obj.quality_details = obj.quality_details.map((ele) => {
                const { name, tag, type, ...rest } = ele;
                const obj = {
                    quality_name: name,
                    quality_tag: tag,
                    type: type,
                };
                const json = JSON.stringify(obj);

                return { quality_name: json, quality_tag: tag, type, ...rest };
            });
        }
        if (data?.cf_chalan_name?.length > 0 && data?.cf_chalan_name != '0') {
            obj['chalan_name'] = data.cf_chalan_name.split(',');
        }
        if (data?.cf_chalan_name_1?.length > 0) {
            obj['chalan_name_1'] = data.cf_chalan_name_1.split(',');
        }
        return obj;
    };

    const { contact, updateLoading: contactLoading } = useSelector(getCustomer);
    const dispatch = useDispatch();
    const [qualityTagLoading, setQualityTagLoading] = useState(false);
    const { yarnGrade, loading: yarnGradeLoading } = useSelector(getYarnGrade);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: computeInitialValue(contact?.contact),
        onSubmit: async (values, { resetForm }) => {
            let valuesCopy = { ...values };
            const parsedQualityDetails = valuesCopy.quality_details?.map(
                (ele) => {
                    const parsedJson = JSON.parse(ele.quality_name);
                    const { quality_name, quality_tag, type, _id, ...rest } =
                        ele;
                    const qualityObj = {
                        quality_name: parsedJson.quality_name,
                        quality_tag: parsedJson.quality_tag,
                        type: parsedJson.type,
                        ...rest,
                    };
                    return qualityObj;
                }
            );
            valuesCopy['quality_details'] = parsedQualityDetails;

            // await dispatch(updateCustomer(valuesCopy));

            const updatedObj = {
                id: valuesCopy.contact_id,
                // first_name: valuesCopy.first_name,
                // last_name: valuesCopy.last_name,
                notes: valuesCopy.notes,
                payment_terms: valuesCopy.payment_terms,
                billing_address: { ...valuesCopy.billing_address },
                shipping_address: { ...valuesCopy.shipping_address },
                gst_no: valuesCopy.gst_no,
                contact_persons: valuesCopy.contact_persons.map((ele) => ({
                    first_name: ele.first_name,
                    last_name: ele.last_name,
                    phone: ele?.phone,
                    mobile: ele.mobile,
                })),

                custom_fields: [
                    {
                        customfield_id:
                            customIDs.customer_group_wise_billing_mode,
                        value: valuesCopy.cf_groupwise_billing_mode,
                    },
                    {
                        customfield_id:
                            customIDs.customer_quarterly_brokerage_mode,
                        value: valuesCopy.cf_quarterly_brockrege_mode,
                    },
                    {
                        customfield_id: customIDs.customer_yarn_grade,
                        value: valuesCopy.yarn_grade,
                    },
                    {
                        customfield_id: customIDs.customer_birthday,
                        value: moment(valuesCopy.birthday).format('YYYY-MM-DD'),
                    },
                    {
                        customfield_id: customIDs.customer_update_date,
                        value: moment().format('YYYY-MM-DD'),
                    },
                    {
                        customfield_id:
                            customIDs.customer_manager_mobile_number,
                        value: valuesCopy.cf_manager_mobile_number,
                    },
                ],
            };

            if (valuesCopy?.quality_details?.length) {
                updatedObj.custom_fields.push({
                    customfield_id: customIDs.customer_title,
                    value: computeQualityTagString(valuesCopy?.quality_details),
                });
            }
            // data is coming as props
            if (data?.cf_calling) {
                updatedObj.custom_fields.push({
                    customfield_id: customIDs.customer_calling,
                    value: data?.cf_calling,
                });
            }
            if (valuesCopy.chalan_name?.length) {
                updatedObj.custom_fields.push({
                    customfield_id: customIDs.customer_chalan_name,
                    value: valuesCopy.chalan_name.join(' , '),
                });
            }
            if (valuesCopy?.chalan_name_1?.length) {
                updatedObj.custom_fields.push({
                    customfield_id: customIDs.customer_chalan_name1,
                    value: valuesCopy.chalan_name_1.join(' , '),
                });
            }
            setQualityTagLoading(true);
            await dispatch(updateCustomer(updatedObj)).then((resp) =>
                resp.payload?.contact
                    ? toast.success('Data Updated in Zoho')
                    : toast.error('Failed to update in zoho')
            );
            await dispatch(fetchContact({ id: data.contact_id })).then(
                (resp) => {
                    formik.setFieldValue(
                        'QualityTag',
                        resp.payload?.contact.cf_title
                    );
                    setQualityTagLoading(false);
                }
            );
            resetForm();
            setIsOpen(false);
        },
    });
    useEffect(() => {
        setQualityTagLoading(true);
        if (isOpen) {
            // dispatch(fetchContact({ contact_id: data.contact_id }));
            dispatch(fetchContact({ id: data.contact_id }));
        }
    }, [data]);
    return (
        <ModalBasic
            title={title}
            modalOpen={isOpen}
            setModalOpen={setIsOpen}
            max_width="max-w-4xl"
        >
            <div className="m-4">
                {contactLoading ? (
                    <ClipLoader />
                ) : (
                    <form onSubmit={formik.handleSubmit}>
                        {(section.includes('company_name') ||
                            section.includes('all')) && (
                            <>
                                <FormikInputGroup
                                    formik={formik}
                                    name={'contact_id'}
                                    label="Contact Id"
                                    readOnly
                                />
                                <FormikInputGroup
                                    formik={formik}
                                    name={'company_name'}
                                    label="Company Name"
                                    readOnly
                                />
                                {/* <FormikSelectGroup
                  formik={formik}
                  name={"priority"}
                  label="Priority"
                  options={PRIORITY_OPTIONS}
                /> */}
                            </>
                        )}
                        {(section.includes('vendor_name') ||
                            section.includes('all')) && (
                            <>
                                <FormikInputGroup
                                    formik={formik}
                                    name={'contact_persons.0.first_name'}
                                    label="First Name"
                                />
                                <FormikInputGroup
                                    formik={formik}
                                    name={'contact_persons.0.last_name'}
                                    label="Last Name"
                                />
                            </>
                        )}
                        {(section.includes('phone') ||
                            section.includes('all')) && (
                            <>
                                <FormikInputGroup
                                    formik={formik}
                                    name={'contact_persons.0.mobile'}
                                    label="Mobile"
                                />
                                <FormikInputGroup
                                    formik={formik}
                                    name={'contact_persons.0.phone'}
                                    label="Work"
                                />
                                <FormikInputGroup
                                    formik={formik}
                                    name={'cf_manager_mobile_number'}
                                    label="Manager Mobile Number"
                                />
                            </>
                        )}

                        {section.includes('all') && (
                            <>
                                <hr className="border-1 my-4" />

                                <FormikInputGroup
                                    name={'payment_terms'}
                                    formik={formik}
                                    label="Payment terms"
                                />
                                <FormikInputGroup
                                    name={'gst_no'}
                                    formik={formik}
                                    label="GST No."
                                />
                                <FormikInputGroup
                                    name={'billing_address.address'}
                                    formik={formik}
                                    label="Billing Address."
                                />
                                <FormikInputGroup
                                    name={'shipping_address.address'}
                                    formik={formik}
                                    label="Shipping Address."
                                />
                                <FormikInputGroup
                                    name={'cf_quarterly_brockrege_mode'}
                                    formik={formik}
                                    label="quarterly brokerage mode."
                                />
                                <FormikInputGroup
                                    name={'cf_groupwise_billing_mode'}
                                    formik={formik}
                                    label="Groupwise billing mode"
                                />

                                <FormikProvider value={formik}>
                                    <ShowDropdown
                                        heading={'Contact person'}
                                        allowedRoles={['admin']}
                                    >
                                        <FieldArray
                                            name="contact_persons"
                                            render={(arrayHelpers) => (
                                                <>
                                                    {/* <label>Contact Person</label> */}
                                                    <div className="flex flex-col ">
                                                        {formik.values.contact_persons?.map(
                                                            (ele, index) =>
                                                                index > 0 && (
                                                                    <div
                                                                        key={
                                                                            index
                                                                        }
                                                                        className="flex items-center gap-2 "
                                                                    >
                                                                        <FormikInputGroup
                                                                            formik={
                                                                                formik
                                                                            }
                                                                            name={`contact_persons.${index}.first_name`}
                                                                            label={
                                                                                'Contact person first name' +
                                                                                index
                                                                            }
                                                                        />
                                                                        <FormikInputGroup
                                                                            formik={
                                                                                formik
                                                                            }
                                                                            name={`contact_persons.${index}.last_name`}
                                                                            label={
                                                                                'Contact person last name' +
                                                                                index
                                                                            }
                                                                        />
                                                                        <FormikInputGroup
                                                                            formik={
                                                                                formik
                                                                            }
                                                                            name={`contact_persons.${index}.mobile`}
                                                                            label={
                                                                                'Contact person mobile' +
                                                                                index
                                                                            }
                                                                        />
                                                                        <FormikInputGroup
                                                                            formik={
                                                                                formik
                                                                            }
                                                                            name={`contact_persons.${index}.phone`}
                                                                            label={
                                                                                'Contact person phone' +
                                                                                index
                                                                            }
                                                                        />
                                                                        <X
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                e.stopPropagation();
                                                                                arrayHelpers.remove(
                                                                                    index
                                                                                );
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )
                                                        )}
                                                        <div>
                                                            <PrimaryButton
                                                                onClick={() =>
                                                                    arrayHelpers.push(
                                                                        null
                                                                    )
                                                                }
                                                            >
                                                                Add more
                                                            </PrimaryButton>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        />
                                    </ShowDropdown>

                                    <ShowDropdown
                                        heading={'Challan details'}
                                        allowedRoles={['admin']}
                                        color={
                                            !formik.values.cf_chalan_name_1
                                                ?.length &&
                                            formik.values.cf_chalan_name == '0'
                                                ? 'red'
                                                : 'black'
                                        }
                                    >
                                        <FieldArray
                                            name="chalan_name"
                                            render={(arrayHelpers) => (
                                                <>
                                                    <label className="text-sm font-medium mb-1">
                                                        CHALAN NAME
                                                    </label>
                                                    <div className="flex items-center flex-wrap gap-4">
                                                        {formik.values.chalan_name?.map(
                                                            (ele, index) => (
                                                                <div
                                                                    key={index}
                                                                    className="flex items-center "
                                                                >
                                                                    <FormikInputGroup
                                                                        formik={
                                                                            formik
                                                                        }
                                                                        name={`chalan_name.${index}`}
                                                                        label={
                                                                            'name' +
                                                                            index
                                                                        }
                                                                    />
                                                                    <X
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            e.stopPropagation();
                                                                            arrayHelpers.remove(
                                                                                index
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        )}
                                                        <div>
                                                            <PrimaryButton
                                                                onClick={() =>
                                                                    arrayHelpers.push(
                                                                        null
                                                                    )
                                                                }
                                                            >
                                                                Add more
                                                            </PrimaryButton>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        />
                                        <hr className="border-1 my-4" />
                                        <FieldArray
                                            name="chalan_name_1"
                                            render={(arrayHelpers) => (
                                                <>
                                                    <label className="text-sm font-medium mb-1">
                                                        CHALAN NAME 1{' '}
                                                    </label>
                                                    <div className="flex items-center flex-wrap gap-4">
                                                        {formik.values.chalan_name_1?.map(
                                                            (ele, index) => (
                                                                <div
                                                                    key={index}
                                                                    className="flex items-center "
                                                                >
                                                                    <FormikInputGroup
                                                                        formik={
                                                                            formik
                                                                        }
                                                                        name={`chalan_name_1.${index}`}
                                                                        label={
                                                                            'name' +
                                                                            index
                                                                        }
                                                                    />
                                                                    <X
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            e.stopPropagation();
                                                                            arrayHelpers.remove(
                                                                                index
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        )}
                                                        <div>
                                                            <PrimaryButton
                                                                onClick={() =>
                                                                    arrayHelpers.push(
                                                                        null
                                                                    )
                                                                }
                                                            >
                                                                Add more
                                                            </PrimaryButton>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        />
                                    </ShowDropdown>
                                </FormikProvider>
                                <hr className="border-1 my-4" />
                                {
                                    <FormikInputGroup
                                        formik={formik}
                                        name={'cf_title'}
                                        label="What quality they make? (ZOHO)"
                                        readOnly
                                    />
                                }
                                <FormikProvider value={formik}>
                                    <FieldArray
                                        name="quality_details"
                                        render={(arrayHelpers) => (
                                            <>
                                                <h1 className="text-sm font-medium mb-1">
                                                    QUALITY DETAILS
                                                </h1>
                                                {formik.values?.quality_details?.map(
                                                    (ele, index) => (
                                                        <div
                                                            className=" my-4 relative"
                                                            key={index}
                                                        >
                                                            <XSquare
                                                                className="absolute top-0 right-0"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation();
                                                                    arrayHelpers.remove(
                                                                        index
                                                                    );
                                                                }}
                                                            />
                                                            <div className="grid grid-cols-3 gap-2">
                                                                <FormikSelectGroup
                                                                    formik={
                                                                        formik
                                                                    }
                                                                    name={`quality_details.${index}.quality_name`}
                                                                    label={
                                                                        'Select Quality' +
                                                                        index
                                                                    }
                                                                    options={qualityMemo.map(
                                                                        (
                                                                            ele
                                                                        ) => ({
                                                                            label: ele.name,
                                                                            value: JSON.stringify(
                                                                                {
                                                                                    quality_name:
                                                                                        ele?.name,
                                                                                    quality_tag:
                                                                                        ele?.tag,
                                                                                    type: ele?.type,
                                                                                }
                                                                            ),
                                                                        })
                                                                    )}
                                                                    onChange={(
                                                                        selectedOption
                                                                    ) => {
                                                                        const {
                                                                            quality_tag,
                                                                            type,
                                                                        } =
                                                                            JSON.parse(
                                                                                selectedOption.value
                                                                            );
                                                                        formik.setFieldValue(
                                                                            `quality_details.${index}`,
                                                                            {
                                                                                quality_tag,
                                                                                type,
                                                                            }
                                                                        );
                                                                        formik.setFieldValue(
                                                                            `quality_details.${index}.quality_name`,
                                                                            selectedOption.value
                                                                        );
                                                                    }}
                                                                />

                                                                <FormikInputGroup
                                                                    formik={
                                                                        formik
                                                                    }
                                                                    name={`quality_details.${index}.quality_tag`}
                                                                    label="Quality Tag"
                                                                    readOnly
                                                                />
                                                                <FormikInputGroup
                                                                    formik={
                                                                        formik
                                                                    }
                                                                    name={`quality_details.${index}.type`}
                                                                    label="type"
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                                <div>
                                                    <PrimaryButton
                                                        onClick={() =>
                                                            arrayHelpers.push(
                                                                null
                                                            )
                                                        }
                                                    >
                                                        Add more
                                                    </PrimaryButton>
                                                </div>
                                            </>
                                        )}
                                    />
                                </FormikProvider>
                                <hr className="border-1 my-4" />

                                <FormikInputDateGroup
                                    formik={formik}
                                    name={'birthday'}
                                    label="birthday"
                                />
                                <FormikInputGroup
                                    formik={formik}
                                    name={'notes'}
                                    label="Remarks"
                                />
                            </>
                        )}
                        <div>
                            <PrimaryButton type="submit">Submit</PrimaryButton>
                        </div>
                    </form>
                )}
            </div>
        </ModalBasic>
    );
};

export default EditCustomer;
