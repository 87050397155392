import { createSlice } from '@reduxjs/toolkit';
import { generateSetEditId } from '../../generators/generateReducers';
import { generateStateSingle } from '../../generators/generateState';
import {
    editData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';
const SLICE_NAME = 'vendors';

export const fetchVendors = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchVendor = fetchEditData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editVendor = editData.generateThunk(SLICE_NAME, SLICE_NAME);

const vendorSlice = createSlice({
    name: SLICE_NAME,
    initialState: generateStateSingle(SLICE_NAME),
    reducers: {
        setEditId: generateSetEditId(),
    },
    extraReducers: {
        ...fetchData.generateExtraReducers(fetchVendors, SLICE_NAME),
        ...editData.generateExtraReducers(editVendor, SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchVendor, SLICE_NAME),
    },
});

export const getVendor = (state) => state.vendors;
export const { setEditId } = vendorSlice.actions;
export default vendorSlice.reducer;
