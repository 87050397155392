import React, { useEffect } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { getPrint } from '../../app/reducers/Print/printSlice';
import { ClipLoader } from 'react-spinners';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { PRINT_OPTIONS } from '../../utils/dropdownOptions';

const PrinterSelect = ({
    printerSelectModalOpen,
    setPrinterSelectModalOpen,
    setSelectedPrinter,
    selectedPrinter,
    selectedRows,
    printInvoices,
    printOption,
    setPrintOption,
}) => {
    const { loading, printers } = useSelector(getPrint);

    useEffect(() => {
        setSelectedPrinter(
            printers.map((ele) => ({
                label: `${ele.computer.name}/${ele.name}`,
                value: ele.id,
            }))[0]
        );
    }, [JSON.stringify(printers)]);

    return (
        <ModalBasic
            modalOpen={printerSelectModalOpen}
            setModalOpen={setPrinterSelectModalOpen}
            title={'Select Printer'}
        >
            <div
                className="p-4 h-[400px] flex flex-col gap-4"
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <div className="mb-4">Print {selectedRows.length} Items</div>
                {loading ? (
                    <ClipLoader />
                ) : (
                    <>
                        <Select
                            options={printers.map((ele) => ({
                                label: `${ele.computer.name}/${ele.name}`,
                                value: ele.id,
                            }))}
                            value={selectedPrinter}
                            onChange={(selectedOption) => {
                                setSelectedPrinter(selectedOption);
                            }}
                        />
                        <Select
                            options={PRINT_OPTIONS}
                            value={printOption}
                            onChange={(selectedOption) => {
                                setPrintOption(selectedOption);
                            }}
                        />
                        <div>
                            <PrimaryButton
                                onClick={printInvoices}
                                type="button"
                            >
                                Print
                            </PrimaryButton>
                        </div>
                    </>
                )}
            </div>
        </ModalBasic>
    );
};

export default PrinterSelect;
