import React from 'react';
import Select from 'react-select';
import _ from 'lodash';

const computeValue = (options, val) => {
    console.log('options');
    console.log(options);
    console.log('val');
    console.log(val);
    let resp = options.filter((option) => val?.includes(option.value));
    return resp ? resp : null;
};
const FormikMultiSelect = ({
    formik,
    label = '',
    required = false,
    options,
    name,
    ...props
}) => {
    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
            }}
            className="w-full"
        >
            <label className="block text-sm font-medium mb-1" htmlFor={name}>
                {label} {required && <span className="text-rose-500">*</span>}
            </label>
            <Select
                name={name}
                defaultValue={computeValue(
                    options,
                    _.at(formik.values, name)[0]
                )}
                options={options}
                onBlur={formik.handleBlur}
                onChange={(selectedOption) => {
                    const values = selectedOption.map((option) => option.value);
                    formik.setFieldValue(name, values);
                }}
                isMulti
                {...props}
            />
            {formik?.errors?.[name]?.value && formik?.touched?.[name] ? (
                <p className="text-xs text-red-500">
                    {formik.errors[name].value}
                </p>
            ) : null}
        </div>
    );
};

export default FormikMultiSelect;
