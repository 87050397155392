import React, { useEffect } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchContacts,
    getContacts,
} from '../../app/reducers/Contacts/contactsSlice';
import { ClipLoader } from 'react-spinners';
import { placeCall } from '../../app/reducers/Callling/callingSlice';
import { toast } from 'react-toastify';

const ContactModal = ({
    contactModalOpen,
    setContactModalOpen,
    contactDetails,
    ...props
}) => {
    const dispatch = useDispatch();
    const { loading, contacts } = useSelector(getContacts);
    const computeTitle = () => {
        let output = '';
        if (loading) {
            output = 'Loading...';
        } else {
            output = contacts?.contacts
                ?.map((ele) => ele.contact_name)
                .join(', ');
        }
        return output;
    };
    useEffect(() => {
        if (!_.isEmpty(contactDetails)) {
            dispatch(
                fetchContacts({
                    search: contactDetails.name,
                    contact_type: contactDetails.contact_type,
                })
            );
        }
    }, [JSON.stringify(contactDetails)]);
    return (
        <ModalBasic
            modalOpen={contactModalOpen}
            setModalOpen={setContactModalOpen}
            title={computeTitle()}
            {...props}
        >
            {loading ? (
                <ClipLoader />
            ) : (
                <div className="flex flex-col gap-5 p-4">
                    {contacts?.contacts?.map?.((ele) => (
                        <div className="flex gap-4 flex-col">
                            <span>Name : {ele?.first_name}</span>
                            <span
                                className="cursor-pointer"
                                // onClick={() => {

                                //   dispatch(placeCall({ phone: ele.phone }));
                                // }}
                                onClick={() => {
                                    navigator.clipboard.writeText(ele.phone);
                                    toast.info('phone number copied');
                                }}
                            >
                                Phone : {ele.phone}
                            </span>
                            <span
                                className="cursor-pointer"
                                onClick={() => {
                                    navigator.clipboard.writeText(ele.mobile);
                                    toast.info('mobile number copied');
                                }}
                                // onClick={() => {
                                //   dispatch(placeCall({ phone: ele.mobile }));
                                // }}
                            >
                                Mobile : {ele.mobile}
                            </span>
                        </div>
                    ))}
                </div>
            )}
        </ModalBasic>
    );
};

export default ContactModal;
