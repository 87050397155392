import axios from 'axios';
import { toast } from 'react-toastify';
import { logout } from '../app/reducers/Auth/authSlice';
import { getLocal, setLocal } from '../Common/Stores';

let store;
export const injectStore = (_store) => {
    store = _store;
};

export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

export const authAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    // timeout: 1000,
});

// Add a request interceptor
authAxiosInstance.interceptors.request.use(function (config) {
    const token = getLocal('access_token');
    config.headers.Authorization = `Bearer ${token}`;
    return config;
});

// Add a response interceptor || Middleware for 401
authAxiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        const {
            config,
            response: { status },
        } = error;
        if (status === 401) {
            localStorage.removeItem('access_token');
            console.log(error);
            if (error?.response?.data?.message) {
                toast.error(error.response.data.message);
            }
            store.dispatch(logout());
        } else if (status === 403) {
            console.log('unauthorized user');
            toast.error('unauthorized user');
        } else {
            console.log('auth axios else');
            return Promise.reject(error);
        }
    }
);
