import { useDispatch, useSelector } from 'react-redux';
import Tab from '../components/infrastructure/Tabs/Tab';
import Tabs from '../components/infrastructure/Tabs/Tabs';
import { useEffect, useState } from 'react';
import TableWithHeadingAndSearch from '../components/Table/TableWithHeadingAndSearch';
import {
    fetchCustomers,
    fetchVendorsFromZoho,
    getCustomer,
} from '../app/reducers/Customer/customerSlice';
import { useMemo } from 'react';
import { ClipLoader } from 'react-spinners';
import { Edit2 } from 'react-feather';
import EditCustomer from './EditCustomer';
import { customIDs } from '../utils/customIds';

const DashboardOld = () => {
    const dispatch = useDispatch();
    const { customer_calling } = customIDs;
    const [index, setIndex] = useState(0);
    const [editData, setData] = useState({});
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        dispatch(
            fetchCustomers({
                contact_type: 'customer',
                [`custom_field_${customer_calling}_contains`]: 0,
            })
        );
        dispatch(
            fetchVendorsFromZoho({
                contact_type: 'vendor',
                [`custom_field_${customer_calling}_contains`]: 0,
            })
        );
    }, []);
    const { customers, vendorsFromZoho, loading } = useSelector(getCustomer);

    // const makeCall = async (row) => {
    //   // let data = {
    //   //   id: row.contact_id,
    //   //   custom_fields: [...row.custom_fields, {
    //   //     "customfield_id": "466597000018024126",
    //   //     "value": "1"
    //   //   }]
    //   // }
    //   // const action = await dispatch(updateCustomer(data))
    //   // if(action.payload) {
    //   //   if(index == 1) {
    //   //     dispatch(fetchCustomers({ contact_type: "customer",custom_field_466597000018024126_contains:0 }));
    //   //   }else{
    //   //     dispatch(fetchVendors({ contact_type: "vendor",custom_field_466597000018024126_contains:0 }));
    //   //   }
    //   // }
    // }

    const cols = [
        {
            Header: 'SRNO',
            Cell: ({ row, data }) => {
                return row.index + 1;
            },
        },
        {
            Header: 'Name',
            accessor: 'contact_name',
        },
        {
            Header: 'Company Name',
            accessor: 'company_name',
        },
        {
            Header: 'Action',
            Cell: ({ row, data }) => {
                return (
                    <>
                        <Edit2
                            color="blue"
                            className="cursor-pointer"
                            onClick={(e) => {
                                e.stopPropagation();
                                setData({
                                    id: row.original.contact_id,
                                    index: index,
                                });
                                setModalOpen(true);
                            }}
                        >
                            Call
                        </Edit2>
                    </>
                );
            },
        },
    ];

    // console.log(custom)
    const customerData = useMemo(
        () => (customers?.contacts ? customers.contacts.slice(0, 5) : []),
        [customers]
    );

    const vendorData = useMemo(
        () =>
            vendorsFromZoho?.contacts
                ? vendorsFromZoho.contacts.slice(0, 5)
                : [],
        [vendorsFromZoho]
    );

    return (
        <>
            <EditCustomer
                title={'Edit Contact'}
                setPurchaseModal={setModalOpen}
                viewPurchaseModal={modalOpen}
                data={editData}
            />
            <div className="p-4" style={{ overflow: 'hidden' }}>
                <Tabs
                    headers={['Vendor', 'Customer']}
                    onTabChange={(index) => {
                        setIndex(index);
                    }}
                >
                    <Tab>
                        {loading ? (
                            <ClipLoader />
                        ) : (
                            <TableWithHeadingAndSearch
                                heading={'Vendor'}
                                data={vendorData}
                                loading={loading}
                                columns={cols}
                            />
                        )}
                    </Tab>
                    <Tab>
                        {loading ? (
                            <ClipLoader />
                        ) : (
                            <TableWithHeadingAndSearch
                                heading={'Customer'}
                                data={customerData}
                                loading={loading}
                                columns={cols}
                            />
                        )}
                    </Tab>
                </Tabs>
            </div>
        </>
    );
};

export default DashboardOld;
