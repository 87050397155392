import React, { useEffect } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
    createFeature,
    editFeature,
    fetchFeatures,
    getFeatures,
} from '../../app/reducers/Features/featureSlice';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import { ClipLoader } from 'react-spinners';

const Features = () => {
    const dispatch = useDispatch();
    const { features, loading, editDataLoading } = useSelector(getFeatures);
    useEffect(() => {
        dispatch(fetchFeatures());
    }, []);
    const cols = [
        { Header: 'System Id', accessor: '_id' },
        { Header: 'Name', accessor: 'name' },
        {
            Header: 'Action',
            Cell: ({ row }) => {
                if (loading || editDataLoading) {
                    return <ClipLoader />;
                }
                return (
                    <div className="flex">
                        {row?.original?.status === 'active' ? (
                            <div>
                                <DangerButton
                                    onClick={async () => {
                                        await dispatch(
                                            editFeature({
                                                _id: row.original._id,
                                                status: 'inactive',
                                            })
                                        );
                                        dispatch(fetchFeatures());
                                    }}
                                >
                                    Deactivate Automation
                                </DangerButton>
                            </div>
                        ) : (
                            <div>
                                <SecondaryButton
                                    onClick={async () => {
                                        await dispatch(
                                            editFeature({
                                                _id: row.original._id,
                                                status: 'active',
                                            })
                                        );
                                        dispatch(fetchFeatures());
                                    }}
                                >
                                    Activate Automation
                                </SecondaryButton>
                            </div>
                        )}
                    </div>
                );
            },
        },
    ];
    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validationSchema: Yup.object({
            name: Yup.string(),
        }),
        onSubmit: async (values) => {
            await dispatch(createFeature(values));
            dispatch(fetchFeatures());
        },
    });
    return (
        <PageWithCard heading="Features">
            <div className="flex flex-col gap-4">
                <form
                    className="flex flex-col gap-4"
                    onSubmit={formik.handleSubmit}
                >
                    <FormikInputGroup
                        label="Name"
                        formik={formik}
                        required
                        name={'name'}
                    />
                    <div>
                        <PrimaryButton
                            type="submit"
                            disabled={formik.isSubmitting}
                        >
                            Submit
                        </PrimaryButton>
                    </div>
                </form>
                <TableWithHeadingAndSearch
                    columns={cols}
                    data={features?.docs ? features?.docs : []}
                    heading={'Features'}
                />
            </div>
        </PageWithCard>
    );
};

export default Features;
