import React from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useFormik } from 'formik';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import moment from 'moment';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { toast } from 'react-toastify';

const EditFollowUpDate = ({ isOpen, setIsOpen, data }) => {
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            follow_up_date: data.cf_po_followup_due_date_12d,
        },
        onSubmit: async (values) => {
            console.log(values);
            let payload = {
                date: moment(values.follow_up_date).format('YYYY-MM-DD'),
                purchaseOrder_id: data.purchaseorder_id,
            };
            const resp = await authAxiosInstance
                .put(`/customerPreference/updateFollowupDate`, payload)
                .then((res) => {
                    toast.success('Follow up updated');
                    setIsOpen(false);
                })
                .catch((e) => toast.error('Error updating follow up'));
            console.log('update date resp', resp);
        },
    });
    return (
        <ModalBasic
            modalOpen={isOpen}
            setModalOpen={setIsOpen}
            title={'EDIT FOLLOW UP DATE'}
        >
            <form
                onSubmit={formik.handleSubmit}
                className="m-4 flex flex-col gap-2"
            >
                <FormikInputDateGroup
                    name={'follow_up_date'}
                    label="Follow up date"
                    formik={formik}
                />
                <div>
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                </div>
            </form>
        </ModalBasic>
    );
};

export default EditFollowUpDate;
