import { createSlice } from '@reduxjs/toolkit';
import { generateSetEditId } from '../../generators/generateReducers';
import { generateStateSingle } from '../../generators/generateState';
import {
    createData,
    deleteData,
    editData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';
const SLICE_NAME = 'reminderSubject';

export const fetchReminderSubjects = fetchData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const editReminderSubject = editData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const fetchReminderSubject = fetchEditData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const deleteReminderSubject = deleteData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const createReminderSubject = createData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);

const reminderSubjectSlice = createSlice({
    name: SLICE_NAME,
    initialState: generateStateSingle(SLICE_NAME),
    reducers: {
        setEditId: generateSetEditId(),
    },
    extraReducers: {
        ...fetchData.generateExtraReducers(fetchReminderSubjects, SLICE_NAME),
        ...createData.generateExtraReducers(createReminderSubject, SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteReminderSubject, SLICE_NAME),
        ...fetchEditData.generateExtraReducers(
            fetchReminderSubject,
            SLICE_NAME
        ),
        ...editData.generateExtraReducers(editReminderSubject, SLICE_NAME),
    },
});

export const getReminderSubject = (state) => state.reminderSubject;
export const { setEditId } = reminderSubjectSlice.actions;
export default reminderSubjectSlice.reducer;
