import React, { useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { ClipLoader } from 'react-spinners';
import { customIDs } from '../../utils/customIds';
import moment from 'moment';
import { toast } from 'react-toastify';
import { authAxiosInstance } from '../../utils/axiosConfig';

const TriggerAutomation = () => {
    const [loading, setLoading] = useState(false);
    const triggerAutomation = async (values) => {
        try {
            const confirm = window.confirm(
                'Are you sure you want to trigger invoice automation'
            );
            if (!confirm) {
                toast.info('Operation canceled');
                return;
            }

            setLoading(true);
            const resp = await authAxiosInstance.post(
                '/zoho/generate-report',
                values
            );
            console.log(resp.data);
            const automationStatus = resp?.data?.automationStatus;
            if (automationStatus === 'inactive') {
                toast.error('Automation Deactivated', {
                    position: 'top-center',
                    autoClose: false,
                });
                throw new Error('Automation Deactivated');
            }
            const customerLength = parseFloat(resp?.data?.customerLength);
            const startDate = resp?.data?.from
                ? moment(resp.data.from, 'YYYY-MM-DD').format('DD-MM-YYYY')
                : null;
            const endDate = resp?.data?.to
                ? moment(resp.data.to, 'YYYY-MM-DD').format('DD-MM-YYYY')
                : null;

            if (customerLength) {
                const totalHours = parseFloat(
                    (1000 * 60 * 3 * customerLength) / (60 * 60 * 1000)
                ).toFixed(2);
                toast.success(
                    `started generating invoices for ${customerLength} customers, expected time ${totalHours} Hours PO`,
                    { position: 'top-center', autoClose: false }
                );
                toast.success(
                    `PO Start Date :  ${startDate} endDate ${endDate} `,
                    { position: 'top-center', autoClose: false }
                );
            } else {
                toast.warn('customer length not a number');
                toast.success('Invoice Generation started');
            }
        } catch (error) {
            console.log(error);
            toast.error('Error Generating please contact developer');
        } finally {
            setLoading(false);
        }
    };
    return (
        <PageWithCard heading="Trigger Automation">
            {loading ? (
                <ClipLoader />
            ) : (
                <div className="flex flex-col gap-8">
                    <div className="flex gap-3">
                        <PrimaryButton
                            onClick={() => {
                                triggerAutomation({
                                    brokerage: 3,
                                    start: '01-01',
                                    startYear: 'current',

                                    to: '31-03',
                                    endYear: 'current',

                                    oldCredit: 'cf_credit_score_fy_q3',
                                    newCredit: customIDs.credit_q4,
                                    group_a_date: moment().format('DD-MM'),
                                    group_b_date: moment().format('DD-MM'),
                                    invoiceYear: 'current',
                                    printerId: 72705643,
                                });
                            }}
                        >
                            Jan To March Customer (3)
                        </PrimaryButton>
                        <PrimaryButton
                            onClick={() => {
                                triggerAutomation({
                                    brokerage: 6,
                                    start: '01-10',
                                    startYear: 'previous',

                                    to: '31-03',
                                    endYear: 'current',

                                    oldCredit: 'cf_credit_score_fy_q2',
                                    newCredit: customIDs.credit_q4,
                                    group_a_date: moment().format('DD-MM'),
                                    group_b_date: moment().format('DD-MM'),
                                    invoiceYear: 'current',
                                    printerId: 72705643,
                                });
                            }}
                        >
                            October To March Customer (6)
                        </PrimaryButton>
                        <PrimaryButton
                            onClick={() => {
                                triggerAutomation({
                                    brokerage: 12,
                                    start: '01-04',
                                    startYear: 'previous',

                                    to: '31-03',
                                    endYear: 'current',

                                    oldCredit: 'cf_q1',
                                    newCredit: customIDs.credit_q1,
                                    group_a_date: moment().format('DD-MM'),
                                    group_b_date: moment().format('DD-MM'),
                                    invoiceYear: 'current',
                                    printerId: 72705643,
                                });
                            }}
                        >
                            April To March Customer (12)
                        </PrimaryButton>
                    </div>
                    <div className="flex gap-3">
                        <PrimaryButton
                            onClick={() => {
                                triggerAutomation({
                                    brokerage: 3,
                                    start: '01-04',
                                    startYear: 'current',

                                    to: '30-06',
                                    endYear: 'current',

                                    oldCredit: 'cf_credit_score_fy_q4',
                                    newCredit: customIDs.credit_q1,
                                    group_a_date: moment().format('DD-MM'),
                                    group_b_date: moment().format('DD-MM'),
                                    invoiceYear: 'current',
                                    printerId: 72705643,
                                });
                            }}
                        >
                            April To June Customer (3)
                        </PrimaryButton>
                    </div>
                    <div className="flex gap-3">
                        <PrimaryButton
                            onClick={() => {
                                triggerAutomation({
                                    brokerage: 3,
                                    start: '01-07',
                                    startYear: 'current',

                                    to: '30-09',
                                    endYear: 'current',

                                    oldCredit: 'cf_q1',
                                    newCredit: customIDs.credit_q2,
                                    group_a_date: moment().format('DD-MM'),
                                    group_b_date: moment().format('DD-MM'),
                                    invoiceYear: 'current',
                                    printerId: 72705643,
                                });
                            }}
                        >
                            July to September Customer (3)
                        </PrimaryButton>
                        <PrimaryButton
                            onClick={() => {
                                triggerAutomation({
                                    brokerage: 6,
                                    start: '01-04',
                                    startYear: 'current',

                                    to: '30-09',
                                    endYear: 'current',

                                    oldCredit: 'cf_credit_score_fy_q4',
                                    newCredit: customIDs.credit_q2,
                                    group_a_date: moment().format('DD-MM'),
                                    group_b_date: moment().format('DD-MM'),
                                    invoiceYear: 'current',
                                    printerId: 72705643,
                                });
                            }}
                        >
                            April To September Customer(6)
                        </PrimaryButton>
                    </div>
                    <div className="flex gap-3">
                        <PrimaryButton
                            onClick={() => {
                                triggerAutomation({
                                    brokerage: 3,
                                    start: '01-10',
                                    startYear: 'current',

                                    to: '31-12',
                                    endYear: 'current',

                                    oldCredit: 'cf_credit_score_fy_q2',
                                    newCredit: customIDs.credit_q3,
                                    group_a_date: moment().format('DD-MM'),
                                    group_b_date: moment().format('DD-MM'),
                                    invoiceYear: 'current',
                                    printerId: 72705643,
                                });
                            }}
                        >
                            October To December Customer (3)
                        </PrimaryButton>
                    </div>
                </div>
            )}
        </PageWithCard>
    );
};

export default TriggerAutomation;
