import { Link, useNavigate, useParams } from 'react-router-dom';
import PageWithCard from '../components/infrastructure/PageWithCard';
import { useEffect, useMemo } from 'react';
import {
    fetchQualities,
    getQuality,
} from '../app/reducers/Quality/qualitySlice';
import { useDispatch, useSelector } from 'react-redux';
import QualitySwatch from './ViewVendor/QualitySwatch';
import base64 from 'base-64';
import { Search } from 'react-feather';
import { useCallback } from 'react';
import SecondaryButton from '../components/infrastructure/Buttons/SecondaryButton';
const encodeQualityName = (qualityName) => {
    return base64.encode(qualityName);
};

function QualityType() {
    const param = useParams();
    const { quality, loading: QualityLoading } = useSelector(getQuality);
    const qualityMemo = useMemo(() => quality.docs ?? [], [quality.docs]);
    console.log(param.qualityType);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(
            fetchQualities({
                type: param.qualityType,
                sort: { priority: -1 },
                limit: 300,
            })
        );
    }, []);

    const handleDebouncedSearch = useCallback(
        _.debounce((value) => {
            dispatch(
                fetchQualities({
                    search: value,
                    type: param.qualityType,
                    sort: { priority: -1 },
                    limit: 300,
                })
            );
        }, 300),
        []
    );
    const navigate = useNavigate();

    return (
        <PageWithCard heading={param.qualityType.toUpperCase()}>
            <SecondaryButton onClick={() => navigate(-1)}>Back</SecondaryButton>
            <div className="  flex border  items-center w-[78%] px-2 rounded-md gap-2 shadow-sm m-4">
                <Search size={'24px'} color="gray" />
                <input
                    className="text italic text-lg border-none w-full"
                    placeholder="Search Projects..."
                    onChange={(e) => handleDebouncedSearch(e.target.value)}
                />
            </div>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-3 p-4">
                {qualityMemo.map((ele) => (
                    <Link
                        to={`/dashboard/${param.qualityType}/${encodeQualityName(
                            ele.name
                        )}`}
                    >
                        <QualitySwatch quality={ele} key={ele._id} />
                    </Link>
                ))}
            </div>
        </PageWithCard>
    );
}

export default QualityType;
