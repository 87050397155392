import { useFormik } from 'formik';
import React, { useMemo } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import {
    editUser,
    getUsers,
    setEditId,
} from '../../app/reducers/Users/usersSlice';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { USER_ROLES } from '../../utils/dropdownOptions';
import { generateOptions } from '../../utils/Utils';
import FormikTextareaGroup from '../../components/formik/FormikTextareaGroup';
import {
    fetchOrganizations,
    getOrganization,
} from '../../app/reducers/Organization/organizationSlice';

const EditUserForm = () => {
    const { elementEditData, editDataLoading, editUserId } =
        useSelector(getUsers);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchOrganizations());
    }, []);
    const { organization } = useSelector(getOrganization);

    const organizationData = useMemo(
        () => (organization?.docs ? organization.docs : []),
        [organization]
    );

    const { ...userValues } = elementEditData || {};
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { ...userValues, password: '' },
        onSubmit: (values) => {
            dispatch(editUser({ ...values }));
        },
    });
    useEffect(() => {
        return () => dispatch(setEditId(null));
    }, [editUserId]);

    return (
        <>
            {editDataLoading ? (
                <ClipLoader />
            ) : (
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-4"
                >
                    <FormikInputGroup
                        label="Name"
                        formik={formik}
                        name="name"
                        required
                    />
                    <FormikInputGroup
                        label="Phone"
                        formik={formik}
                        name="username"
                        required
                    />
                    <FormikInputGroup
                        label="Password"
                        formik={formik}
                        name="password"
                        type="password"
                    />
                    <FormikInputGroup
                        label="Email"
                        formik={formik}
                        name="email"
                    />
                    <FormikSelectGroup
                        label="Organization"
                        formik={formik}
                        name="organization"
                        required
                        options={generateOptions({
                            array: organizationData,
                            labelField: 'name',
                            valueField: '_id',
                        })}
                    />
                    <FormikSelectGroup
                        label="Role"
                        formik={formik}
                        name="role"
                        required
                        options={generateOptions({
                            array: USER_ROLES,
                            labelField: 'label',
                            valueField: 'value',
                        })}
                    />
                    <FormikTextareaGroup
                        label="Address"
                        formik={formik}
                        name="address"
                    />
                    <div>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            )}
        </>
    );
};

export default EditUserForm;
