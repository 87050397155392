import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchUsers,
    fetchUser,
    getUsers,
    setEditId,
} from '../../app/reducers/Users/usersSlice';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import Select from 'react-select';
import { ClipLoader } from 'react-spinners';
import EditUserForm from './EditUserForm';

const EditUser = () => {
    const { elementEditId, auth, editUserLoading } = useSelector(getUsers);
    const dispatch = useDispatch();
    useEffect(() => {
        if (elementEditId) {
            dispatch(fetchUser({ _id: elementEditId }));
        } else {
            dispatch(fetchUsers());
        }
    }, [elementEditId]);

    return (
        <PageWithCard>
            {elementEditId ? (
                <>
                    {editUserLoading ? (
                        <ClipLoader />
                    ) : (
                        <>
                            <EditUserForm />
                        </>
                    )}
                </>
            ) : (
                <>
                    <label>Select User to edit</label>
                    <Select
                        options={
                            auth?.docs?.map
                                ? auth?.docs.map((ele) => ({
                                      value: ele['_id'],
                                      label: `${ele['username']} / ${ele['name']}`,
                                  }))
                                : []
                        }
                        onChange={(selectedOption) => {
                            dispatch(setEditId(selectedOption.value));
                        }}
                    />
                </>
            )}
        </PageWithCard>
    );
};

export default EditUser;
