import React, { useEffect, useState } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import {
    fetchPurchaseOrder,
    updatePurchaseOrder,
    updatePurchaseOrderQuantity,
} from '../../app/reducers/PurchaseOrder/purchaseOrderSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import moment from 'moment';
import { ClipLoader } from 'react-spinners';
import { customIDs } from '../../utils/customIds';
import FormikTextareaGroup from '../../components/formik/FormikTextareaGroup';
import { toast } from 'react-toastify';

const EditDateModal = ({
    editDateModalOpen,
    setEditDateModalOpen,
    editPoId,
    setEditPoId,
}) => {
    const [purchaseOrder, setPurchaseOrder] = useState(null);
    const { purchaseOrderLoading } = useSelector(fetchPurchaseOrder);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    useEffect(() => {
        console.log(editPoId);
        const fetchPo = async () => {
            if (editPoId) {
                setLoading(true);
                const action = await dispatch(
                    fetchPurchaseOrder({ id: editPoId })
                );

                if (action?.payload?.purchaseorder) {
                    setPurchaseOrder({
                        ...action.payload.purchaseorder,
                        date: moment(
                            action?.payload?.purchaseorder?.date
                        ).toDate(),
                    });
                    console.log(action.payload.purchaseorder);
                    setLoading(false);
                }
            }
        };
        fetchPo();
    }, [editPoId]);
    console.log(purchaseOrder, 'po');
    const formik = useFormik({
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                console.log(values);
                setLoading(true);
                const line_items_custom_fields =
                    values.line_items[0].item_custom_fields.map((ele, i) => {
                        if (
                            ele.customfield_id ===
                            customIDs.purchase_item_qty_taka
                        ) {
                            return {
                                customfield_id:
                                    customIDs.purchase_item_qty_taka,
                                value: values.qtyTaka,
                            };
                        } else {
                            return {
                                customfield_id: ele.customfield_id,
                                value: ele.value,
                            };
                        }
                    });
                // console.log(line_items);
                // return;
                const payload = {
                    id: values.id,
                    date: moment(values.date).format('YYYY-MM-DD'),
                    custom_fields: [
                        {
                            customfield_id: customIDs.purchase_custom_qty_taka,
                            value: values.qtyTaka,
                        },
                        {
                            customfield_id: customIDs.purchase_custom_note,
                            value: `${values.poNote}-${moment().format('DD/MM')}`,
                        },
                    ],
                    line_items: [
                        {
                            ...values.line_items[0],
                            item_custom_fields: line_items_custom_fields,
                        },
                    ],
                };
                await dispatch(updatePurchaseOrderQuantity(payload));
                setEditDateModalOpen(false);
            } catch (error) {
                console.log(error);
                toast.error('failed to update po');
            } finally {
                setLoading(false);
            }
        },
        initialValues: {
            id: editPoId,
            poNumber: purchaseOrder?.purchaseorder_number,
            qtyTaka: purchaseOrder?.custom_field_hash['cf_qty_taka'],
            poNote: purchaseOrder?.custom_field_hash?.['cf_po_note']?.split(
                '-'
            )[0],
            // id: editPoId,
            line_items: purchaseOrder?.line_items,
            date: purchaseOrder?.date,
        },
    });
    return (
        <ModalBasic
            title={'Quick Edit Purchase Order'}
            modalOpen={editDateModalOpen}
            setModalOpen={setEditDateModalOpen}
        >
            {purchaseOrderLoading || loading ? (
                <ClipLoader />
            ) : (
                <form
                    className="p-4 flex-col gap-4"
                    onSubmit={formik.handleSubmit}
                >
                    <FormikInputGroup
                        label="ID"
                        readOnly
                        name={'id'}
                        formik={formik}
                    />
                    <FormikInputGroup
                        name={'poNote'}
                        formik={formik}
                        label="PO Note"
                    />
                    <FormikInputGroup
                        label="PO Number"
                        readOnly
                        name={'poNumber'}
                        formik={formik}
                    />
                    <FormikInputGroup
                        label="Quantity Taka"
                        name={'qtyTaka'}
                        formik={formik}
                    />
                    <FormikInputDateGroup
                        label="Current Date"
                        name={'date'}
                        formik={formik}
                    />
                    <div>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            )}
        </ModalBasic>
    );
};

export default EditDateModal;
