import { createSlice } from '@reduxjs/toolkit';
import {
    createData,
    deleteData,
    editData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';
import { generateStateSingle } from '../../generators/generateState';

const SLICE_NAME = 'reminderPopUp';
let initialData = {
    isOpen: false,
    data: {},
};

export const fetchReminderPopUps = fetchData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const editReminderPopUp = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchReminderPopUp = fetchEditData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const deleteReminderPopUp = deleteData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const createReminderPopUp = createData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);

const reminderPopUp = createSlice({
    name: SLICE_NAME,
    initialState: { ...generateStateSingle(SLICE_NAME), initialData },
    reducers: {
        openPopUp: (state, action) => {
            state.isOpen = action.payload.isOpen;
            state.data = action.payload.data;
        },
    },
    extraReducers: {
        ...fetchData.generateExtraReducers(fetchReminderPopUps, SLICE_NAME),
        ...createData.generateExtraReducers(createReminderPopUp, SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteReminderPopUp, SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchReminderPopUp, SLICE_NAME),
        ...editData.generateExtraReducers(editReminderPopUp, SLICE_NAME),
    },
});

export const getReminderPopUp = (state) => state.reminderPopUp;
export const { openPopUp } = reminderPopUp.actions;

export const openReminderPopUp = (data) => async (dispatch) => {
    await dispatch(openPopUp(data));
};

export default reminderPopUp.reducer;
