import { useFormik } from 'formik';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchQualities,
    getQuality,
} from '../../app/reducers/Quality/qualitySlice';
import { useEffect, useState } from 'react';
import { generateOptions } from '../../utils/Utils';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import {
    fetchCustomers,
    getCustomer,
} from '../../app/reducers/Customer/customerSlice';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import { ClipLoader } from 'react-spinners';
import moment from 'moment';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import { fetchContacts } from '../../app/reducers/Contacts/contactsSlice';
import { CheckCircle } from 'react-feather';
import { createCustomerPreference } from '../../app/reducers/CustomerPreference/customerPreferenceSlice';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';

const CustomerPreference = () => {
    const { quality, loading: qualityLoading } = useSelector(getQuality);
    const { customers, loading: customerLoading } = useSelector(getCustomer);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchQualities({ limit: 300, sort: { priority: -1 } }));
        dispatch(
            fetchCustomers({
                contact_type: 'customer',
            })
        );

        // dispatch(fetchVendors());
    }, []);

    async function handlePairAndRating(row, star) {
        // const qualityRes = await dispatch(fetchQuality({ name: row.original.cf_quality_name }));
        // const QualityId = qualityRes?.payload?.data?.docs?.[0]._id
        // console.log(QualityId)
        await dispatch(
            createCustomerPreference({
                vendorName: row.original.vendor_name,
                customerName: row.original.cf_buyer_name,
                qualityName: row.original.cf_quality_name,
                star: star,
                purchaseOrder_id: row.original.purchaseorder_id,
            })
        );
        // await dispatch(fetchPurchaseOrders({ [`custom_field_${customIDs.purchase_order_followup_task}_contains`]: "YES" }));
    }
    const currentDate = moment().format('YYYY-MM-DD');
    const sixMonthBackDate = moment()
        .subtract(6, 'months')
        .format('YYYY-MM-DD');
    console.log('sixMonthBackDate', sixMonthBackDate);
    const [preferenceData, setPreferenceData] = useState({});
    const [loading, setLoading] = useState(false);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            quality: [],
            customer: '',
            monthGap: '6',
            customerName: '',
            vendor: '',
        },
        onSubmit: async (values) => {
            try {
                const currentDate = moment().format('YYYY-MM-DD');
                const monthGapDate = moment()
                    .subtract(Number(values.monthGap), 'months')
                    .format('YYYY-MM-DD');

                const payload = {
                    qualityArr: values.quality,
                    customerId: values.customer,
                    startDate: monthGapDate,
                    endDate: currentDate,
                    customerName: values.customerName,
                    vendorId: values.vendor,
                };
                const string = QueryString.stringify(payload);
                setLoading(true);
                const resp = await authAxiosInstance.get(
                    `/customerPreference?${string}`
                );
                console.log('resp', Object.values(resp.data.data));
                const preferenceArr = Object.values(
                    resp.data.data.preferenceObj
                );
                const ratingData = Object.values(resp.data.data.ratingData);
                const sortedPreferenceArr = preferenceArr.sort(function (a, b) {
                    return a.cf_qty_taka > b.cf_qty_taka ? -1 : 1;
                });
                const sortedRatingData = await ratingData.sort((a, b) =>
                    a.star > b.star ? -1 : 1
                );
                setPreferenceData({
                    sortedPreferenceArr,
                    ratingData: sortedRatingData,
                });
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log('Onsubmit Error =>', error);
            }
        },
    });

    const [showRating, setShowRating] = useState(false);

    const cols = [
        { Header: 'SRNO', Cell: ({ row }) => row.index + 1 },
        { Header: 'Customer Name', accessor: 'cf_buyer_name' },
        { Header: 'Vendor Name', accessor: 'vendor_name' },
        { Header: 'Quality', accessor: 'cf_quality_name' },
        { Header: 'Taka QTY', accessor: 'cf_qty_taka' },
        {
            Header: 'Rating',
            Cell: ({ row }) => (
                <>
                    {showRating ? (
                        <div className="flex gap-1 items-center">
                            <PrimaryButton
                                onClick={async () => {
                                    const star = 0;
                                    handlePairAndRating(row, star);
                                }}
                            >
                                0
                            </PrimaryButton>
                            <PrimaryButton
                                onClick={async () => {
                                    const star = 3;
                                    handlePairAndRating(row, star);
                                }}
                            >
                                3
                            </PrimaryButton>
                            <PrimaryButton
                                onClick={async () => {
                                    const star = 5;
                                    handlePairAndRating(row, star);
                                }}
                            >
                                5
                            </PrimaryButton>
                        </div>
                    ) : (
                        <p className="text-gray-400 italic">Disabled</p>
                    )}
                </>
            ),
        },
    ];
    const ratingCols = [
        { Header: 'SRNO', Cell: ({ row }) => row.index + 1 },
        { Header: 'Customer Name', accessor: 'customer' },
        {
            Header: 'Vendor Name',
            Cell: ({ row }) => row.original.vendor.company_name,
        },
        { Header: 'Quality', Cell: ({ row }) => row.original.quality },
        {
            Header: 'rating',
            Cell: ({ row }) => {
                if (row.original.star == 5) {
                    return (
                        <div className="flex gap-3">
                            <p>{row.original.star} STAR</p>
                            <CheckCircle color="green" />
                        </div>
                    );
                } else if (row.original.star == 3) {
                    return (
                        <diV className="flex gap-3">
                            <p>{row.original.star} STAR</p>{' '}
                            <CheckCircle color="yellow" />
                        </diV>
                    );
                } else {
                    return (
                        <div className="flex gap-3">
                            <p>{row.original.star} STAR</p>{' '}
                            <CheckCircle color="red" />
                        </div>
                    );
                }
            },
            accessor: 'star',
        },
    ];
    return (
        <PageWithCard heading="Customer Preference">
            <form
                onSubmit={formik.handleSubmit}
                className="my-2 flex flex-col gap-2"
            >
                <div className="grid grid-cols-3 gap-4">
                    <FormikSelectGroup
                        options={generateOptions({
                            array: customers?.contacts
                                ? customers.contacts
                                : [],
                            labelField: 'company_name',
                            valueField: 'contact_id',
                        })}
                        formik={formik}
                        name={'customer'}
                        label="Customer"
                        onChange={(option) => {
                            formik.setFieldValue('customerName', option.label);
                            formik.setFieldValue('customer', option.value);
                        }}
                    />
                    <FormikMultiSelect
                        options={generateOptions({
                            array: quality.docs ? quality.docs : [],
                            labelField: 'name',
                            valueField: 'name',
                        })}
                        formik={formik}
                        name={'quality'}
                        label="Quality"
                    />
                    <FormikAsyncSelect
                        name={'vendor'}
                        label="Vendor"
                        formik={formik}
                        getOptions={async (value) => {
                            const action = await dispatch(
                                fetchContacts({
                                    search: value,
                                    contact_type: 'vendor',
                                })
                            );
                            const serverResp = action.payload.contacts.map(
                                (ele) => ({
                                    label: `${ele.contact_name}`,
                                    value: `${ele.contact_id}`,
                                })
                            );
                            return serverResp;
                        }}
                        onChange={(option) => {
                            formik.setFieldValue('vendor', option.value);
                        }}
                    />
                </div>
                <FormikSelectGroup
                    options={[
                        { value: '6', label: '6 Months' },
                        { value: '12', label: '12 Months' },
                    ]}
                    formik={formik}
                    name={'monthGap'}
                    label="Month Gap"
                />
                <div>
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                </div>
            </form>
            {loading ? (
                <ClipLoader />
            ) : (
                <TableWithHeadingAndSearch
                    heading={' preference'}
                    data={preferenceData.ratingData ?? []}
                    columns={ratingCols}
                />
            )}
            {loading ? (
                <ClipLoader />
            ) : (
                <TableWithHeadingAndSearch
                    heading={
                        <div className="flex gap-2 items-center ">
                            <h1>View PO according to preference</h1>
                            <PrimaryButton
                                onClick={() => {
                                    setShowRating((prev) => !prev);
                                }}
                            >
                                Update Rating
                            </PrimaryButton>
                        </div>
                    }
                    data={preferenceData?.sortedPreferenceArr ?? []}
                    columns={cols}
                />
            )}
        </PageWithCard>
    );
};

export default CustomerPreference;
