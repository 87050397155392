import { useEffect, useMemo } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { useDispatch, useSelector } from 'react-redux';
import {
    createAttribute,
    deleteAttribute,
    fetchAttributes,
    getAttributes,
} from '../../app/reducers/Attributes/attributeSlice';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { ClipLoader } from 'react-spinners';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { generateOptions } from '../../utils/Utils';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import { Edit2, Eye, Trash, X } from 'react-feather';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import ViewAttributeModal from './ViewAttributeModal';
import { useState } from 'react';
import EditAttributeModal from './EditAttributeModal';

const AddAttribute = () => {
    const [isViewAttributeModalOpen, setIsViewAttributeModalOpen] =
        useState(false);
    const [isEditAttributeModalOpen, setIsEditAttributeModalOpen] =
        useState(false);
    const [selectedAttribute, setSelectedAttribute] = useState();
    const dispatch = useDispatch();
    const { loading, attributes } = useSelector(getAttributes);
    useEffect(() => {
        dispatch(fetchAttributes());
    }, []);

    const attributeType = [
        { name: 'Fani' },
        { name: 'Pana' },
        { name: 'Weight' },
        { name: 'Pick' },
        { name: 'Denier Beam' },
        { name: 'Denier Vana' },
        { name: 'TPM Beam' },
        { name: 'TPM Vana' },
        { name: 'Yarn Beam' },
        { name: 'Yarn Vana' },
    ];

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            type: '',
            additionalValue: [],
        },
        onSubmit: async (values, { resetForm }) => {
            const { additionalValue, ...rest } = values;
            const additionalData = additionalValue.reduce((acc, ele) => {
                acc[ele.key] = ele.value;
                return acc;
            }, {});
            const finalObj = { ...additionalData, ...rest };
            const attributeRes = await dispatch(createAttribute(finalObj));
            console.log('attributeRes==>', attributeRes);
            resetForm();
        },
    });

    const data = useMemo(
        () => (attributes?.docs ? attributes.docs : []),
        [attributes]
    );

    const columns = [
        {
            Header: 'SRNO',
            Cell: (prop) => {
                const { data, row } = prop;
                return row.index + 1;
            },
        },
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'type',
            accessor: 'type',
        },
        {
            Header: 'Action',
            Cell: (prop) => {
                const { data, row } = prop;
                return (
                    <div
                        className="flex items-center gap-2"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Edit2
                            color="blue"
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsEditAttributeModalOpen(true);
                                setSelectedAttribute(row?.original);
                            }}
                        />
                        <Eye
                            color="blue"
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsViewAttributeModalOpen(true);
                                setSelectedAttribute(row?.original);
                            }}
                        />
                        <Trash
                            color="red"
                            onClick={async () => {
                                await dispatch(
                                    deleteAttribute({ id: row?.original?._id })
                                );
                                dispatch(fetchAttributes());
                            }}
                        />
                    </div>
                );
            },
        },
    ];
    return (
        <PageWithCard heading="Attributes">
            <ViewAttributeModal
                setIsViewAttributeModalOpen={setIsViewAttributeModalOpen}
                isViewAttributeModalOpen={isViewAttributeModalOpen}
                selectedAttribute={selectedAttribute}
            />
            <EditAttributeModal
                setIsEditAttributeModalOpen={setIsEditAttributeModalOpen}
                isEditAttributeModalOpen={isEditAttributeModalOpen}
                selectedAttribute={selectedAttribute}
            />
            {loading ? (
                <ClipLoader />
            ) : (
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-4 mb-2"
                >
                    <FormikInputGroup
                        label="Name"
                        formik={formik}
                        name="name"
                        required
                    />
                    <FormikSelectGroup
                        label="type"
                        formik={formik}
                        name="type"
                        options={generateOptions({
                            array: attributeType ? attributeType : [],
                            labelField: 'name',
                            valueField: 'name',
                        })}
                        required
                    />
                    <FormikProvider value={formik}>
                        <FieldArray
                            name="additionalValue"
                            render={(arrayHelpers) => {
                                return (
                                    <div>
                                        <div className="my-2">
                                            {formik.values.additionalValue.map(
                                                (ele, count) => (
                                                    <div
                                                        key={count}
                                                        className="flex  gap-3  items-center"
                                                    >
                                                        <FormikInputGroup
                                                            formik={formik}
                                                            label="Enter Key"
                                                            name={`additionalValue.${count}.key`}
                                                        />
                                                        <FormikInputGroup
                                                            formik={formik}
                                                            label="Enter value"
                                                            name={`additionalValue.${count}.value`}
                                                        />
                                                        <X
                                                            className="text-red-500 "
                                                            onClick={() =>
                                                                arrayHelpers.remove(
                                                                    count
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                )
                                            )}
                                        </div>
                                        <div>
                                            <SecondaryButton
                                                onClick={() => {
                                                    arrayHelpers.push('');
                                                }}
                                            >
                                                Add Additional Fields
                                            </SecondaryButton>
                                        </div>
                                    </div>
                                );
                            }}
                        />
                    </FormikProvider>

                    <div>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            )}

            <TableWithHeadingAndGlobalSearch
                heading="Attributes"
                data={data}
                columns={columns}
                loading={loading}
                searchFunction={(value) => {
                    // debouncedSearch(value);
                }}
            />
        </PageWithCard>
    );
};

export default AddAttribute;
