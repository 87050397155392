import React from 'react';

const TertiaryButton = ({ children, className, type = 'button', ...props }) => {
    return (
        <button
            className={`btn border-slate-200 hover:border-slate-300 text-slate-600 ${className}`}
            type={type}
            {...props}
        >
            {children}
        </button>
    );
};

export default TertiaryButton;
