import React from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteOrganization,
    editOrganization,
    fetchOrganizations,
    getOrganization,
} from '../../app/reducers/Organization/organizationSlice';
import { ClipLoader } from 'react-spinners';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import { useMemo, useCallback, useEffect } from 'react';
import { Edit2, Trash } from 'react-feather';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';

export const ViewOrganization = () => {
    const { organization, loading } = useSelector(getOrganization);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchOrganizations());
    }, []);

    const columns = [
        {
            Header: 'SRNO',
            Cell: (prop) => {
                const { data, row } = prop;
                console.log(row);
                return row.index + 1;
            },
        },
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Refresh Token',
            accessor: 'refreshToken',
        },
        {
            Header: 'Access Token',
            accessor: 'accessToken',
        },
        {
            Header: 'Client Id',
            accessor: 'clientID',
        },
        {
            Header: 'Secret Id',
            accessor: 'secretID',
        },
        {
            Header: 'Organization Id',
            accessor: 'organizationID',
        },
        {
            Header: 'Domain',
            accessor: 'domainName',
        },
        {
            Header: 'Action',
            Cell: (prop) => {
                const { data, row } = prop;
                return (
                    <div
                        className="flex gap-2"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Edit2
                            size={18}
                            color="blue"
                            onClick={() => {
                                navigate(
                                    `/editOrganization/${row.original._id}`
                                );
                            }}
                        />
                        <Trash
                            size={18}
                            color="red"
                            onClick={async () => {
                                await dispatch(
                                    deleteOrganization({
                                        id: row.original._id,
                                    })
                                );
                                dispatch(fetchOrganizations());
                            }}
                        />
                    </div>
                );
            },
        },
    ];
    const columnsMemo = useMemo(() => columns, [columns]);
    const dataMemo = useMemo(
        () => (organization?.docs ? organization.docs : []),
        [organization?.docs]
    );

    const debouncedSearch = useCallback(
        _.debounce(async (search) => {
            dispatch(fetchOrganizations({ search: search }));
        }, 300),
        [organization?.docs]
    );

    return (
        <PageWithCard heading="View Organization">
            {/* {loading ? (
        <ClipLoader />
      ) : (
        <TableWithHeadingAndSearch
          columns={columnsMemo}
          data={dataMemo}
          heading="Organization"
        />
      )} */}
            <TableWithHeadingAndGlobalSearch
                columns={columnsMemo}
                data={dataMemo}
                heading="Organization"
                loading={loading}
                searchFunction={debouncedSearch}
            />
        </PageWithCard>
    );
};
