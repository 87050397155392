import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { generateSetEditId } from '../../generators/generateReducers';
import { generateStateSingle } from '../../generators/generateState';
import {
    createData,
    deleteData,
    editData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';
import QueryString from 'qs';
import { authAxiosInstance } from '../../../utils/axiosConfig';
const SLICE_NAME = 'customerPreference';

export const fetchCustomerPreferences = fetchData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const editCustomerPreference = editData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const fetchCustomerPreference = fetchEditData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const deleteCustomerPreference = deleteData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const createCustomerPreference = createData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const checkForCustomerFollowUp = createAsyncThunk(
    'customerPreference/checkForCustomerFollowUp',
    async (data) => {
        try {
            const string = QueryString.stringify(data);
            const resp = await authAxiosInstance.get(
                `customerPreference/checkForFollowup?${string}`
            );
            return resp.data;
        } catch (error) {
            console.log('Error in checkForCustomerFollowUp slice: ', error);
            if (error?.response?.message) toast.error(error.response.message);
            else toast.error('failed to check for followup');
        }
    }
);

const customerPreferenceSlice = createSlice({
    name: SLICE_NAME,
    initialState: generateStateSingle(SLICE_NAME),
    reducers: {
        setEditId: generateSetEditId(),
    },
    extraReducers: {
        ...fetchData.generateExtraReducers(
            fetchCustomerPreferences,
            SLICE_NAME
        ),
        ...createData.generateExtraReducers(
            createCustomerPreference,
            SLICE_NAME
        ),
        ...deleteData.generateExtraReducers(
            deleteCustomerPreference,
            SLICE_NAME
        ),
        ...fetchEditData.generateExtraReducers(
            fetchCustomerPreference,
            SLICE_NAME
        ),
        ...editData.generateExtraReducers(editCustomerPreference, SLICE_NAME),
        [checkForCustomerFollowUp.pending]: (state) => {
            state.loading = true;
        },
        [checkForCustomerFollowUp.fulfilled]: (state, action) => {
            state.loading = false;
        },
        [checkForCustomerFollowUp.rejected]: (state, action) => {
            state.loading = false;
        },
    },
});

export const getCustomerPreference = (state) => state.customerPreference;
export const { setEditId } = customerPreferenceSlice.actions;
export default customerPreferenceSlice.reducer;
