import React, { useEffect, useState } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useDispatch } from 'react-redux';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import QueryString from 'qs';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { downloadPDF } from '../../utils/downloadPdf';

const InvoiceDataPopup = ({
    invoicePopupOpen,
    setInvoicePopupOpen,
    clickedInvoiceId,
    setClickedInvoiceId,
}) => {
    const [invoiceData, setInvoiceData] = useState({});
    const [previousInvoices, setPreviousInvoices] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchInvoice = async () => {
            try {
                setLoading(true);
                if (clickedInvoiceId) {
                    const resp = await authAxiosInstance.get(
                        `invoice/${clickedInvoiceId}`
                    );

                    // const prevInvoices = await authAxiosInstance.get(
                    //   `invoice/${clickedInvoiceId}`
                    // );
                    setInvoiceData(resp?.data?.data?.invoice);
                }
            } catch (error) {
                console.log(error);
                toast.error('failed to fetch invoice');
            } finally {
                setLoading(false);
            }
        };

        fetchInvoice();
    }, [clickedInvoiceId]);
    return (
        <ModalBasic
            title={'Invoice Total'}
            modalOpen={invoicePopupOpen}
            setModalOpen={setInvoicePopupOpen}
        >
            {loading ? (
                <ClipLoader />
            ) : (
                <div className="p-4 flex flex-col gap-4">
                    {invoiceData?.line_items?.map((ele, i) => (
                        <span className="font-bold" key={i}>
                            {ele?.description}
                        </span>
                    ))}

                    <span>Base Amount : {invoiceData?.bcy_sub_total}</span>
                    {invoiceData?.taxes?.map((ele, i) => (
                        <span key={i}>
                            {ele?.tax_name} : {ele?.tax_amount}
                        </span>
                    ))}
                    <span className="text-red-500">
                        TDS : {invoiceData?.tds_amount}
                    </span>
                    <span className="underline">
                        Final Total : {invoiceData?.bcy_total}
                    </span>
                    {invoiceData?.adjustment_description &&
                        invoiceData?.adjustment && (
                            <span>
                                {invoiceData.adjustment_description} :{' '}
                                {invoiceData?.adjustment}
                            </span>
                        )}
                    <span className="font-bold">
                        Balance Due : {invoiceData?.balance}
                    </span>
                    <div>
                        <PrimaryButton
                            onClick={async (e) => {
                                e.stopPropagation();
                                const resp = await authAxiosInstance.get(
                                    `/invoice/pdf/${clickedInvoiceId}`
                                );
                                downloadPDF(resp.data.base64);
                            }}
                        >
                            Download Invoice
                        </PrimaryButton>
                    </div>
                </div>
            )}
        </ModalBasic>
    );
};

export default InvoiceDataPopup;
