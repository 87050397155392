import { createSlice } from '@reduxjs/toolkit';
import { generateSetEditId } from '../../generators/generateReducers';
import { generateStateSingle } from '../../generators/generateState';
import {
    createData,
    deleteData,
    editData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';
const SLICE_NAME = 'pendingOrderList';

export const fetchPendingOrderLists = fetchData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const editPendingOrderList = editData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const fetchPendingOrderList = fetchEditData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const deletePendingOrderList = deleteData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const createPendingOrderList = createData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);

const pendingOrderListSlice = createSlice({
    name: SLICE_NAME,
    initialState: generateStateSingle(SLICE_NAME),
    reducers: {
        setEditId: generateSetEditId(),
    },
    extraReducers: {
        ...fetchData.generateExtraReducers(fetchPendingOrderLists, SLICE_NAME),
        ...createData.generateExtraReducers(createPendingOrderList, SLICE_NAME),
        ...deleteData.generateExtraReducers(deletePendingOrderList, SLICE_NAME),
        ...fetchEditData.generateExtraReducers(
            fetchPendingOrderList,
            SLICE_NAME
        ),
        ...editData.generateExtraReducers(editPendingOrderList, SLICE_NAME),
    },
});

export const getPendingOrderList = (state) => state.pendingOrderList;
export const { setEditId } = pendingOrderListSlice.actions;
export default pendingOrderListSlice.reducer;
